import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Toast } from 'primereact/toast'
import { popupIcon } from 'Assets/Icons'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import { coffeeReportJsonLinks } from 'Utils/Constants'

export default function India({ swapReport, setLoading }) {
  const { t } = useTranslation()
  const toast = useRef(null)
  const { indiaCountryWiseExportsReport } = coffeeReportJsonLinks

  const [fetchedData, setFetchedData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [currentData, setCurrentData] = useState({})
  const [previousData, setPreviousData] = useState({})
  const [currentYear, setCurrentYear] = useState('')
  const [previousYear, setPreviousYear] = useState('')
  const [modalType, setModalType] = useState(null)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    const reportName = indiaCountryWiseExportsReport

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setFetchedData(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const calculateTotals = (countryCertifiedData) => {
    const totals = {
      PLAP: 0,
      AC: 0,
      RP: 0,
      RC: 0,
      INSTANT: 0,
      ROASTED: 0,
      GROUND: 0
    }

    countryCertifiedData.forEach(({ PLAP, AC, RP, RC, INSTANT, ROASTED, GROUND }) => {
      totals.PLAP += Number(PLAP) || 0
      totals.AC += Number(AC) || 0
      totals.RP += Number(RP) || 0
      totals.RC += Number(RC) || 0
      totals.INSTANT += Number(INSTANT) || 0
      totals.ROASTED += Number(ROASTED) || 0
      totals.GROUND += Number(GROUND) || 0
    })

    return totals
  }

  const convertMTToBags = (valueInMT) => {
    if (valueInMT === '' || Number.isNaN(Number(valueInMT))) {
      return ''
    }
    const result = (valueInMT * 1000) / 60

    return Math.round(result) || ''
  }

  const formatValue = (value, isDecimal) => {
    if (value === '' || Number.isNaN(Number(value))) {
      return ''
    }
    if (isDecimal) {
      return value.toFixed(2)
    }

    return Math.round(value) || ''
  }

  const calculateTotalsWithMonth = (data) => {
    const totals = {
      MONTH: '',
      PLAP: 0,
      AC: 0,
      RP: 0,
      RC: 0,
      INSTANT: 0,
      ROASTED: 0,
      GROUND: 0
    }

    data.forEach(({ PLAP, AC, RP, RC, INSTANT, ROASTED, GROUND, MONTH }) => {
      totals.PLAP += Number(PLAP) || 0
      totals.AC += Number(AC) || 0
      totals.RP += Number(RP) || 0
      totals.RC += Number(RC) || 0
      totals.INSTANT += Number(INSTANT) || 0
      totals.ROASTED += Number(ROASTED) || 0
      totals.GROUND += Number(GROUND) || 0

      if (MONTH) {
        totals.MONTH = MONTH
      }
    })

    return totals
  }

  const getTextColor = (value) => {
    if (value === 0) {
      return 'neutral'
    }
    if (value > 0) {
      return 'positive'
    }

    return 'negative'
  }

  const showMonthValueModal = (currentIndex, selectedYear) => {
    const filteredData = fetchedData.find(({ year }) => year === selectedYear)

    const selectedIndex = fetchedData.findIndex(({ year }) => year === selectedYear)

    const nextData = selectedIndex !== -1 && selectedIndex + 1 < fetchedData.length ? fetchedData[selectedIndex + 1] : null

    if (!filteredData || !nextData) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })

      return
    }

    const currentYearData = filteredData?.report[currentIndex]
    const previousYearData = nextData?.report[currentIndex]

    const currentYearValue = filteredData?.year.split(' : ')[1]
    const previousYearValue = nextData?.year.split(' : ')[1]

    setCurrentData(currentYearData)
    setPreviousData(previousYearData)
    setCurrentYear(currentYearValue)
    setPreviousYear(previousYearValue)
    setModalType('month')

    if (currentYearData?.PLAP !== '' && currentYearData?.AC !== '' && previousYearData?.PLAP !== '' && previousYearData?.AC !== '') {
      setShowModal(true)
    } else {
      setShowModal(false)
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_SELECTED_YEAR'), life: 3000 })
    }
  }

  const showTotalValueModal = (selectedYear) => {
    const filteredData = fetchedData.find(({ year }) => year === selectedYear)

    const selectedIndex = fetchedData.findIndex(({ year }) => year === selectedYear)

    const nextData = selectedIndex !== -1 && selectedIndex + 1 < fetchedData.length ? fetchedData[selectedIndex + 1] : null

    if (!filteredData || !nextData) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })

      return
    }

    const totalValue = []

    const updateData = filteredData?.report.filter((ele) => ele.PLAP && ele.AC)

    for (let i = 0; i < updateData.length; i++) {
      const elementCopy = { ...nextData?.report[i] }

      totalValue.push(elementCopy)
    }

    if (filteredData && nextData) {
      const currentYearTotal = calculateTotalsWithMonth(updateData)
      const previousYearTotal = calculateTotalsWithMonth(totalValue)

      const currentYearValue = filteredData?.year.split(' : ')[1]
      const previousYearValue = nextData?.year.split(' : ')[1]

      setCurrentData(currentYearTotal)
      setPreviousData(previousYearTotal)

      setCurrentYear(currentYearValue)
      setPreviousYear(previousYearValue)
      setModalType('total')

      if (currentYearTotal && previousYearTotal) {
        setShowModal(true)
      }
    }
  }

  const comparisonTableData = () => {
    if (!currentData || !previousData) {
      return null
    }

    const displayValue = (value) => (swapReport === 'MT' ? value.toLocaleString() : convertMTToBags(value).toLocaleString())

    const sumOfCurrentReports = currentData ? Number(currentData.PLAP) + Number(currentData.AC) + Number(currentData.RP) + Number(currentData.RC) + Number(currentData.INSTANT) + Number(currentData.ROASTED) + Number(currentData.GROUND) : 0
    const sumOfPreviousReports = previousData ? Number(previousData.PLAP) + Number(previousData.AC) + Number(previousData.RP) + Number(previousData.RC) + Number(previousData.INSTANT) + Number(previousData.ROASTED) + Number(previousData.GROUND) : 0

    const difference = {
      plap: Number(currentData.PLAP) - Number(previousData.PLAP) || 0,
      ac: Number(currentData.AC) - Number(previousData.AC) || 0,
      rp: Number(currentData.RP) - Number(previousData.RP) || 0,
      rc: Number(currentData.RC) - Number(previousData.RC) || 0,
      instant: Number(currentData.INSTANT) - Number(previousData.INSTANT) || 0,
      roasted: Number(currentData.ROASTED) - Number(previousData.ROASTED) || 0,
      ground: Number(currentData.GROUND) - Number(previousData.GROUND) || 0,
      total: sumOfCurrentReports - sumOfPreviousReports
    }

    const percentageDifference = {
      plap: previousData.PLAP ? ((difference.plap / Number(previousData.PLAP)) * 100).toFixed(2) : 'N/A',
      ac: previousData.AC ? ((difference.ac / Number(previousData.AC)) * 100).toFixed(2) : 'N/A',
      rp: previousData.RP ? ((difference.rp / Number(previousData.RP)) * 100).toFixed(2) : 'N/A',
      rc: previousData.RC ? ((difference.rc / Number(previousData.RC)) * 100).toFixed(2) : 'N/A',
      instant: previousData.INSTANT ? ((difference.instant / Number(previousData.INSTANT)) * 100).toFixed(2) : 'N/A',
      roasted: previousData.ROASTED ? ((difference.roasted / Number(previousData.ROASTED)) * 100).toFixed(2) : 'N/A',
      ground: previousData.GROUND ? ((difference.ground / Number(previousData.GROUND)) * 100).toFixed(2) : 'N/A',
      total: sumOfPreviousReports ? ((difference.total / sumOfPreviousReports) * 100).toFixed(2) : 'N/A'
    }

    const currentMonth = currentData.MONTH?.split('-')[0]
    const previousMonth = previousData.MONTH?.split('-')[0]

    const currentMonthYear = `${currentMonth} - ${currentYear}`
    const previousMonthYear = previousData ? `${previousMonth} - ${previousYear}` : ''

    const dynamicTitle = modalType === 'total' ? `UPTO ${currentMonthYear} TO ${previousMonthYear} COMPARISON` : `${currentMonthYear} TO ${previousMonthYear} COMPARISON`

    return (
      <div className="comparison-table">
        <div className="content-title">
          <h5>{dynamicTitle}</h5>
        </div>
        <table>
          <thead>
            <tr>
              <th>{t('MONTH')}</th>
              <th>{t('PL/AP')}</th>
              <th>{t('AC')}</th>
              <th>{t('RP')}</th>
              <th>{t('RC')}</th>
              <th>{t('INSTANT')}</th>
              <th>{t('ROASTED')}</th>
              <th>{t('GROUND')}</th>
              <th>{t('TOTAL')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{currentData.MONTH}</td>
              <td>{displayValue(Math.round(currentData.PLAP))}</td>
              <td>{displayValue(Math.round(currentData.AC))}</td>
              <td>{displayValue(Math.round(currentData.RP))}</td>
              <td>{displayValue(Math.round(currentData.RC))}</td>
              <td>{displayValue(Math.round(currentData.INSTANT))}</td>
              <td>{displayValue(Math.round(currentData.ROASTED))}</td>
              <td>{displayValue(Math.round(currentData.GROUND))}</td>
              <td>{displayValue(Math.round(sumOfCurrentReports))}</td>
            </tr>
            <tr>
              <td>{previousData ? previousData.MONTH : ''}</td>
              <td>{displayValue(Math.round(previousData.PLAP))}</td>
              <td>{displayValue(Math.round(previousData.AC))}</td>
              <td>{displayValue(Math.round(previousData.RP))}</td>
              <td>{displayValue(Math.round(previousData.RC))}</td>
              <td>{displayValue(Math.round(previousData.INSTANT))}</td>
              <td>{displayValue(Math.round(previousData.ROASTED))}</td>
              <td>{displayValue(Math.round(previousData.GROUND))}</td>
              <td>{displayValue(Math.round(sumOfPreviousReports))}</td>
            </tr>
            <tr>
              <td>{t('DIFFERENCE')}</td>
              <td className={getTextColor(difference.plap)}>{displayValue(Math.round(difference.plap))}</td>
              <td className={getTextColor(difference.ac)}>{displayValue(Math.round(difference.ac))}</td>
              <td className={getTextColor(difference.rp)}>{displayValue(Math.round(difference.rp))}</td>
              <td className={getTextColor(difference.rc)}>{displayValue(Math.round(difference.rc))}</td>
              <td className={getTextColor(difference.instant)}>{displayValue(Math.round(difference.instant))}</td>
              <td className={getTextColor(difference.instant)}>{displayValue(Math.round(difference.roasted))}</td>
              <td className={getTextColor(difference.ground)}>{displayValue(Math.round(difference.ground))}</td>
              <td className={getTextColor(difference.total)}>{displayValue(Math.round(difference.total))}</td>
            </tr>
            <tr>
              <td>{t('% WISE')}</td>
              <td className={getTextColor(percentageDifference.plap)}>{percentageDifference.plap !== 'N/A' ? `${percentageDifference.plap}%` : 'N/A'}</td>
              <td className={getTextColor(percentageDifference.ac)}>{percentageDifference.ac !== 'N/A' ? `${percentageDifference.ac}%` : 'N/A'}</td>
              <td className={getTextColor(percentageDifference.rp)}>{percentageDifference.rp !== 'N/A' ? `${percentageDifference.rp}%` : 'N/A'}</td>
              <td className={getTextColor(percentageDifference.rc)}>{percentageDifference.rc !== 'N/A' ? `${percentageDifference.rc}%` : 'N/A'}</td>
              <td className={getTextColor(percentageDifference.instant)}>{percentageDifference.instant !== 'N/A' ? `${percentageDifference.instant}%` : 'N/A'}</td>
              <td className={getTextColor(percentageDifference.roasted)}>{percentageDifference.roasted !== 'N/A' ? `${percentageDifference.roasted}%` : 'N/A'}</td>
              <td className={getTextColor(percentageDifference.ground)}>{percentageDifference.ground !== 'N/A' ? `${percentageDifference.ground}%` : 'N/A'}</td>
              <td className={getTextColor(percentageDifference.total)}>{percentageDifference.total !== 'N/A' ? `${percentageDifference.total}%` : 'N/A'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="horizontal-scroll-container" id="port-wise-stock-report">
      {fetchedData?.map(({ report, year }, index) => {
        const { PLAP, AC, RP, RC, INSTANT, ROASTED, GROUND } = calculateTotals(report)
        const sumOfTotals = Math.round(PLAP + AC + RP + RC + INSTANT + ROASTED + GROUND)

        return (
          <div key={index} className="table-wrapper">
            <table>
              <thead>
                <tr className="year-row">
                  <td colSpan={12}>
                    <div>{year}</div>
                  </td>
                </tr>
                <tr>
                  <th>{t('MONTH')}</th>
                  <th>{t('PL/AP')}</th>
                  <th>{t('AC')}</th>
                  <th>{t('RP')}</th>
                  <th>{t('RC')}</th>
                  <th>{t('INSTANT')}</th>
                  <th>{t('ROASTED')}</th>
                  <th>{t('GROUND')}</th>
                  <th>{t('TOTAL')}</th>
                </tr>
              </thead>
              <tbody>
                {report.map(({ MONTH, PLAP, AC, RP, RC, INSTANT, ROASTED, GROUND }, index) => {
                  const sumOfReports = Math.round(PLAP + AC + RP + RC + INSTANT + ROASTED + GROUND)

                  return (
                    <tr key={index}>
                      <td>
                        <div className="popup-wrapper" onClick={() => showMonthValueModal(index, year)}>
                          <div>{MONTH}</div>
                          <div className="icon-wrapper">
                            <img src={popupIcon} alt={MONTH} className="popup-icon" />
                          </div>
                        </div>
                      </td>
                      <td>{swapReport === 'MT' ? formatValue(PLAP, false).toLocaleString() : convertMTToBags(PLAP).toLocaleString()}</td>
                      <td>{swapReport === 'MT' ? formatValue(AC, false).toLocaleString() : convertMTToBags(AC).toLocaleString()}</td>
                      <td>{swapReport === 'MT' ? formatValue(RP, false).toLocaleString() : convertMTToBags(RP).toLocaleString()}</td>
                      <td>{swapReport === 'MT' ? formatValue(RC, false).toLocaleString() : convertMTToBags(RC).toLocaleString()}</td>
                      <td>{swapReport === 'MT' ? formatValue(INSTANT, false).toLocaleString() : convertMTToBags(INSTANT).toLocaleString()}</td>
                      <td>{swapReport === 'MT' ? formatValue(ROASTED, true).toLocaleString() : convertMTToBags(ROASTED).toLocaleString()}</td>
                      <td>{swapReport === 'MT' ? formatValue(GROUND, true).toLocaleString() : convertMTToBags(GROUND).toLocaleString()}</td>
                      <td>{swapReport === 'MT' ? formatValue(sumOfReports, false).toLocaleString() || '' : convertMTToBags(sumOfReports).toLocaleString()}</td>
                    </tr>
                  )
                })}
                <tr className="tr-color">
                  <td>
                    <div className="popup-wrapper" onClick={() => showTotalValueModal(year)}>
                      <div>{t('TOTAL')}</div>
                      <div className="icon-wrapper">
                        <img src={popupIcon} alt={'icon'} className="popup-icon" />
                      </div>
                    </div>
                  </td>
                  <td>{swapReport === 'MT' ? formatValue(PLAP, false).toLocaleString() : convertMTToBags(PLAP).toLocaleString()}</td>
                  <td>{swapReport === 'MT' ? formatValue(AC, false).toLocaleString() : convertMTToBags(AC).toLocaleString()}</td>
                  <td>{swapReport === 'MT' ? formatValue(RP, false).toLocaleString() : convertMTToBags(RP).toLocaleString()}</td>
                  <td>{swapReport === 'MT' ? formatValue(RC, false) : convertMTToBags(RC).toLocaleString()}</td>
                  <td>{swapReport === 'MT' ? formatValue(INSTANT, false).toLocaleString() : convertMTToBags(INSTANT).toLocaleString()}</td>
                  <td>{swapReport === 'MT' ? formatValue(ROASTED, true).toLocaleString() : convertMTToBags(ROASTED).toLocaleString()}</td>
                  <td>{swapReport === 'MT' ? formatValue(GROUND, true).toLocaleString() : convertMTToBags(GROUND).toLocaleString()}</td>
                  <td>{swapReport === 'MT' ? formatValue(sumOfTotals, false).toLocaleString() || '' : convertMTToBags(sumOfTotals).toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      })}
      <Toast ref={toast} position="top-right" />

      <DialogUIModal title={modalType === 'total' ? t('TOTAL_COMPARISON_DATA') : t('MONTH_COMPARISON_DATA')} showModal={showModal} handleClose={() => setShowModal(false)} bodyContent={comparisonTableData} titlePosition="center" size="lg" />
    </div>
  )
}
