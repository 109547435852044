import * as Sentry from '@sentry/react'

const isLocalDevelopment = process.env.REACT_APP_ENVIRONMENT === 'develop' && window.location.hostname === 'localhost'

let dsn

if (isLocalDevelopment) {
  dsn = undefined
} else if (process.env.REACT_APP_ENVIRONMENT === 'develop') {
  dsn = process.env.REACT_APP_DEV_SENTRY_DSN
} else {
  dsn = process.env.REACT_APP_PROD_SENTRY_DSN
}

Sentry.init({
  dsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  environment: process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'development' : 'production',
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['coffeeweb.com', 'dev-pwa.devptest.com', /^https:\/\/dev-api.devptest\.com\/api/, /^https:\/\/www.coffeewebapi\.com\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})
