import './styles.scss'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Clock from 'react-clock'
import { Carousel } from 'primereact/carousel'
import { SelectButton } from 'primereact/selectbutton'
import { ClockSetting } from 'Assets/Icons'
import { getLocalClockDisplay, getLocalClockTab, getLocalTimeFormat, getLocalUserDetails, setLocalWithClockTab } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { commonMethods } from 'Utils/commonMethods'
import DropdownComponent from 'Components/DropdownComponent'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import ClockSettings from './Components/MyClockSettingsModal'

const AnalogClock = ({ ...props }) => {
  const { mockDisplayTab, mockSelectedCountryData, mockSelectedCountry } = props

  const { t } = useTranslation()
  const userDetails = getLocalUserDetails()
  const { isLoading, setLoading } = useContext(CoffeeWebContext)
  let carouselValue
  const numVisible = 5 // To get the count of visible clocks in UI

  const defaultCountryData = {
    countryName: 'India',
    flagURL: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/IND.svg',
    gmtOffSet: 'UTC +05:30',
    roundOffSet: '+5.5',
    currentTime: '2024-03-14T15:36:57.8199849Z',
    timeZone: 'Asia/Kolkata'
  }

  const clockList = ['All Clock', 'My Favourite']
  const [page, setPage] = useState(0)
  const [clockDataForAllCountries, setClockDataForAllCountries] = useState([])
  const [favoriteCountryClock, setFavoriteCountryClock] = useState([])
  const [pickListSourceData, setPickListSourceData] = useState([])
  const [pickListTargetData, setPickListTargetData] = useState([])
  const [countryTime, setCountryTime] = useState({})
  const [digitalClockTime, setDigitalClockTime] = useState({})
  const [digitalTimeHour, setDigitalTimeHour] = useState({})
  const [digitalClock, setDigitalClock] = useState({})
  const [digitalPeriod, setDigitalPeriod] = useState({})
  const [countryDay, setCountryDay] = useState({})
  const [selectedCountry, setSelectedCountry] = useState({})
  const [filterCountry, setFilterCountry] = useState({})
  const [visible, setVisible] = useState(false)
  const [selectedCountryData, setSelectedCountryData] = useState(null)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [clockTab, setClockTab] = useState(() => getLocalClockTab('clockTab') || 'All Clock')
  const [selectedClockType, setSelectedClockType] = useState(() => getLocalClockDisplay('selectedClockDisplay') || 'Analog')
  const [timeFormat, setTimeFormat] = useState(() => getLocalTimeFormat('selectedTimeFormat') || '12-hour')
  const [isCountryPresent, setIsCountryPresent] = useState(false)
  const [numScrollCount, setNumScrollCount] = useState(1)
  const [errorState, setErrorState] = useState(false)
  const [numScroll, setNumScroll] = useState(3) // To control the count of data swiped in carousel
  const [pageLoad, setPageLoad] = useState(false) // To recognize page mount
  const [allClockData, setAllClockData] = useState([]) // To store all 243 countries data
  const [pageCount, setPageCount] = useState(0) // To maintain global Page Count
  const [displayTab, setDisplayTab] = useState(false) // To handle Tab Switch

  useEffect(() => {
    setSelectedClockType(getLocalClockDisplay('selectedClockDisplay') || 'Analog')
  }, [getLocalClockDisplay('selectedClockDisplay')])

  useEffect(() => {
    setTimeFormat(getLocalTimeFormat('selectedTimeFormat') || '12-hour')
  }, [getLocalClockDisplay('selectedTimeFormat')])

  useEffect(() => {
    getAllCountryTimeDetails()

    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (page > pageCount) {
      // To execute only on forward page movement
      setPageCount((prev) => prev + 1)
      if (page % 4 === 0) {
        // Slicing Data on every 4th page in forward direction, based on numScroll i.e. countries swiped on one click
        setClockDataForAllCountries([...clockDataForAllCountries, ...allClockData.slice((page + 1) * numScroll + (numVisible - numScroll), (page + 1) * numScroll + (numVisible - numScroll) + numScroll * 4)])
      }
    }
  }, [page])

  useEffect(() => {
    if (!isCountryPresent && pageLoad) {
      getGeolocation()
    }

    if (clockDataForAllCountries?.length) {
      const displayButton = clockDataForAllCountries?.some((ele) => ele.isFavorite) || allClockData?.some((ele) => ele.isFavorite)

      setDisplayTab(displayButton)
    }
  }, [clockDataForAllCountries, allClockData])

  useEffect(() => {
    const intervalID = setInterval(() => {
      getCurrentTime(allClockData)
    }, 1000)

    return () => clearInterval(intervalID)
  }, [allClockData])

  useEffect(() => {
    document.querySelector('body').style.overflow = visible ? 'hidden' : 'auto'
  }, [visible])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (mockDisplayTab) {
      setDisplayTab(mockDisplayTab)
      setSelectedCountryData(mockSelectedCountryData)
      setSelectedCountry(mockSelectedCountry)
    }
  }, [])

  const getAllCountryTimeDetails = async () => {
    setLoading(true)

    try {
      setErrorState(false)
      const {
        data: { returnLst }
      } = await apiAdapterCoffeeWeb.getCountryTimeZone({ userId: userDetails?.id || 0, pageNumber: 1, pageSize: 243 })
      const favoriteCountries = returnLst.filter((country) => country.isFavorite).sort((a, b) => a.favoriteIndex - b.favoriteIndex)
      const filteredCountry = returnLst.filter(({ countryId }) => !favoriteCountries.map(({ countryId }) => countryId).includes(countryId))

      setPageLoad(true)

      if (favoriteCountries?.length) {
        if (getLocalClockTab('clockTab') === 'All Clock') {
          setLocalWithClockTab('All Clock')
          setClockTab('All Clock')
        } else {
          setLocalWithClockTab('My Favourite')
          setClockTab('My Favourite')
        }
      }

      // For Guest User Based on Favorite Count, countries should be swiped right
      if (!userDetails) {
        setNumScroll(returnLst.length % 5)
      }

      setClockDataForAllCountries([...clockDataForAllCountries, ...returnLst.slice(0, 17)])
      setAllClockData([...clockDataForAllCountries, ...returnLst])
      getCurrentTime(returnLst)
      setFavoriteCountryClock(favoriteCountries)
      setPickListTargetData(favoriteCountries)
      setPickListSourceData(filteredCountry)
    } catch (error) {
      setLoading(false)
      setErrorState(true)
    }
  }

  const getCurrentTime = (clockData) => {
    const analogClockTime = {}
    const newWeekDay = {}
    const digitalTime = {}
    const digitalPeriod = {}
    const timeForDigitalClockClassName = {}
    const digitalClock24Hour = {}

    const getTime = (timeZone, options) => new Date().toLocaleTimeString('en-US', { timeZone, ...options })

    clockData.forEach((item) => {
      const options = {
        timeZone: item.timeZone,
        weekday: 'long',
        hour12: false
      }

      const digitalOptions = {
        timeZone: item.timeZone,
        hour12: true,
        hour: 'numeric',
        minute: 'numeric'
      }

      const options24Hour = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone: item.timeZone
      }

      const currentTimeForAnalogClock = getTime(item.timeZone)
      const currentTimeForDigitalClock = getTime(item.timeZone, digitalOptions)
      const currentTime24Hour = getTime(item.timeZone, options24Hour)

      const [time, period] = currentTimeForDigitalClock.split(' ')
      const currentDay = new Date().toLocaleDateString('en-us', options)

      analogClockTime[item.countryName] = currentTimeForAnalogClock
      timeForDigitalClockClassName[item.countryName] = currentTimeForDigitalClock
      digitalClock24Hour[item.countryName] = currentTime24Hour
      digitalTime[item.countryName] = time
      digitalPeriod[item.countryName] = period
      newWeekDay[item.countryName] = currentDay
    })

    setCountryTime(analogClockTime)
    setDigitalClockTime(digitalTime)
    setDigitalPeriod(digitalPeriod)
    setCountryDay(newWeekDay)
    setDigitalClock(timeForDigitalClockClassName)
    setDigitalTimeHour(digitalClock24Hour)
  }

  const getClockClassName = (timeString) => {
    if (timeString) {
      const timeParts = timeString.split(':')
      const period = timeString.split(' ')

      const hours = parseInt(timeParts[0], 10)

      const isNightTime = (hours >= 8 && hours < 12 && period[1] === 'PM') || ((hours < 6 || hours === 12) && period[1] === 'AM')

      return isNightTime ? ` ${selectedClockType === 'Digital' ? 'digital-clock-dark' : 'analog-clock-dark'}` : ` ${selectedClockType === 'Digital' ? 'digital-clock-light' : 'analog-clock-light'}`
    }

    return ''
  }

  const getGeolocation = async () => {
    setLoading(true)
    setSelectedCountry(clockDataForAllCountries[2])

    try {
      if (!userDetails) {
        const locationResponse = await commonMethods.searchLocation()

        modifyCountryPositions(locationResponse?.country, locationResponse?.region, locationResponse?.name)
      }
    } catch {
      if (!userDetails) {
        const ipLocationResponse = await commonMethods.fetchIPDetails()

        modifyCountryPositions(ipLocationResponse?.city, ipLocationResponse?.region, ipLocationResponse?.countryName)
      }
    } finally {
      setLoading(false)
    }
  }

  const modifyCountryPositions = (...args) => {
    setLoading(true)

    let remainingCountries
    let currentCountry = clockDataForAllCountries?.find((item) => args.some((arg) => item.countryName === arg))

    if (currentCountry && !isCountryPresent && !userDetails) {
      remainingCountries = clockDataForAllCountries.filter((item) => item?.countryName !== currentCountry.countryName)
      remainingCountries.splice(2, 0, currentCountry)
      setIsCountryPresent(true)
      setClockDataForAllCountries(remainingCountries)
    } else if (!currentCountry && !isCountryPresent && !userDetails) {
      remainingCountries = clockDataForAllCountries.filter((item) => item?.countryName !== 'India')
      remainingCountries.splice(2, 0, defaultCountryData)
      currentCountry = defaultCountryData
      setIsCountryPresent(true)
      setClockDataForAllCountries(remainingCountries)
    }

    setSelectedCountry(currentCountry)
  }

  const onClockTabChange = (event) => {
    setPage(0)
    handleClearFilter()

    const selectedClockTab = event.target.value

    setClockTab(selectedClockTab)
    setLocalWithClockTab(selectedClockTab)
  }

  const handleAddButtonClick = () => {
    handleClearFilter()

    setVisible(true)
  }

  const handleCountrySelection = (selectedCountry) => {
    setPage(0)

    const filteredCountry = allClockData?.filter((country) => country?.countryName === selectedCountry?.countryName)

    setSelectedCountryData(filteredCountry?.length > 0 ? filteredCountry[0] : null)
  }

  if (selectedCountryData) {
    carouselValue = [selectedCountryData]
  } else {
    carouselValue = clockTab === 'All Clock' ? clockDataForAllCountries : favoriteCountryClock
  }

  const handleClearFilter = () => {
    setFilterCountry({})
    setSelectedCountryData(null)
  }

  const pageChange = (event) => {
    setPage(event.page)
  }

  const productTemplate = (item) =>
    selectedClockType === 'Analog' ? (
      <div className="clock-container">
        {/* Analog Clock UI */}
        <div className="clock-content">
          <div className="clock-details-info" data-testId="analog-clock">
            {/* Clocks Country Name */}
            <div className="country-name-info" data-testId="analogClock-countryName-info">
              {item.countryName}
            </div>
            <Clock value={countryTime[item.countryName]} renderNumbers={false} hourMarksLength={10} minuteMarksLength={4} size={windowWidth <= 1024 ? 90 : 140} className={getClockClassName(countryTime[item.countryName])} data-testId="clock" />
            <img src={item.flagURL} alt={`${item.countryName} Flag`} className="country-flag-info" title="analogClock-flag-info" />
          </div>
          <div className="time-details-container">
            <div className="weekday-info" data-testId="analogClock-weekday-info">
              {countryDay[item.countryName]}
            </div>

            <div className="current-time-info" data-testId="analogClock-time-info">
              {digitalClockTime[item.countryName]} {digitalPeriod[item.countryName]}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <>
        {/* Digital Clock UI */}
        <div className="digital-clock-container" data-testId="digital-clock">
          <div className="digital-clock-country-details">
            <div className="digital-country-name-info" data-testId="digitalClock-countryName-info">
              {item.countryName}
            </div>
          </div>
          <div className="digital-clock-wrapper">
            <div className="digital-clock">
              <div className={`digital-clock-item ${getClockClassName(digitalClock[item.countryName])}`}>
                <div className="digital-clock-time-wrapper">
                  {timeFormat === '12-hour' ? (
                    <>
                      {/* 12 Hour Format */}
                      <div className="digital-time-info" data-testId="digital-time-info">
                        {digitalClockTime[item.countryName]}
                      </div>
                      <div className="digital-period-info" data-testId="digital-period-info">
                        {digitalPeriod[item.countryName]}
                      </div>
                    </>
                  ) : (
                    <>
                      {/* 24 Hour Format */}
                      <div className="digital-time-info" data-testId="digital-time-hour">
                        {digitalTimeHour[item.countryName]}
                      </div>
                    </>
                  )}
                </div>
                {/* Country Flag in Clock */}
                <div className="country-flag-wrapper">
                  <img src={item.flagURL} alt={`${item.countryName} Flag`} className="country-flag-info" title="digitalClock-flag-info" />
                </div>
              </div>
            </div>
          </div>
          <div className="digital-weekday-info" data-testId="digital-weekday">
            {countryDay[item.countryName]}
          </div>
        </div>
      </>
    )

  const LoaderText = () => <div className="no-data-title"> {isLoading ? t('FETCHING') : t('NO_DATA_FOUND')} </div>

  const handleChangeCountry = (e) => {
    setFilterCountry(e)
    setSelectedCountry(e)
    handleCountrySelection(e)
  }

  return (
    <div className="country-clock-container" data-testId="country-clock">
      {userDetails && (
        <div className="search-container">
          <div className="select-button-wrapper">{displayTab && <SelectButton value={clockTab} onChange={onClockTabChange} options={clockList} className="select-button" allowEmpty={false} data-testId="webview-select-button" />}</div>
          <div className="search-country-wrapper">
            <div className="country-dropdown-wrapper">
              <DropdownComponent isAboutCoffeeWeb={true} currentItem={filterCountry} handleSelectCountry={handleChangeCountry} countryList={clockTab === 'All Clock' ? allClockData : favoriteCountryClock} isError={errorState} testId="country-dropdown" />
              {selectedCountryData && (
                <div className="clear-icon" onClick={handleClearFilter} data-testId="clear-icon">
                  <i className="pi pi-times" />
                  <div className="close-icon-tool-tip">
                    <div>{t('CLOSE')}</div>
                  </div>
                </div>
              )}
            </div>
            <div className="setting-icon" onClick={handleAddButtonClick} data-testId="clockSetting-icon">
              <ClockSetting />
              <div className="setting-tooltip">
                <div>{t('CLOCK_SETTINGS')}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {windowWidth >= 767 ? (
        // Web View UI
        <div className="carousel-wrapper" data-testId="carousel">
          {clockDataForAllCountries.length === 0 && <LoaderText />}
          {<Carousel value={carouselValue} numVisible={numVisible} numScroll={numScroll} itemTemplate={productTemplate} showIndicators={false} onPageChange={pageChange} page={page} showNavigators={clockDataForAllCountries?.length ?? false} />}
        </div>
      ) : (
        <div className="mobile-view-container" data-testId="mobile-view-container">
          {/* Mobile View UI */}
          {userDetails ? (
            <div className="mobile-view-header-wrapper">
              <div className={`${displayTab ? 'select-button-wrapper' : 'remove-button'}`}>
                <SelectButton value={clockTab} onChange={onClockTabChange} options={clockList} className="select-button" allowEmpty={false} data-testId="mobileView-select-button" />
              </div>

              <div className={`${displayTab ? 'setting-wrapper' : 'setting-button-container'}`}>
                <div className="dropdown-wrapper">
                  <DropdownComponent isAboutCoffeeWeb={true} currentItem={selectedCountry} handleSelectCountry={handleChangeCountry} countryList={clockTab === 'All Clock' ? allClockData : favoriteCountryClock} isError={errorState} testId="mobileView-country-dropdown" />
                </div>
                <div className="setting-icon">
                  <ClockSetting onClick={handleAddButtonClick} data-testId="clockSetting-icon" />
                </div>
              </div>
            </div>
          ) : (
            <div className="guest-dropdown-wrapper">
              <DropdownComponent isAboutCoffeeWeb={true} currentItem={selectedCountry} handleSelectCountry={handleChangeCountry} countryList={allClockData} isError={errorState} testId="country-dropdown" />
            </div>
          )}

          <div className="mobile-view-clock-wrapper">
            <div className="clock-content">
              <div className="clock-details-info" data-testId="mobileView-analog-clock">
                {selectedClockType === 'Analog' ? (
                  <>
                    {selectedCountry?.countryName ? (
                      <>
                        <Clock value={countryTime[selectedCountry?.countryName]} renderNumbers={false} hourMarksLength={10} minuteMarksLength={4} size={160} className={getClockClassName(countryTime[selectedCountry?.countryName])} />
                        <img src={selectedCountry?.flagURL} alt={`${selectedCountry?.countryName} Flag`} className="country-flag-info" title="analog-countryFlag" />
                      </>
                    ) : (
                      <div data-testId="mobileView-isLoading">{isLoading ? t('FETCHING') : t('NO_DATA_FOUND')}</div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="digital-clock-container">
                      <div className="digital-clock-wrapper">
                        <div className="digital-clock" data-testId="mobileView-digital-clock">
                          <div className={`digital-clock-item ${getClockClassName(digitalClock[selectedCountry?.countryName])}`} data-testId="countryName-info">
                            <div className="digital-clock-time-wrapper">
                              {timeFormat === '12-hour' ? (
                                <>
                                  <div className="digital-time-info" data-testId="digital-time-info">
                                    {digitalClockTime[selectedCountry?.countryName]}
                                  </div>
                                  <div className="digital-period-info" data-testId="digital-period-info">
                                    {digitalPeriod[selectedCountry?.countryName]}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="digital-time-info" data-testId="digital-time-hour">
                                    {digitalTimeHour[selectedCountry?.countryName]}
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="country-flag-wrapper">
                              <img src={selectedCountry?.flagURL} alt={`${selectedCountry?.countryName} Flag`} className="country-flag-info" title="digital-flag" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="time-details-container">
                <div className="weekday-info" data-testId="digital-weekdays">
                  {countryDay[selectedCountry?.countryName]}
                  {countryDay[selectedCountry?.countryName] && ','}
                </div>
                <div className="current-time-info" data-testId="current-time">
                  {digitalClockTime[selectedCountry?.countryName]}
                </div>
                <div className="current-time-info" data-testId="current-period">
                  {digitalPeriod[selectedCountry?.countryName]}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="clock-setting-layout">{visible && <ClockSettings setDisplayTab={setDisplayTab} visible={visible} setPage={setPage} setVisible={setVisible} pickListSourceData={pickListSourceData} setPickListSourceData={setPickListSourceData} favoriteCountryClockData={favoriteCountryClock} setFavoriteCountryClock={setFavoriteCountryClock} pickListTargetData={pickListTargetData} setPickListTargetData={setPickListTargetData} setClockTab={setClockTab} />}</div>
    </div>
  )
}

export default AnalogClock
