import './App.scss'
import React, { useEffect } from 'react'
import { Switch } from 'react-router-dom'
import CacheBuster from 'react-cache-buster'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { GoogleOAuthProvider } from '@react-oauth/google'
import GuestRoute from 'Components/LayoutComponents/Routes/GuestRoute'
import ProtectedRoute from 'Components/LayoutComponents/Routes/ProtectedRoute'
import { CoffeeWebContextProvider } from 'Context/CoffeeWebContext'
import versionDetail from '../package.json'
import Routes from './Components/LayoutComponents/Routes'
import MyErrorBoundary from './Components/MyErrorBoundary'
import { detectOS, requestPermission } from './firebase.js'

function App() {
  const uniqueKey = Date.now()
  const isProduction = process.env.NODE_ENV === 'production'

  useEffect(() => {
    const os = detectOS()

    if (!(os === 'Mac' || os === 'iOS')) {
      requestPermission()
    }
  }, [])

  return (
    <CoffeeWebContextProvider>
      <MyErrorBoundary>
        <div key={uniqueKey} suppressHydrationWarning={true}>
          <CacheBuster currentVersion={versionDetail.version} isEnabled={isProduction} isVerboseMode={false} metaFileDirectory={'.'}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <div>
                <Switch>
                  <ProtectedRoute path={ROUTE_STRINGS.dashboard} component={Routes} />
                  <GuestRoute component={Routes} />
                </Switch>
              </div>
            </GoogleOAuthProvider>
          </CacheBuster>
        </div>
      </MyErrorBoundary>
    </CoffeeWebContextProvider>
  )
}

export default App
