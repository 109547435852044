import './styles.scss'
import { useContext, useEffect, useState } from 'react'
import { FacebookShareButton, WhatsappShareButton, FacebookIcon, EmailShareButton, EmailIcon, LinkedinShareButton, LinkedinIcon, TwitterShareButton, TwitterIcon, TelegramShareButton, TelegramIcon } from 'react-share'
import { Tooltip } from 'primereact/tooltip'
import { useTranslation } from 'react-i18next'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

const ShareNewsFeed = ({ newsData, closeModal, mockDialogModal, mockTrueSocialMediaList }) => {
  const { t } = useTranslation()
  const { setLoading } = useContext(CoffeeWebContext)

  const shareUrl = `${process.env.REACT_APP_SHARE_URL_ENDPOINT}/share/News/${newsData.encryptedId}`
  const coffeeWebTitle = 'CoffeeWeb News!'
  const appStore = 'App Store :'
  const appStoreUrl = 'https://apps.apple.com/in/app/coffeeweb/id1618878403'
  const playStore = 'Play Store :'
  const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.coffeeweb.app&pli=1'
  const website = 'Website :'
  const websiteUrl = `${process.env.REACT_APP_SHARE_URL_ENDPOINT}`
  const newsTitle = `${coffeeWebTitle} ${newsData.subject.replace(/<\/?b>/g, '')}\n${shareUrl}\n`
  const newsTitleForWhatsApp = `*${coffeeWebTitle}*\n\n${newsData.subject.replace(/<\/?b>/g, '')}\n\n${shareUrl}`
  const shareLinksFowWhatsApp = `\n\n*${appStore}* ${appStoreUrl}\n\n*${playStore}* ${playStoreUrl}\n\n*${website}* ${websiteUrl}`

  const imgUrl = 'https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_menu_icons/CoffeeWeb_Logo_White_Background_Blue_Text.png'

  const [isCopied, setIsCopied] = useState(false)
  const [shortenUrl, setShortenUrl] = useState('')
  const [activeSocialMediaLists, setActiveSocialMediaLists] = useState('')
  const [modalResponse, setModalResponse] = useState(false)

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (mockDialogModal) {
      setActiveSocialMediaLists(mockTrueSocialMediaList)
      setModalResponse(mockDialogModal)
    }
  }, [])

  const init = async () => {
    setLoading(true)
    const getAllShareList = apiAdapterCoffeeWeb.getAllShareList()
    const getShortUrl = apiAdapterCoffeeWeb.shortenUrl(shareUrl)

    try {
      const [getAllShareListResponse, getShortUrlResponse] = await Promise.all([getAllShareList, getShortUrl])

      const activeSocialMediaListNames = getAllShareListResponse.data?.returnLst?.map((ele) => ele.shareName)

      setModalResponse(true)
      setActiveSocialMediaLists(activeSocialMediaListNames)
      setShortenUrl(getShortUrlResponse.data)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const handleCopyClick = () => {
    const copiedText = `${coffeeWebTitle}\n\n${newsData.subject.replace(/<\/?b>/g, '')}\n\n${shareUrl}\n` + '\n' + `${appStore} ${appStoreUrl}\n` + '\n' + `${playStore} ${playStoreUrl}\n` + '\n' + `${website} ${websiteUrl}`

    navigator.clipboard.writeText(copiedText)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }

  const shareListModalContent = () => (
    <div className="share-news-feed" data-testId="share-news-feed-main-container">
      {activeSocialMediaLists.includes('Copy') && (
        <div className="share-news-feed-container" data-testId="copy-container">
          <div className="icon-container copy-button" onClick={handleCopyClick} data-testId="copy-icon-container">
            <i className="pi pi-copy copy-icon" quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} value={shortenUrl} data-testId="tool-tip" />
          </div>
          <p className="icons-text" onClick={handleCopyClick}>
            {t('COPY')}
          </p>
          <Tooltip target=".copy-button" position="bottom" content={isCopied ? 'Copied!' : 'Copy'} className="copy-tooltip" />
        </div>
      )}
      {activeSocialMediaLists.includes('WhatsApp') && (
        <div className="share-news-feed-container" data-testId="whats-app-container">
          <WhatsappShareButton url={shareLinksFowWhatsApp} quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} title={newsTitleForWhatsApp} media={imgUrl}>
            <div className="icon-container" data-testId="whatsapp-icon-container">
              <i className="pi pi-whatsapp whatsapp-icon" />
            </div>
            <p className="icons-text">{t('WHATS_APP')}</p>
          </WhatsappShareButton>
        </div>
      )}
      {activeSocialMediaLists.includes('Facebook') && (
        <div className="social-media-div">
          <FacebookShareButton url={shortenUrl} quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} title={newsTitle} media={imgUrl}>
            <FacebookIcon size={40} round={true} />
            <p className="icons-text">{t('FACEBOOK')}</p>
          </FacebookShareButton>
        </div>
      )}
      {activeSocialMediaLists.includes('LinkedIn') && (
        <div className="social-media-div">
          <LinkedinShareButton url={shortenUrl} quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} title={newsTitle} media={imgUrl}>
            <LinkedinIcon size={40} round={true} />
            <p className="icons-text">{t('LINKED_IN')}</p>
          </LinkedinShareButton>
        </div>
      )}
      {activeSocialMediaLists.includes('Twitter') && (
        <div className="social-media-div">
          <TwitterShareButton url={shortenUrl} quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} title={newsTitle} media={imgUrl}>
            <TwitterIcon size={40} round={true} />
            <p className="icons-text">{t('TWITTER')}</p>
          </TwitterShareButton>
        </div>
      )}
      {activeSocialMediaLists.includes('Telegram') && (
        <div className="social-media-div">
          <TelegramShareButton url={shortenUrl} quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} title={newsTitle} media={imgUrl}>
            <TelegramIcon size={40} round={true} />
            <p className="icons-text">{t('TELEGRAM')}</p>
          </TelegramShareButton>
        </div>
      )}
      {activeSocialMediaLists.includes('Email') && (
        <div className="social-media-div">
          <EmailShareButton url={shortenUrl} quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} title={newsTitle} media={imgUrl}>
            <EmailIcon size={40} round={true} />
            <p className="icons-text">{t('MAIL')}</p>
          </EmailShareButton>
        </div>
      )}
    </div>
  )

  return <DialogUIModal title={t('SHARE_MODAL')} showModal={modalResponse} handleClose={closeModal} bodyContent={shareListModalContent} shareNews={true} titlePosition="center" testId="share-news-feed-dialog-modal" />
}

export default ShareNewsFeed
