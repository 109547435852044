import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { getLocalLoggedInStatus, getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import CreateAccountInfoModal from 'Components/UIComponent/CreateAccountInfoModal'
import { ROUTE_STRINGS } from 'Utils/Constants'
import DropdownComponent from 'Components/DropdownComponent'
import CountryWiseExportsReport from './Components/CountryWiseExportsReport'
import CommitmentOfTradersReport from './Components/CommitmentOfTradersReport'
import CertifiedStockReport from './Components/CertifiedStockReport'

const CoffeeStockReports = () => {
  const coffeeReports = [
    {
      id: 1,
      report: 'Certified Stocks Report'
    },
    {
      id: 2,
      report: 'Commitment of Traders Report'
    },
    {
      id: 3,
      report: 'Country Wise Exports Report'
    }
  ]
  const marketName = [
    {
      idMarket: 1,
      marketName: 'Robusta',
      marketCode: 'R',
      searchCode: 'RC'
    },
    {
      idMarket: 2,
      marketName: 'Arabica',
      marketCode: 'F',
      searchCode: 'AC'
    }
  ]

  const { t } = useTranslation()
  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''
  const userDetails = getLocalUserDetails()
  const isUserLoggedIn = getLocalLoggedInStatus()
  const { sub: { subType } = {} } = userDetails || {}

  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }

  const [coffeeReport, setCoffeeReports] = useState([])
  const [activeTab, setActiveTab] = useState('tab1')
  const [selectedMarket, setSelectedMarket] = useState('Robusta')

  useEffect(() => {
    const defaultReportValue = coffeeReports.map((item) => item)

    setCoffeeReports(defaultReportValue[0])
  }, [])

  const handleCoffeeReportsChange = (event) => {
    setCoffeeReports(event)
    setActiveTab('tab1')
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const getButtonAndInfo = () => {
    if (!userDetails) {
      return {
        buttonText: t('LOGIN_AND_CREATE_ACCOUNT'),
        infoMessage: t('TO_ACCESS_INFO_GLOBAL_DIFFERENTIAL'),
        infoMessage2: t('TO_ACCESS_GLOBAL_DIFFERENTIAL_FEATURE'),
        className: 'login-button',
        navigationPath: ROUTE_STRINGS.login,
        state: {}
      }
    }
    if (subType === 1) {
      return {
        buttonText: t('BUY_SUBSCRIPTION'),
        infoMessage: t('TO_ACCESS_GLOBAL_DIFFERENTIAL_FEATURE'),
        className: 'buy-subscription-button',
        navigationPath: ROUTE_STRINGS.subscription,
        state: { subscriptionId: 3 }
      }
    }
    if (subType === 2) {
      return {
        buttonText: t('UPGRADE'),
        infoMessage: t('TO_ACCESS_GLOBAL_DIFFERENTIAL_FEATURE'),
        className: 'buy-subscription-button',
        navigationPath: ROUTE_STRINGS.subscription,
        state: { subscriptionId: 3 }
      }
    }

    return {
      buttonText: t('LOGIN_AND_CREATE_ACCOUNT'),
      infoMessage: t('TO_ACCESS_INFO_GLOBAL_DIFFERENTIAL'),
      className: 'login-button',
      navigationPath: ROUTE_STRINGS.login,
      state: {}
    }
  }

  const { buttonText, infoMessage, className, navigationPath, state } = getButtonAndInfo()

  const getTabsConfig = () => {
    if (coffeeReport.id === 1) {
      return [
        { id: 'tab1', label: t('TOTAL_CERTIFIED_STOCKS') },
        { id: 'tab2', label: t('ALL_COUNTRY_CERTIFIED_STOCK') },
        { id: 'tab3', label: selectedMarket === 'Robusta' ? t('PORT_WISE_CERTIFIED_STOCK') : t('COUNTRYWISE_CERTIFIED_STOCK') }
      ]
    }
    // eslint-disable-next-line no-else-return
    else if (coffeeReport.id === 2) {
      return [
        { id: 'tab1', label: t('LOTWISE_COMPARISON') },
        { id: 'tab2', label: t('WEEKLY_WISE_COT_REPORTS') },
        { id: 'tab3', label: t('TRADERWISE_COMPARISON') }
      ]
    }

    return []
  }

  const tabsConfig = getTabsConfig()

  return (
    <div className="coffee-reports-component">
      <div className={coffeeReport.id === 1 || coffeeReport.id === 2 ? 'coffee-report-container' : 'country-wise-export-report'}>
        <div className="coffee-report-header">
          <div className="title">{t('COFFEE_REPORTS')}</div>
          <div className="coffee-report-dropdown">
            <DropdownComponent handleSelectCountry={handleCoffeeReportsChange} countryList={coffeeReports} currentItem={coffeeReport} setCurrentItem={setCoffeeReports} placeholderText={coffeeReports.length ? t('SELECT_REPORT') : t('NO_DATA_FOUND')} coffeeReportDropDown={true} height="41px" />
          </div>
        </div>

        {coffeeReport?.id === 1 && <CertifiedStockReport activeTab={activeTab} marketName={marketName} selectedMarket={selectedMarket} setSelectedMarket={setSelectedMarket} />}
        {coffeeReport?.id === 2 && <CommitmentOfTradersReport activeTab={activeTab} marketName={marketName} selectedMarket={selectedMarket} setSelectedMarket={setSelectedMarket} />}
        {coffeeReport?.id === 3 && (
          <div className="coffee-report-table">
            <CountryWiseExportsReport />
          </div>
        )}
      </div>

      {(coffeeReport?.id === 1 || coffeeReport?.id === 2) && (
        <div className="tab-wrapper">
          <div className="tab-container">
            {tabsConfig.map(({ id, label }) => (
              <>
                <input type="radio" name="tab" id={id} className={`tab ${id}`} />
                <label className="tab_label" htmlFor={id} onClick={() => handleTabClick(id)}>
                  {label}
                </label>
              </>
            ))}

            <div className="indicator"></div>
          </div>
        </div>
      )}

      {(!isUserLoggedIn || menuLock) && (
        <div className={`coffee-reports-create-account-info-modal-popup ${isUserLoggedIn ? 'create-account-info-message' : 'guest-create-account-info-message'}`}>
          <CreateAccountInfoModal buttonText={buttonText} infoMessage={infoMessage} className={className} navigationPath={navigationPath} state={state} DropdownComponent={DropdownComponent} />
        </div>
      )}
    </div>
  )
}

export default CoffeeStockReports
