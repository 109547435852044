import './styles.scss'
import React from 'react'
import { Button as PrimeReactButton } from 'primereact/button'
import { useTranslation } from 'react-i18next'

const Button = ({ buttonType, buttonLabel, variant, onButtonClick, buttonSize, buttonWidth, buttonIcon, buttonHover, testingId }) => {
  const { t } = useTranslation()

  const actions = {
    CLOSE: { labelName: t('CLOSE'), buttonVariant: 'soft-action' },
    CANCEL: { labelName: t('CANCEL'), buttonVariant: variant ?? 'soft-action' },
    SAVE: { labelName: t('SAVE'), buttonVariant: 'hard-action' },
    SUBMIT: { labelName: t('SUBMIT'), buttonVariant: 'hard-action' },
    CONTINUE: { labelName: t('CONTINUE'), buttonVariant: 'hard-action' },
    CUSTOM: { labelName: buttonLabel ?? t('CUSTOM_BUTTON'), buttonVariant: variant ?? 'hard-action' }
  }

  const action = actions[buttonType?.toUpperCase()]
  const { labelName, buttonVariant } = action

  return (
    <div className="button-wrapper">
      <PrimeReactButton label={labelName} icon={buttonIcon ?? null} className={`${buttonVariant} ${buttonSize ?? ''} ${buttonWidth ?? ''} ${buttonHover ? '' : 'disable-hover'}`} onClick={onButtonClick} data-testId={testingId} />
    </div>
  )
}

export default Button
