import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ROUTE_STRINGS } from 'Utils/Constants'
import DialogModal from 'Components/UIComponent/DialogModal'

const ApiResponseHandler = (WrappedComponent) => {
  function WrappedWithResponseHandler(props) {
    const { t } = useTranslation()
    const history = useHistory()
    const [statusCode, setStatusCode] = useState(null)
    const [responseDetails, setResponseDetails] = useState(null)
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
      const handleOnlineStatusChange = () => {
        if (!navigator.onLine) {
          setStatusCode('networkFail')
          setShowModal(true)
        } else {
          setShowModal(false)
        }
      }

      window.addEventListener('online', handleOnlineStatusChange)
      window.addEventListener('offline', handleOnlineStatusChange)

      return () => {
        window.removeEventListener('online', handleOnlineStatusChange)
        window.removeEventListener('offline', handleOnlineStatusChange)
      }
    }, [])

    const handleApiResponse = (errorObject) => {
      setResponseDetails(errorObject)
      setStatusCode(errorObject.status)
      setShowModal(true)
    }

    const logout = () => {
      history.push(ROUTE_STRINGS.login)
      localStorage.clear()
    }

    if (statusCode === 401) {
      setTimeout(() => {
        logout()
      }, 3000)
    }

    const handleDismiss = () => {
      setShowModal(false)

      if (responseDetails && responseDetails.onDismiss) {
        responseDetails.onDismiss()
      }
    }

    const handleRetry = () => {
      setShowModal(false)

      if (responseDetails && responseDetails.onRetry) {
        responseDetails.onRetry()
      } else {
        window.location.reload()
      }
    }

    const getResponseContent = (statusType) => {
      const responseContent = {
        401: {
          modalType: 'error',
          headerMessage: t('SESSION_EXPIRED'),
          customMessage: t('PLEASE_LOGIN'),
          hardAction: t('LOGOUT'),
          onRetry: logout,
          onHide: handleDismiss,
          onDismiss: handleDismiss
        },
        404: {
          modalType: 'error',
          message: (responseDetails && responseDetails.message) || t('FAILED_TO_FETCH_DATA'),
          // headerMessage: (responseDetails && responseDetails.headerMessage) || t('SOMETHING_WENT_WRONG'),
          // customMessage: (responseDetails && responseDetails.customMessage) || t('FAILED_TO_FETCH_DATA'),
          onRetry: handleRetry,
          onHide: handleDismiss,
          onDismiss: handleDismiss
        },
        500: {
          modalType: 'error',
          headerMessage: t('SERVER_ERROR'),
          customMessage: t('PLEASE_TRY_AGAIN'),
          onRetry: handleRetry,
          onHide: handleDismiss,
          onDismiss: handleDismiss
        },
        400: {
          modalType: 'error',
          message: (responseDetails && responseDetails.message) || t('FAILED_TO_UPDATE_DETAILS'),
          onRetry: handleRetry,
          onHide: handleDismiss,
          onDismiss: handleDismiss
        },
        200: {
          modalType: 'error',
          headerMessage: responseDetails && responseDetails.headerMessage,
          customMessage: responseDetails && responseDetails.customMessage,
          message: (responseDetails && responseDetails.message) || t('NO_DATA_FOUND'),
          onRetry: handleRetry,
          onHide: handleDismiss,
          onDismiss: handleDismiss
        },
        201: {
          modalType: 'error',
          message: (responseDetails && responseDetails.message) || t('NO_DATA_FOUND'),
          onRetry: handleRetry,
          onHide: handleDismiss,
          onDismiss: handleDismiss
        },
        networkFail: {
          modalType: 'error',
          headerMessage: t('NO_INTERNET_CONNECTION'),
          customMessage: t('PLEASE_CHECK_YOUR_CONNECTION'),
          onRetry: () => window.location.reload(),
          onHide: handleDismiss,
          onDismiss: handleDismiss
        }
      }

      return (
        responseContent[statusType] || {
          modalType: 'error',
          message: (responseDetails && responseDetails.message) || t('SOMETHING_WENT_WRONG'),
          onRetry: handleRetry,
          onHide: handleDismiss,
          onDismiss: handleDismiss
        }
      )
    }

    return (
      <>
        <DialogModal showModal={showModal} {...getResponseContent(statusCode)} />
        <WrappedComponent {...props} handleApiResponse={handleApiResponse} />
      </>
    )
  }

  return WrappedWithResponseHandler
}

export default ApiResponseHandler
