import React, { useState, useEffect, useContext } from 'react'
import './styles.scss'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { FloatLabel } from 'primereact/floatlabel'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import Button from 'Components/UIComponent/Button'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import razorpay from './PaymentServices/razorpay'
import paypal from './PaymentServices/paypal'
import stripe from './PaymentServices/stripe'

const PaymentGateway = ({ showSuccess, showError }) => {
  const userDetails = getLocalUserDetails()
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const { setLoading } = useContext(CoffeeWebContext)

  const params = new URLSearchParams(location.search)
  const selectedSubscriptionId = params.get('id')
  const { state: selectedSubscription } = location
  const [subPaymentMapping, setSubPaymentMapping] = useState([])
  const [selectedSubscriptionDetailes, setSelectedSubscriptionDetailes] = useState({})
  const [stateList, setStateList] = useState([])
  const [userSelectedState, setUserSelectedState] = useState(userDetails.idState)
  const [billingInformation, setBillingInformation] = useState()
  const [selectedState, setSelectedState] = useState({})
  const [userStateId, setUserStateId] = useState({})
  const [accordingSymbol, setAcoordingSymbol] = useState(true)
  const [gstInput, setgstInput] = useState({
    gstNumber: '',
    organistationName: '',
    organistationAddress: '',
    selectedState: '',
    email: userDetails?.email,
    userName: `${userDetails.firstName} ${userDetails.lastName}`
  })

  const subscriptionColorConst = [
    {
      id: '0',
      color: '#cbcccd, #e5e8eb, #cbcccd'
    },
    {
      id: '1',
      color: '#50b3f6, #8DD5F4, #50b3f6'
    },
    {
      id: '2',
      color: '#a48011, #e7cd77, #a48011'
    },
    {
      id: '3',
      color: '#8A8A8A, #D4D4D4, #8A8A8A'
    }
  ]

  useEffect(() => {
    GetSubscriptionTypes()
    getGstDetails()
    getStateList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchUserStateData()
  }, [stateList])

  const GetSubscriptionTypes = async () => {
    setLoading(true)
    await apiAdapterCoffeeWeb
      .getAllSubscriptionPaymentMappingById({ id: selectedSubscriptionId, userId: userDetails.id })
      .then((response) => {
        setSubPaymentMapping(response.data?.returnLst)
        setSelectedSubscriptionDetailes(response.data?.returnLst[0])
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)

        showError({
          title: t('ERROR_TITLE'),
          message: `<p>${t('WE_ARE_UNABLE_TO_PROCEED_WITH_THE_SELECTED_PLAN')}</p><p>${t('PLEASE_TRY_AGAIN_OR_CHOOSE_ANOTHER_ONE')}</p><p>${t('CONTACT_SUPPORT_TEAM')}</p>`,
          buttonLabel: t('OK'),
          onConfirm: () => {
            history.push({ pathname: ROUTE_STRINGS.subscription })
          },
          onCancel: () => {
            history.push({ pathname: ROUTE_STRINGS.subscription })
          }
        })
      })
  }

  const getStateList = async () => {
    await apiAdapterCoffeeWeb
      .getStateList(userDetails.countryId)
      .then((response) => {
        if (response.status === 200) {
          setStateList(response.data)
        }
      })
      .catch(() => {
        // errorHandler
      })
  }

  const selectPaymentBtn = (ele) => {
    const razorPaymentInstance = razorpay({ gstInput, setLoading, showError })
    const paypalPaymentInstances = paypal({ setLoading, showError })
    const stripeInstances = stripe({ setLoading, showError })

    if (ele.paymentName === 'PayPal') {
      paypalPaymentInstances.paypalCreateOrder(ele)
    }

    if (ele.paymentName === 'Stripe') {
      stripeInstances.stripeCreateOrder(ele)
    }
    if (ele?.paymentName === 'RazorPay') {
      razorPaymentInstance.razorpayCreateOrder(ele, billingInformation)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setgstInput((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const postGstDetailes = async () => {
    const { userName, organistationName, organistationAddress, gstNumber, email } = gstInput
    const { id: userId, firstName } = userDetails
    const getOrDefault = (value, defaultValue) => value || defaultValue

    const gstData = {
      idUserGstdetails: 0,
      userId,
      userName: getOrDefault(userName, firstName),
      organistationName,
      organistationAddress,
      stateId: selectedState ? selectedState.idState : 0,
      gstno: getOrDefault(gstNumber, 'NA'),
      entryDate: new Date().toISOString(),
      lastUsed: true,
      lastUsedDtm: new Date().toISOString(),
      email: getOrDefault(email, userDetails.email)
    }

    await apiAdapterCoffeeWeb
      .postUserGSTDetail(gstData)
      .then(() => {
        showSuccess({
          title: t('SUCCESS'),
          message: t('UPDATED_SUCCESSFULLY'),
          buttonLabel: t('OK')
        })

        getGstDetails()
      })
      .catch(() => {
        showError({
          title: t('ERROR_TITLE'),
          message: t('UPDATE_FAILED'),
          buttonLabel: t('OK')
        })
      })
  }

  const getGstDetails = async () => {
    await apiAdapterCoffeeWeb
      .getUserGSTDetailByList(userDetails.id)
      .then((response) => {
        if (response.status === 200) {
          setAcoordingSymbol(true)
          const userActiveData = response.data?.returnLst.filter((item) => item.lastUsed === true)

          setBillingInformation(userActiveData[0])

          setgstInput({
            ...gstInput,
            organistationAddress: userActiveData[0]?.organistationAddress,
            organistationName: userActiveData[0]?.organistationName,
            gstNumber: userActiveData[0]?.gstno,
            userName: userActiveData[0]?.userName === '' ? userDetails.firstName : userActiveData[0]?.userName,
            email: userActiveData[0]?.email === '' ? userDetails.email : userActiveData[0]?.email
          })

          setUserStateId(userActiveData[0]?.stateId)
          setUserSelectedState(userActiveData[0]?.stateId)
        }
      })
      .catch(() => {
        // errorHandler
      })
  }

  const fetchUserStateData = () => {
    if (stateList) {
      const userState = stateList.find((ele) => ele.idState === userStateId)

      setSelectedState(userState)
      handleStateSelection(null, userState)
    }
  }

  const handleStateSelection = (event, userState) => {
    const value = event ? event.target.value : userState

    setSelectedState(value)
    setUserSelectedState(value)
  }

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 2

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }

  const selectedColor = subscriptionColorConst.filter(({ id }) => id === selectedSubscriptionId).map(({ color }) => color)
  const neutralColor = selectedColor.length ? selectedColor.toString().slice(0, 7) : subscriptionColorConst[0].color

  const subscriptionColor = selectedSubscription ? selectedSubscription.subscriptionColor.slice(0, 7) : neutralColor.slice(0, 7)

  return (
    <>
      {subPaymentMapping.length > 0 && (
        <div className="payment-container">
          <div className="container payment-method">
            <div
              className="card v-100 shadow card1 payment-card"
              style={{
                boxShadow: `0px 0px 10px ${subscriptionColor}`,
                border: `1px solid ${subscriptionColor}`
              }}
            >
              <div className="order-container" style={{ backgroundColor: ` ${subscriptionColor}` }}>
                <h4 className="order-summary">{t('ORDER_SUMMARY')}</h4>
              </div>
              <div className="subscription-conatiner">
                <div className="subscription-name">{selectedSubscriptionDetailes?.name}</div>
                <div className="subscription-country">{userDetails.countryId === 102 ? `${selectedSubscriptionDetailes?.currencyType}` : '$'}</div>
                <div className="subscription-baseprice">{userDetails.countryId === 102 ? `${selectedSubscriptionDetailes?.basePrice}` : `${selectedSubscriptionDetailes?.amountInDollars}.00`}</div>
              </div>
              {userDetails.countryId === 102 && (
                <>
                  {userSelectedState === 17 ? (
                    <>
                      {selectedSubscriptionDetailes?.discountedamount > 0 && (
                        <>
                          <div className="subscription-conatiner">
                            <div className="discount-amount">{t('DISCOUNTED_AMOUNT')}</div>
                            <div>{selectedSubscriptionDetailes?.currencyType}</div>
                            <div className="discount-currency">{selectedSubscriptionDetailes?.discountedamount}</div>
                          </div>
                          <div className="subscription-conatiner">
                            <div className="actual-amount">
                              {t('ACTUAL_AMOUNT')} {selectedSubscriptionDetailes?.name}
                            </div>
                            <div className="actual-amount">{selectedSubscriptionDetailes?.currencyType}</div>
                            <div className="actual-currency">{selectedSubscriptionDetailes?.actualAmount}</div>
                          </div>
                        </>
                      )}
                      <div className="subscription-conatiner">
                        <div className="cgst">
                          {t('CGST_AND_FEES')}({selectedSubscriptionDetailes?.cgst}%)
                        </div>
                        <div className="cgst-currencytype">{selectedSubscriptionDetailes?.currencyType}</div>
                        <div className="cgst-amount">{selectedSubscriptionDetailes?.cgstAmount}</div>
                      </div>
                      <div className="subscription-conatiner">
                        <div className="sgst">
                          {t('SGST_AND_FEES')}({selectedSubscriptionDetailes?.sgst}%)
                        </div>
                        <div className="sgst-currency">{selectedSubscriptionDetailes?.currencyType}</div>
                        <div className="sgst-amount">{selectedSubscriptionDetailes?.sgstAmount}</div>
                      </div>
                      {selectedSubscriptionDetailes?.roundoftonearby > 0 && (
                        <div className="subscription-conatiner">
                          <div className="round-to-nearby">Round of to nearby</div>
                          <div className="round-to-nearby-currency">{selectedSubscriptionDetailes?.currencyType}</div>
                          <div className="round-to-nearby-details">{selectedSubscriptionDetailes?.roundoftonearby}</div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="subscription-conatiner">
                        <div className="igst">
                          {t('IGST_AND_FEES')}({selectedSubscriptionDetailes?.igst}%)
                        </div>
                        <div className="igst-currencytype">{selectedSubscriptionDetailes?.currencyType}</div>
                        <div className="igst-amount">{selectedSubscriptionDetailes?.igstAmount}</div>
                      </div>
                      {selectedSubscriptionDetailes?.roundoftonearby > 0 && (
                        <div className="subscription-conatiner">
                          <div className="round-to-nearby">{t('ROUND_OF_TO_NEARBY')}</div>
                          <div className="round-to-nearby-currency">{selectedSubscriptionDetailes?.currencyType}</div>
                          <div className="round-to-nearby-details">{selectedSubscriptionDetailes?.roundoftonearby}</div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}

              <hr></hr>
              {userDetails.countryId === 102 && (
                <>
                  {userSelectedState === 17 ? (
                    <div className="subscription-conatiner" style={{ margin: '0px 0px 10px' }}>
                      <div className="total">{t('TOTAL')}</div>
                      <div className="total-currency">{userDetails.countryId === 102 ? `${selectedSubscriptionDetailes?.currencyType}` : '$'}</div>
                      <div className="cgst-tax">{userDetails.countryId === 102 ? `${selectedSubscriptionDetailes?.csgstTaxableAmount}` : `${selectedSubscriptionDetailes?.amountInDollars}.00`}</div>
                    </div>
                  ) : (
                    <div className="subscription-conatiner" style={{ margin: '0px 0px 10px' }}>
                      <div className="total">{t('TOTAL')}</div>
                      <div className="total-currency">{userDetails.countryId === 102 ? `${selectedSubscriptionDetailes?.currencyType}` : '$'}</div>
                      <div className="cgst-tax">{userDetails.countryId === 102 ? `${selectedSubscriptionDetailes?.igstTaxableAmount}` : `${selectedSubscriptionDetailes?.amountInDollars}.00`}</div>
                    </div>
                  )}
                </>
              )}
              {userDetails.countryId !== 102 && (
                <>
                  <div className="subscription-conatiner" style={{ margin: '0px 0px 10px' }}>
                    <div className="total">{t('TOTAL')}</div>
                    <div className="total-currencytype">{userDetails.countryId === 102 ? `${selectedSubscriptionDetailes?.currencyType}` : '$'}</div>
                    <div className="igst-amount">{userDetails.countryId === 102 ? `${selectedSubscriptionDetailes?.igstTaxableAmount}` : `${selectedSubscriptionDetailes?.amountInDollars}.00`}</div>
                  </div>
                </>
              )}
            </div>

            <div
              className="payment-card"
              style={{
                border: `1px solid ${subscriptionColor}`
              }}
            >
              <div
                className="payment-method-container"
                style={{
                  backgroundColor: ` ${subscriptionColor}`
                }}
              >
                <h4 className="payment-method-heading">{t('PAYMENT_METHOD')}</h4>
              </div>
              <div className="no-payment">
                <>
                  {subPaymentMapping.length === 0 ? (
                    <h4 className="no-payment-available">{t('NO_PAYMENT_METHOD_AVAILABLE')}</h4>
                  ) : (
                    subPaymentMapping.map((ele, index) => (
                      <div key={index} className="paypal-payment-btn" onClick={() => selectPaymentBtn(ele)}>
                        <img alt="" src={ele.imageUrl} />
                      </div>
                    ))
                  )}
                </>
              </div>
            </div>
          </div>

          <div className="container payment-method-gst">
            <div className="payment-card-gst">
              <div className="billingInfo-card-gst " style={{ boxShadow: `0px 0px 10px ${subscriptionColor}`, border: `1px solid ${subscriptionColor}` }}>
                <div
                  className="gst-header-card"
                  style={{
                    backgroundColor: ` ${subscriptionColor}`
                  }}
                >
                  <div className="billing-text">{t('BILLING_INFORMATION')}</div>
                  <div className="according-symbol ">{accordingSymbol === true ? <i className="pi pi-pencil" onClick={() => setAcoordingSymbol(false)} /> : <i className="pi pi-pen-to-square" onClick={() => setAcoordingSymbol(true)} />}</div>
                </div>

                {accordingSymbol && (
                  <div className="card shadow m-1" style={{ padding: '15px' }}>
                    {gstInput.userName}
                  </div>
                )}

                {!accordingSymbol && (
                  <div className="text-field">
                    <div className="input-field">
                      <FloatLabel>
                        <InputText id="userName" name="userName" value={gstInput?.userName} onChange={handleChange} className="p-inputtext-sm" autoComplete="off" />
                        <label htmlFor="userName" className="input-field-label">
                          {t('NAME')}
                        </label>
                      </FloatLabel>
                    </div>

                    <div className="input-field">
                      <FloatLabel>
                        <InputText id="organistationName" name="organistationName" value={gstInput?.organistationName} onChange={handleChange} className="p-inputtext-sm" autoComplete="off" />
                        <label htmlFor="organistationName" className="input-field-label">
                          {t('ORGANIZATION_NAME')}
                        </label>
                      </FloatLabel>
                    </div>

                    <div className="input-field">
                      <FloatLabel>
                        <InputText id="organistationAddress" name="organistationAddress" value={gstInput?.organistationAddress} onChange={handleChange} className="p-inputtext-sm" autoComplete="off" />
                        <label htmlFor="organistationAddress" className="input-field-label">
                          {t('BILLING_ADDRESS')}
                        </label>
                      </FloatLabel>
                    </div>

                    <div className="input-field">
                      <FloatLabel>
                        <InputText id="email" name="email" value={gstInput?.email} onChange={handleChange} className="p-inputtext-sm" autoComplete="off" />

                        <label htmlFor="email" className="input-field-label">
                          {t('EMAIL')}
                        </label>
                      </FloatLabel>
                    </div>

                    {userDetails.countryId === 102 && (
                      <>
                        <div className="dropdown-field-wrapper">
                          <FloatLabel>
                            <Dropdown id={'stateName'} options={stateList} value={selectedState} onChange={handleStateSelection} optionLabel="stateName" filter filterBy="stateName" className="state-selection-dropdown" panelClassName="state-select-dropdown-panel" />
                            <label htmlFor="stateName" className="input-field-label">
                              {t('SELECT_STATE')}
                            </label>
                          </FloatLabel>
                        </div>
                        <div className="input-field">
                          <FloatLabel>
                            <InputText id="gstNumber" name="gstNumber" value={gstInput?.gstNumber} onChange={handleChange} className="p-inputtext-sm" autoComplete="off" />

                            <label htmlFor="gstNumber" className="input-field-label">
                              {t('GST_NO')}
                            </label>
                          </FloatLabel>
                        </div>
                      </>
                    )}
                    <div className="invoice-note">
                      <span style={{ margin: '0px 10px' }}>{t('NOTE_INVOICE')}</span>
                    </div>

                    <Button buttonType="custom" buttonLabel={t('SAVE_DETAILS')} buttonWidth="full" buttonSize="small" buttonHover={true} onButtonClick={postGstDetailes} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ErrorMessageModal(PaymentGateway)
