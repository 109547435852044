import './styles.scss'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { i18n, t } = useTranslation()

  return (
    <>
      <div className="footer">
        <div className="container py-1 text-center">
          <span>
            {t('COPYRIGHT')} <b>{t('COFFEE_WEB')}</b>
            {t('ALL_RIGHTS_RESERVED')}
          </span>
        </div>
      </div>
    </>
  )
}

export default Footer
