/* eslint-disable */
import './styles.scss'
import React, { useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { getLocalAppSettings, getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

const Menubar = () => {
  const userDetails = getLocalUserDetails()
  const appSettings = getLocalAppSettings()
  const { isLoading } = useContext(CoffeeWebContext)

  useEffect(() => {
    if (appSettings?.showMenuBold === true) {
      document.documentElement.style.setProperty('--setFontWeight', 'bold')
    }
  }, [])

  const transformMenuUrl = (url) => '/dashboard/' + url

  return (
    <>
      {userDetails.userRole !== 5 && (
        <div className={`post_header ${isLoading ? 'is-loading' : ''}`} data-testid="menu-main-container">
          <div className="" id="navbar-main" data-testid="navbar-main-container">
            <div>
              <div aria-controls="basic-navbar-nav" />
              <div id="basic-navbar-nav" data-testid="basic-navbar-container">
                <ul className="navbar-nav" data-testid="navbar-ul-container">
                  {userDetails?.menus !== null &&
                    userDetails?.menus?.map((ele, index) => (
                      <div key={index}>
                        {ele.isWebDisplayEnable === true && ele.isLaunch === true && ele.menuUrl !== null && (
                          <li className="nav-item1 menucolor1" id="navitem" data-testid="navbar-li-container">
                            <NavLink activeClassName="active_nav-link1" exact to={transformMenuUrl(ele?.menuUrl)} className="nav-link1 nav-link-ltr">
                              <div style={{ padding: '0px 5px' }} data-testid="menu-name-container">
                                {ele.menuName}
                              </div>
                            </NavLink>
                          </li>
                        )}
                      </div>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Menubar
