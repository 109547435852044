import './styles.scss'
import React, { useEffect, useState, useContext } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Badge } from 'primereact/badge'
import { OverlayPanel } from 'primereact/overlaypanel'
import { doc, onSnapshot } from 'firebase/firestore'
import { getLocalLatestNewsReadNotification, getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { db } from 'firebase.js'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

const NotificationListenService = ({ showConfirmation, overlayPanelRef }) => {
  const userDetails = getLocalUserDetails()
  const params = new URLSearchParams()

  const history = useHistory()
  const { t } = useTranslation()
  const { isLoading, setLoading } = useContext(CoffeeWebContext)

  const onlyPageSize = 8
  const [userNotificationForUser, setUserNotificationForUser] = useState([])
  const [onlyPageNumber, setOnlyPageNumber] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [showTostify, setShowTostify] = useState(false)
  const [firebaseLatestNewsId, setFirebaseLatestNewsId] = useState(null)
  const [notificationCount, setNotificationCount] = useState(0)
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)
  const [isIconClicked, setIsIconClicked] = useState(false)

  const [notificationMessageDetail, setNotificationMessageDetail] = useState({
    message: '',
    notificationId: ''
  })

  useEffect(() => {
    const cleanUp = init()

    return () => {
      cleanUp()
    }
  }, [])

  const init = () => {
    getUsersNotificationForUser(onlyPageNumber, onlyPageSize)
    let mounted = false

    if (userDetails?.phone || userDetails?.email) {
      const firebaseNewsId = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb_Devp' : 'CoffeeWeb', 'NewsFeeds'), (doc) => {
        const firebaseResponse = doc.data()

        const subscriptionList = firebaseResponse?.LatestNewsForSubscription?.split(',')?.map((number) => parseInt(number.trim(), 10))
        const countryList = firebaseResponse?.LatestNewsForCountries?.split(',')?.map((number) => parseInt(number.trim(), 10))

        if (mounted) {
          if (subscriptionList.includes(userDetails?.sub?.subType) && countryList.includes(userDetails?.countryId) && !firebaseResponse?.IsDeleted && firebaseResponse?.LatestNewsLanguage === userDetails?.languageId && firebaseResponse?.SendNotification) {
            setFirebaseLatestNewsId(firebaseResponse?.LatestNewsId)
          }
        } else {
          mounted = true
        }
      })

      const firebaseNotificationCount = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb_Devp' : 'CoffeeWeb', userDetails?.googlesignid ? userDetails?.email : userDetails?.phone), (doc) => {
        const firebaseResponse = doc.data()

        if (firebaseResponse?.UserNotifications?.UnreadCount >= 0) {
          setNotificationCount(firebaseResponse?.UserNotifications?.UnreadCount)
          setUnreadNotificationCount(firebaseResponse?.UserNotifications?.UnreadCount)
        }
      })

      return () => {
        firebaseNewsId()
        firebaseNotificationCount()
      }
    }
  }

  useEffect(() => {
    const latestNewsReadNotification = getLocalLatestNewsReadNotification()

    const selectedNewsId = typeof latestNewsReadNotification === 'number' ? [latestNewsReadNotification] : latestNewsReadNotification

    const newArray = userNotificationForUser?.map((ele) => {
      if (selectedNewsId?.includes(ele.sourceId)) {
        return { ...ele, isRead: true }
      }

      return ele
    })

    setUserNotificationForUser(newArray)

    if (notificationCount === 0) {
      setUserNotificationForUser(userNotificationForUser?.map((ele) => ({ ...ele, isRead: true })))
    }
  }, [notificationCount])

  useEffect(() => {
    if (firebaseLatestNewsId) {
      getNewsAndMedia()
    }
  }, [firebaseLatestNewsId])

  useEffect(() => {
    const body = document.querySelector('body')

    body.style.overflow = isIconClicked ? 'hidden' : 'auto'
  }, [isIconClicked])

  const getUsersNotificationForUser = async (pageNumber, pageSize) => {
    await apiAdapterCoffeeWeb
      .getUsersNotificationForUser({
        userId: userDetails.id,
        pageNumber,
        pageSize
      })
      .then((response) => {
        if (response.data?.returnLst?.appNotificationDTO.length > 0) {
          setUserNotificationForUser((userNotificationForUser) => [...userNotificationForUser, ...response.data.returnLst.appNotificationDTO])
          setOnlyPageNumber(onlyPageNumber + 1)

          const unReadNotifications = response.data.returnLst.notificationCount.unreadNotification

          setUnreadNotificationCount(unReadNotifications)
        } else {
          setHasMore(false)
        }
      })
      .catch(() => {
        setHasMore(false)
      })
  }

  const getNewsAndMedia = async () => {
    await apiAdapterCoffeeWeb
      .getNewsAndMediaById(firebaseLatestNewsId)
      .then((response) => {
        const newObject = {
          isRead: false,
          sourceId: firebaseLatestNewsId,
          notificationMessage: response.data?.returnLst[0]?.subject,
          timeOnly: 'Just Now'
        }

        let found = false

        for (let i = 0; i < userNotificationForUser?.length; i++) {
          if (userNotificationForUser[i].sourceId === firebaseLatestNewsId) {
            found = true
          }
        }

        if (!found) {
          setUserNotificationForUser([newObject, ...userNotificationForUser])

          setShowTostify(true)

          setTimeout(() => {
            setShowTostify(false)
          }, 4000)
        }

        setNotificationMessageDetail({
          ...notificationMessageDetail,
          message: response.data?.returnLst[0]?.subject,
          notificationId: firebaseLatestNewsId
        })
      })
      .catch(() => {
        setHasMore(false)
      })
  }

  const markNewsAsRead = async () => {
    setLoading(true)
    const updateData = {
      userId: userDetails.id,
      newsId: []
    }

    try {
      const { data } = await apiAdapterCoffeeWeb.updateNotificationReadAll(updateData)

      if (data) {
        const updatedNews = userNotificationForUser.map((ele) => ({
          ...ele,
          isRead: true
        }))

        setUserNotificationForUser(updatedNews)
        setUnreadNotificationCount(0)
      }
    } catch (error) {
      console.log(error.response)
    } finally {
      setLoading(false)
    }
  }

  const handleOpenNotification = (item) => {
    const { isRead, sourceId } = item

    if (isRead === false) {
      if (notificationCount >= 0) {
        setNotificationCount(notificationCount - 1)
      }
    }
    const updatedCards = userNotificationForUser.map((ele) => {
      if (ele.sourceId === sourceId) {
        return {
          ...ele,
          isRead: true
        }
      }

      return ele
    })

    setUserNotificationForUser(updatedCards)
    overlayPanelRef.current.hide()

    params.set('newsId', sourceId)

    history.push({
      search: params.toString(),
      state: { pageRef: 'yes' },
      pathname: ROUTE_STRINGS.opennewsnotification
    })
  }

  const handleClick = (event) => {
    overlayPanelRef.current.toggle(event)
    setIsIconClicked(true)
  }

  const openConfirmationModal = () => {
    showConfirmation({
      title: t('ARE_YOU_SURE'),
      message: t('CONFORMATION_NOTIFICATION'),
      buttonLabel: t('YES'),
      dismissButtonLabel: t('NO'),
      onConfirm: () => {
        markNewsAsRead()
      }
    })
  }

  return (
    <div>
      <div>
        <div>
          {showTostify && (
            <div className="toast_div">
              <div className="toast_sub_div">
                <div className="toast_container">{ReactHtmlParser(notificationMessageDetail?.message)}</div>
              </div>
            </div>
          )}
        </div>
        <div className="notification_icon_box">
          <i className="pi pi-bell p-overlay-badge notification-icon" onClick={handleClick}>
            {notificationCount !== 0 && <Badge value={notificationCount} className="notification_badge" severity="danger"></Badge>}
          </i>
        </div>

        <OverlayPanel
          ref={overlayPanelRef}
          id="overlay-panel-id"
          onHide={() => {
            setIsIconClicked(false)
          }}
          className={`overlay-panel-content ${isLoading ? 'is-loading' : ''}`}
        >
          <div>
            <div className="popup_card_for_notification" id="scrollableDiv">
              <div className="notification-sub-div">
                <div>
                  <div className="notification-text">{t('NOTIFICATIONS')}</div>
                </div>
                <div>
                  {unreadNotificationCount > 0 && (
                    <div className="notification-mark-read" onClick={openConfirmationModal}>
                      {t('MARK_ALL_AS_READ')}
                    </div>
                  )}
                </div>
              </div>
              {userNotificationForUser.length && (
                <div className="see-more-notification">
                  <div
                    className="see-all-notification-text"
                    onClick={() => {
                      history.push(ROUTE_STRINGS.notification)
                      overlayPanelRef.current.hide()
                    }}
                  >
                    {t('SEE_ALL_NOTIFICATIONS')}
                  </div>
                </div>
              )}

              <InfiniteScroll dataLength={userNotificationForUser.length} next={() => getUsersNotificationForUser(onlyPageNumber, onlyPageSize)} hasMore={hasMore} scrollableTarget="scrollableDiv" loader={<div className="infinite_scroll_div">Loading...</div>} className="infinite_scroll">
                {userNotificationForUser &&
                  userNotificationForUser?.map((ele, index) => (
                    <div key={index} className="notification-menu" onClick={() => handleOpenNotification(ele)} id={ele.isRead === false && 'unRead'}>
                      <div className="notification-list">
                        <div>
                          <div className="notification-html-news-list">{ReactHtmlParser(ele.notificationMessage)}</div>
                        </div>
                        <div className="notification-news-date">{ele.timeOnly}</div>
                      </div>
                    </div>
                  ))}
              </InfiniteScroll>
              {userNotificationForUser?.length === 0 && <div className="no-notification">{t('NO_NOTIFICATIONS')}</div>}
            </div>{' '}
          </div>
        </OverlayPanel>
      </div>
    </div>
  )
}

export default ErrorMessageModal(NotificationListenService)
