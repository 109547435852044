import EN_LANGUAGES from './en'
import KA_LANGUAGES from './ka'
import HI_LANGUAGES from './hi'

const language = {
  en: {
    translation: EN_LANGUAGES
  },
  ka: {
    translation: KA_LANGUAGES
  },
  hi: {
    translation: HI_LANGUAGES
  }
}

export default language
