import './styles.scss'
import React, { useRef, useState } from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { useTranslation } from 'react-i18next'
import { DoubleArrowIcon, popupIcon } from 'Assets/Icons'

const AccordianForMobile = ({ data, GDItem, handleShowAccordionPopup }) => {
  const { t } = useTranslation()
  const { globalDifferentialData, qualityGroupName, terminalLevel } = data[0]

  const handleSelectRow = (idCountry, qualityName, countryName, qualityCode) => {
    handleShowAccordionPopup({ idCountry, qualityName, countryName, qualityCode })
  }

  const currencyStyle = (value) => {
    if (!value) return {}

    const convertedToNumber = parseFloat(value.replace(/[$¢]/g, ''))

    return { color: convertedToNumber >= 0 ? 'green' : 'red' }
  }

  const headerTemplate = (qualityName, rangeStart, rangeEnd) => {
    const rangeStartMatch = rangeStart.match(/(-?)([^0-9]*)([0-9]+)/)
    const [, rangeStartsign, rangeStartCurrencySymbol, rangeStartValue] = rangeStartMatch
    const rangeEndMatch = rangeEnd.match(/(-?)([^0-9]*)([0-9]+)/)
    const [, rangeEndsign, rangeEndCurrencySymbol, rangeEndValue] = rangeEndMatch

    return (
      <div className="accordian_sub_heading">
        <div className="tr_sub_heading">
          <div className="first_row">{qualityName}</div>
          <div className="differential-column">
            <td className="range-starts" style={currencyStyle(rangeStart)} colSpan={3}>
              <div className="currency-and-value">
                <div className={`${rangeStartCurrencySymbol === '¢' ? 'symbol cent-symbol' : 'symbol'}`}>
                  {rangeStartsign}
                  {rangeStartCurrencySymbol}
                </div>
                <div className="value">{rangeStartValue || '-'}</div>
              </div>
            </td>
          </div>
          <div className="between-column">
            <td className="range-ends" style={currencyStyle(rangeEnd)} colSpan={3}>
              <div className="currency-and-value">
                <div className={`${rangeEndCurrencySymbol === '¢' ? 'symbol cent-symbol' : 'symbol'}`}>
                  {rangeEndsign}
                  {rangeEndCurrencySymbol}
                </div>
                <div className="value">{rangeEndValue || '-'}</div>
              </div>
            </td>
          </div>
          <div>
            <DoubleArrowIcon />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="global-differential-mobile-view">
      <div className="accordian-container">
        <div className="accordian_main_heading">
          <div>{qualityGroupName}</div>
          <div className="tr_heading">{t('DIFFERENTIALS')}</div>
          <div className="tr_heading">{t('BETWEEN')}</div>
          <div></div>
        </div>
        <Accordion>
          {globalDifferentialData?.length > 0 &&
            globalDifferentialData?.map(({ qualityName, rangeStart, rangeEnd, qualityCode, terminal, remark, idCountry }, index) => (
              <AccordionTab key={index} headerTemplate={headerTemplate(qualityName, rangeStart, rangeEnd)}>
                <div className="accordian_main_body">
                  <div className="accodian_sub_div">
                    <div>
                      <div className="each-data">
                        <div className="row-heading">{t('SCREEN')} :</div>
                        <div>{qualityCode}</div>
                      </div>
                      <div className="each-data">
                        <div className="row-heading"> {t('TERMINAL')} :&nbsp;</div>
                        <div>{terminal}</div>
                      </div>
                      <div className="each-data">
                        <div className="row-heading"> {t('TERMINAL_LEVEL')} :&nbsp;</div>
                        <div>{terminalLevel}</div>
                      </div>
                      <div className="each-data">
                        <div className="row-heading"> {t('DELIVERY_PORT')} :</div>
                        <div>{remark}</div>
                      </div>
                    </div>
                  </div>
                  <div className="each-data historical-data data_for_date" onClick={() => handleSelectRow(idCountry, qualityName, GDItem?.country, qualityCode)}>
                    {t('HISTORICAL_DATA')} <img className="popup-icon" src={popupIcon} alt="popup-icon" />
                  </div>
                </div>
              </AccordionTab>
            ))}
        </Accordion>
      </div>
    </div>
  )
}

export default AccordianForMobile
