import './styles.scss'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { commonMethods } from 'Utils/commonMethods'
import { coffeeReportJsonLinks } from 'Utils/Constants'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'

const TotalCertifiedStocks = ({ selectedMarket, setSelectedMarket }) => {
  const { t } = useTranslation()
  const { setLoading } = useContext(CoffeeWebContext)
  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''
  const userDetails = getLocalUserDetails()
  const { robustaTotalCertifiedStocks, arabicaTotalCertifiedStocks } = coffeeReportJsonLinks

  const { checkForUserAccess } = commonMethods

  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }

  const [swapReport, setSwapReport] = useState('MT')
  const [fetchedData, setFetchedData] = useState([])

  const fetchData = async () => {
    setLoading(true)
    const reportName = selectedMarket === 'Robusta' ? robustaTotalCertifiedStocks : arabicaTotalCertifiedStocks

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setFetchedData(response.data)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    if (selectedMarket) {
      setSwapReport('MT')
    }
  }, [selectedMarket])

  const toggleSwapOption = () => {
    setSwapReport((prevOption) => {
      if (selectedMarket === 'Robusta') {
        return prevOption === 'MT' ? 'LOTS' : 'MT'
      }
      if (selectedMarket === 'Arabica') {
        return prevOption === 'MT' ? '60 KG BAGS' : 'MT'
      }

      return 'MT'
    })
  }

  const convertMTToBags = (valueInMT) => {
    const result = (valueInMT * 1000) / 60

    return Math.round(result)
  }

  const determineCellClass = (currentValue, previousValue) => {
    if (currentValue > previousValue) {
      return 'positive'
    }
    if (currentValue < previousValue) {
      return 'negative'
    }

    return 'neutral'
  }

  const determineTextColor = (differenceFromPreviousDay) => {
    if (differenceFromPreviousDay === 0) {
      return 'neutral'
    }
    if (differenceFromPreviousDay > 0) {
      return 'positive'
    }

    return 'negative'
  }

  const formatMarketData = (value) => {
    if (selectedMarket === 'Robusta') {
      const formattedRobustaData = swapReport === 'MT' ? value?.toLocaleString('en-US') : (value / 10)?.toLocaleString('en-US')

      return formattedRobustaData
    }

    const formattedArabicaData = swapReport === 'MT' ? Math.round(value)?.toLocaleString('en-US') : convertMTToBags(value)?.toLocaleString('en-US')

    return formattedArabicaData
  }

  const convertValuesInString = (str) => {
    const regex = /\d+/g
    const matches = str?.match(regex)

    if (matches) {
      const convertedMatches = matches.map((value) => convertMTToBags(parseFloat(value)))

      return str.replace(regex, () => convertedMatches.shift())
    }

    return str
  }

  const transformData = (data) => {
    const response = data?.details?.map((detail, detailIndex) => {
      const previousCertifiedStocks = data?.details[detailIndex + 1]?.certifiedStocks || data?.details?.certifiedStocks
      const certifiedStockColor = determineCellClass(detail?.certifiedStocks, previousCertifiedStocks)
      const compareToPreviousDayColor = determineTextColor(detail?.ComparedToPreDay)

      const formattedCertifiedStocks = formatMarketData(detail?.certifiedStocks)
      const formattedComparisonToPreviousDay = formatMarketData(detail?.ComparedToPreDay)

      const result = {
        ...detail,
        certifiedStockColor,
        formattedCertifiedStocks,
        formattedComparisonToPreviousDay,
        compareToPreviousDayColor
      }

      if (selectedMarket === 'Robusta') {
        const formattedNonTenderable = formatMarketData(detail?.nonTenderable)
        const formattedSuspended = formatMarketData(detail?.suspended)

        return {
          ...result,
          formattedNonTenderable,
          formattedSuspended
        }
      }

      let formattedPendingGradingReport = detail?.PendingGradingReport
      let formattedGrading = detail?.grading

      if (selectedMarket === 'Arabica') {
        if (swapReport !== 'MT') {
          formattedPendingGradingReport = convertValuesInString(detail?.PendingGradingReport)
          formattedGrading = convertValuesInString(detail?.grading)
        }

        return {
          ...result,
          formattedPendingGradingReport,
          formattedGrading
        }
      }

      return result
    })

    return response
  }

  return (
    <div className="certified-reports-wrapper">
      <div className="header-container-wrapper">
        <div className="header-container">
          <div className="market-selector" onClick={() => setSelectedMarket(selectedMarket === 'Robusta' ? 'Arabica' : 'Robusta')}>
            {selectedMarket}
            <div className="arrow-toggle">
              <i className="pi pi-angle-up arrow-up" />
              <i className="pi pi-angle-down arrow-down" />
            </div>
          </div>

          <div className="content-title">{t('CERTIFIED_STOCKS_REPORTS')}</div>

          <div className="swap-content-wrapper" onClick={toggleSwapOption}>
            {swapReport}
            <div className="swap-button">
              <i className="pi pi-angle-up upper-arrow" />
              <i className="pi pi-angle-down down-arrow" />
            </div>
          </div>
        </div>
      </div>

      <div className="table-container">
        <table style={checkForUserAccess(menuLock)}>
          <thead>
            <tr>
              <th className="header-report-date">{t('REPORT_DATE')}</th>
              <th className="header-certified-stocks">{t('CERTIFIED_STOCKS')}</th>
              <th className="header-compare-to-previous-day">{t('COMPARE_TO_PRE_DAY')}</th>
              {selectedMarket === 'Robusta' ? (
                <>
                  <th className="non-tenderable">{t('NON_TENDERABLE')}</th>
                  <th className="suspended">{t('SUSPENDED')}</th>
                </>
              ) : (
                <>
                  <th className="header-pending-grading-report">{t('PENDING_GRADING_REPORT')}</th>
                  <th className="daily-grading-report">{t('DAILY_GRADING_SUMMARY')}</th>
                </>
              )}
            </tr>
            <tr className="empty-row"></tr>
          </thead>

          <tbody>
            {selectedMarket === 'Robusta' &&
              transformData(fetchedData)?.map(({ date, compareToPreviousDayColor, certifiedStockColor, formattedCertifiedStocks, formattedComparisonToPreviousDay, formattedNonTenderable, formattedSuspended }, index) => (
                <tr key={index}>
                  <td>{date}</td>
                  <td className={certifiedStockColor}>{formattedCertifiedStocks}</td>
                  <td className={compareToPreviousDayColor}>{formattedComparisonToPreviousDay}</td>
                  <td>{formattedNonTenderable}</td>
                  <td>{formattedSuspended}</td>
                </tr>
              ))}
            {selectedMarket === 'Arabica' &&
              transformData(fetchedData)?.map(({ Date, compareToPreviousDayColor, certifiedStockColor, formattedCertifiedStocks, formattedComparisonToPreviousDay, formattedPendingGradingReport, formattedGrading }, index) => (
                <tr key={index}>
                  <td>{Date}</td>
                  <td className={certifiedStockColor}>{formattedCertifiedStocks}</td>
                  <td className={compareToPreviousDayColor}>{formattedComparisonToPreviousDay}</td>
                  <td>{formattedPendingGradingReport}</td>
                  <td>{formattedGrading}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TotalCertifiedStocks
