import './styles.scss'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import { useTranslation } from 'react-i18next'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { GoogleIcon } from 'Assets/Icons'
import { getLocalShareNewsId, getLocalNewsForSubscription, getLocalUserDetails, getLocalFCMToken, setLocalWithLoggedInStatus } from 'Utils/LocalStorage_Handler'
import { ROUTE_STRINGS, STATIC_ROUTE_STRINGS } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import DropdownComponent from 'Components/DropdownComponent'
import Button from 'Components/UIComponent/Button'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'

const SignUpComponent = ({ handleTabSelection, singleDeviceId, setNewsDataOnClick, addUserInFirebase, showError, authenticateForDashboard, handleApiResponse }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { setLoading } = useContext(CoffeeWebContext)
  const { sentryLog, fetchIPDetails, getUserDetailUsingOAuth } = commonMethods
  const sharedNewsId = history.location.state?.encryptedId || getLocalShareNewsId()
  const newsForSubscription = history.location.state?.newsForSubscription || getLocalNewsForSubscription()
  const newsId = history.location.state?.newsId
  const forGuestUser = history.location.state?.forGuestUser

  const userDetails = getLocalUserDetails()
  const firebaseFcmToken = getLocalFCMToken()

  let displayNameForChat = ''
  let emailForChat = ''
  let userChatId = ''

  const [userGoogleToken, setUserGoogleToken] = useState('')
  const [countriesList, setCountriesList] = useState([])
  const [initialCountry, setInitialCountry] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [dropdownError, setDropdownError] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showGooglePopup, setShowGooglePopup] = useState(false)

  if (userDetails) {
    setLocalWithLoggedInStatus(true)
    if (userDetails?.userLandingPageUrl) {
      history.push(`${ROUTE_STRINGS.dashboard}/${userDetails?.userLandingPageUrl}`)
    } else {
      history.push(ROUTE_STRINGS.coffeequotes)
    }
  }

  useEffect(() => {
    init()
    setNewsDataOnClick((previousState) => ({ ...previousState, encryptedId: sharedNewsId, newsForSubscription, newsId, forGuestUser }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (showGooglePopup) {
      signUpWithGoogle()
    }
  }, [showGooglePopup])

  const init = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getAllCountries()

      if (response.data.length) {
        setCountriesList(response.data)

        const ipLocationResponse = await fetchIPDetails()
        const countryCode = ipLocationResponse?.countryCode

        const initialCountryFound = response.data.find((ele) => `+${ele?.phonecode}` === countryCode)

        setInitialCountry(initialCountryFound)
        setSelectedCountry(initialCountryFound)
      } else {
        setIsError(true)
      }
    } catch {
      setIsError(true)
    }
  }

  const validateAndSubmitDetails = () => {
    setShowGooglePopup(true)
    setTimeout(() => setShowGooglePopup(false), 0)
  }

  const signUpWithGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      const googleToken = response.access_token

      setUserGoogleToken(googleToken)
      try {
        const userDetails = await getUserDetailUsingOAuth(googleToken)

        if (userDetails?.email) {
          checkForEmail(userDetails?.email, googleToken)
        }
      } catch (error) {
        console.log(error)
      }
    }
  })

  const checkForEmail = async (email, googleToken) => {
    setLoading(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.isEmailExist({ email, id: 0 })

      if (data) {
        showError({
          title: t('EMAIL_EXIST'),
          message: t('SIGNING_EMAIL_EXIST'),
          buttonLabel: t('CONTINUE'),
          onConfirm: () => {
            authenticateForDashboard(googleToken, email)
          }
        })
      } else {
        setIsModalVisible(true)
      }
    } catch {
      handleApiResponse({ status: null, message: t('SORRY_UNABLE_TO_LOGIN'), onRetry: () => authenticateForDashboard() })
    } finally {
      setLoading(false)
    }
  }

  const addUserInDatabase = async (googleToken, countryId, phoneCode) => {
    setIsModalVisible(false)
    setLoading(true)
    const data = {
      userType: 1,
      firstName: 'NA',
      lastName: '',
      email: 'NA',
      phone: 'NA',
      occupationId: 0,
      occupationName: 'NA',
      countryId,
      password: 'NA',
      createdBy: 0,
      includeMenu: true,
      countryPhoneCode: phoneCode,
      idtimezone: 0,
      idState: 0,
      showMobileNo: true,
      showEmailId: true,
      isAllowedToConnect: true,
      userRole: 0,
      registerSource: 2,
      deviceId: singleDeviceId,
      fbToken: firebaseFcmToken || 'NA',
      registerDevice: 'Browser',
      googleTocken: googleToken
    }

    try {
      const response = await apiAdapterCoffeeWeb.createUser(data)
      const {
        data: { firstName, email, userFbregistrationId, isError }
      } = response

      if (!isError) {
        displayNameForChat = firstName
        emailForChat = email
        userChatId = userFbregistrationId
        addUserInFirebase(googleToken, email, userChatId, displayNameForChat, emailForChat)
      } else {
        showError({
          title: t('EMAIL_EXIST'),
          message: t('SIGNING_EMAIL_EXIST'),
          buttonLabel: t('CONTINUE'),
          onConfirm: () => {
            authenticateForDashboard(googleToken, email)
          }
        })
        setLoading(false)
      }
    } catch (error) {
      sentryLog({ userId: 'NA', screenName: 'signup', description: error?.response?.data?.title || error?.response?.status, errorIn: 'createUser API' })
      showError({
        title: t('ERROR_TITLE'),
        message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p>`,
        buttonLabel: t('OK')
      })
      setLoading(false)
    }
  }

  const createNewUserAccount = () => {
    setDropdownError((prevState) => ({
      ...prevState,
      noDataFound: false
    }))
    if (!selectedCountry) {
      setDropdownError((prevState) => ({
        ...prevState,
        countryIsRequired: true
      }))
    } else {
      addUserInDatabase(userGoogleToken, selectedCountry.id, selectedCountry.phonecode)
    }
  }

  const closeModal = () => {
    setDropdownError((prevState) => ({
      ...prevState,
      noDataFound: false,
      countryIsRequired: false
    }))
    setIsModalVisible(false)
    setSelectedCountry(initialCountry)
  }

  const handleDropdownSelection = (event) => {
    if (event && event.id && event.phonecode) {
      setDropdownError((prevState) => ({
        ...prevState,
        countryIsRequired: false,
        noDataFound: false
      }))
      setSelectedCountry(event)
    }
  }

  const dialogBodyContent = () => (
    <div className="country-picker-dropdown">
      <DropdownComponent handleSelectCountry={handleDropdownSelection} isAuthScreen={true} countryList={countriesList} currentItem={selectedCountry || initialCountry} setCurrentItem={setSelectedCountry} errorState={isError} width="100%" height="40px" />
      {dropdownError.noDataFound && <div className="country-dropdown-error">{t('NO_DATA_FOUND')}</div>}
      {dropdownError.countryIsRequired && <div className="country-dropdown-error">{t('COUNTRY_IS_REQUIRED')}</div>}
    </div>
  )

  const dialogFooterContent = () => (
    <div className="sign-up-modal-footer-content">
      <Button buttonType={'CLOSE'} onButtonClick={() => closeModal()} />
      <Button buttonType={'CONTINUE'} onButtonClick={createNewUserAccount} />
    </div>
  )

  return (
    <>
      <div className="sign-up-tab-component">
        <div className="sign-up-form-container">
          <div className="sign-up-and-terms-button-wrapper">
            <div className="sign-up-button">
              <Button buttonType={'CUSTOM'} buttonLabel={t('SIGN_UP_WITH_GOOGLE')} variant={'grey-soft-action'} buttonSize={'small'} buttonWidth={'full'} buttonIcon={<GoogleIcon />} onButtonClick={() => validateAndSubmitDetails()} />
            </div>
            <div className="sign-up-terms-and-condition">
              {t('BY_SIGNING_UP_YOU_AGREE_TO_OUR')}
              <a href={STATIC_ROUTE_STRINGS.termsAndCondition} target="_blank" rel="noreferrer">
                {t('TERMS_CONDITIONS')}
              </a>
              {t('AND')}
              <a href={STATIC_ROUTE_STRINGS.privacyPolicy} target="_blank" rel="noreferrer">
                {t('PRIVACY_POLICY')}
              </a>
            </div>
          </div>
          <div className="redirect-to-login-container">
            <span lang="en-us">{t('ALREADY_HAVE_AN_ACCOUNT')}</span>
            <Button buttonType={'CUSTOM'} buttonLabel={t('LOGIN_HERE')} buttonSize={'x-small'} onButtonClick={() => handleTabSelection('login')} className="button-wrapper" />
          </div>
        </div>

        <DialogUIModal showModal={isModalVisible} title={t('SELECT_COUNTRY')} bodyContent={dialogBodyContent} handleClose={closeModal} className="country-picker-modal" footerContent={dialogFooterContent} onHide={closeModal} hideCloseIcon={true} signUpModal={true} />
      </div>
    </>
  )
}

export default ApiResponseHandler(ErrorMessageModal(SignUpComponent))
