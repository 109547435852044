import React, { useCallback, useContext, useEffect, useState } from 'react'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Carousel } from 'primereact/carousel'
import { useErrorBoundary } from 'react-error-boundary'
import { updateUserDetails, getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import { ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import SubscriptionCard from './Components/SubscriptionCardDetails'

const Subscription = ({ showError }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = new URLSearchParams()
  const { showBoundary } = useErrorBoundary()
  const { setLoading } = useContext(CoffeeWebContext)
  let startX = 0
  let endX = 0
  let isDragging = false

  const [subscriptionUsers, setSubscriptionUsers] = useState([])
  const [modifiedSubscriptionUsers, setModifiedSubscriptionUsers] = useState([])
  const userDetails = getLocalUserDetails()
  const { subType } = userDetails.sub
  const [highlightIndex, setHighlightIndex] = useState(null)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth) // eslint-disable-line no-unused-vars

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightIndex])

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  useEffect(() => {
    getAllUsers()
    updateUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (subscriptionUsers.length > 0) {
      getSlideCurrentIndex()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionUsers])

  const getAllUsers = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getSubscriptionFeatureWithCountry({ countryId: userDetails?.countryId, isTestPayment: userDetails?.isTestPayment })
      const modifiedData = response.data.returnLst.map((ele) => {
        const locationBasedSubscriptionId = location.state?.subscriptionId

        return {
          ...ele,
          isHighlight: ele.id === locationBasedSubscriptionId
        }
      })

      setSubscriptionUsers(modifiedData)
      setModifiedSubscriptionUsers([{}, ...modifiedData, {}])
    } catch (err) {
      showError({
        title: t('ERROR_TITLE'),
        message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p>`,
        buttonLabel: t('OK')
      })
    } finally {
      setLoading(false)
    }
  }

  const updateUser = async () => {
    const response = await updateUserDetails()

    if (response?.status === 401) {
      showBoundary('unAuthorized')
    }
  }

  const location = useLocation()

  const getSlideCurrentIndex = () => {
    const findIndex = subscriptionUsers.findIndex(({ id }) => subType === id)
    const locationBasedSubscriptionId = location.state?.subscriptionId

    if (locationBasedSubscriptionId) {
      setHighlightIndex(locationBasedSubscriptionId - 1)
    } else if (findIndex === subscriptionUsers.length - 1) {
      setHighlightIndex(findIndex)
    } else {
      setHighlightIndex(findIndex === -1 ? subscriptionUsers.length : findIndex + 1)
    }
  }

  const selectPlan = (selectedSubType) => {
    params.set('id', selectedSubType.id)
    history.push({
      pathname: ROUTE_STRINGS.paymentgateway,
      state: selectedSubType,
      search: params.toString()
    })
  }

  const responsiveOptions = [
    {
      breakpoint: '1200px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ]

  const subscriptionTemplate = (element, index) => <div key={index}>{Object.keys(element).length > 0 ? <SubscriptionCard subscription={element} selectPlan={selectPlan} /> : <div />}</div>

  const handleMouseDown = (e) => {
    startX = e.clientX
    isDragging = true
  }

  const handleMouseMove = (e) => {
    if (!isDragging) return
    endX = e.clientX
    handleDrag()
  }

  const handleMouseUp = () => {
    isDragging = false
    startX = 0
    endX = 0
  }

  const handleDrag = () => {
    if (!isDragging) return

    const difference = startX - endX

    if (Math.abs(difference) > 50) {
      if (difference > 0 && highlightIndex < subscriptionUsers.length - 1) {
        setHighlightIndex(highlightIndex + 1)
      } else if (difference < 0 && highlightIndex > 0) {
        setHighlightIndex(highlightIndex - 1)
      }
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowLeft' && highlightIndex > 0) {
      setHighlightIndex(highlightIndex - 1)
    } else if (e.key === 'ArrowRight' && highlightIndex < subscriptionUsers.length - 1) {
      setHighlightIndex(highlightIndex + 1)
    }
  }

  return (
    <div className="subscription-main-container" data-testId="subscription-main-container">
      <Carousel onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} value={window.innerWidth > 1198 ? modifiedSubscriptionUsers : subscriptionUsers} numScroll={1} numVisible={3} page={highlightIndex} responsiveOptions={responsiveOptions} itemTemplate={subscriptionTemplate} data-testId="carousel-container" />
    </div>
  )
}

export default ErrorMessageModal(Subscription)
