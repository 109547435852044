import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { ROUTE_STRINGS } from './Constants'

export const coffeewebSetLocal = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}
export const coffeewebGetLocal = (key) => {
  const value = localStorage.getItem(key)

  return JSON.parse(value)
}
export const coffeewebClearLocal = () => {
  localStorage.clear()
}

export const updateUserDetails = async () => {
  const userDetails = getLocalUserDetails()

  try {
    const response = await apiAdapterCoffeeWeb.getUserByUserId(userDetails?.id)

    setLocalWithUserDetails(response.data)

    return response
  } catch (err) {
    return err.response
  }
}

export const redirectToHome = (history) => {
  history.push({
    pathname: ROUTE_STRINGS.login
  })
}

export const coffeewebStorageKeys = {
  authToken: 'authToken',
  userDetails: 'userDetails',
  appSettings: 'appSettings',
  coffeeQuotesSubFeatures: 'coffeeQuotesSubFeatures',
  payment: 'payment',
  coffeeNewsScreen: 'coffeeNewsScreen',
  checkForUserLoggedIn: 'checkForUserLoggedIn',
  backToNotificationScreen: 'backToNotificationScreen',
  newsMediaForFirebaseEvent: 'newsMediaForFirebaseEvent',
  sharednewsid: 'sharedNewsId',
  notificationSouceId: 'notificationSourceId',
  previewpdf: 'previewPdf',
  singleSignOn: 'singleSignOn',
  languageId: 'languageId',
  languageIdForNews: 'languageIdForNews',
  isLoggedIn: 'isLoggedIn',
  paypalDetails: 'paypalDetails',
  latestNewsReadNotification: 'latestNewsReadNotification',
  appLanguage: 'appLanguage',
  singleDeviceId: 'singleDeviceId',
  freeNewsLanguageId: 'freeNewsLanguageId',
  newsForSubscription: 'newsForSubscription',
  subscriptionNewsId: 'subscriptionNewsId',
  freeMenusToDisplay: 'freeMenusToDisplay',
  firebaseFcmToken: 'firebaseFcmToken',
  currentLocation: 'currentLocation',
  clockTab: 'clockTab',
  selectedClockDisplay: 'selectedClockDisplay',
  selectedTimeFormat: 'selectedTimeFormat'
}

const getLocal = {
  getLocalAppSettings() {
    return coffeewebGetLocal(coffeewebStorageKeys?.appSettings)
  },
  getLocalAppLanguage() {
    return coffeewebGetLocal(coffeewebStorageKeys?.appLanguage)
  },
  getLocalSingleDeviceId() {
    return coffeewebGetLocal(coffeewebStorageKeys?.singleDeviceId)
  },
  getLocalFreeMenus() {
    return coffeewebGetLocal(coffeewebStorageKeys?.freeMenusToDisplay)
  },
  getLocalFCMToken() {
    return coffeewebGetLocal(coffeewebStorageKeys?.firebaseFcmToken)
  },
  getLocalUserDetails() {
    return coffeewebGetLocal(coffeewebStorageKeys?.userDetails)
  },
  getLocalLoggedInStatus() {
    return coffeewebGetLocal(coffeewebStorageKeys?.isLoggedIn)
  },
  getLocalFreeNewsLanguageId() {
    return coffeewebGetLocal(coffeewebStorageKeys?.freeNewsLanguageId)
  },
  getLocalLatestNewsReadNotification() {
    return coffeewebGetLocal(coffeewebStorageKeys.latestNewsReadNotification)
  },
  getLocalAuthToken() {
    return coffeewebGetLocal(coffeewebStorageKeys.authToken)
  },
  getLocalShareNewsId() {
    return coffeewebGetLocal(coffeewebStorageKeys.sharednewsid)
  },
  getLocalNewsForSubscription() {
    return coffeewebGetLocal(coffeewebStorageKeys.newsForSubscription)
  },
  getLocalLanguageIdForNews() {
    return coffeewebGetLocal(coffeewebStorageKeys.languageIdForNews)
  },
  getLocalSubscriptionNewsId() {
    return coffeewebGetLocal(coffeewebStorageKeys.subscriptionNewsId)
  },
  getStripeDataFromLocal() {
    return coffeewebGetLocal(coffeewebStorageKeys.payment)
  },
  getPayPalDataFromLocal() {
    return coffeewebGetLocal(coffeewebStorageKeys.paypalDetails)
  },
  getLocalCurrentLocation() {
    return coffeewebGetLocal(coffeewebStorageKeys.currentLocation)
  },
  getLocalClockTab() {
    return coffeewebGetLocal(coffeewebStorageKeys.clockTab)
  },
  getLocalClockDisplay() {
    return coffeewebGetLocal(coffeewebStorageKeys.selectedClockDisplay)
  },
  getLocalTimeFormat() {
    return coffeewebGetLocal(coffeewebStorageKeys.selectedTimeFormat)
  }
}

const setLocal = {
  setLocalWithSingleDeviceId(deviceId) {
    coffeewebSetLocal(coffeewebStorageKeys.singleDeviceId, deviceId)
  },
  setLocalWithAppSettings(appSetting) {
    coffeewebSetLocal(coffeewebStorageKeys.appSettings, appSetting)
  },
  setLocalWithFreeNewsLanguageId(languageId) {
    coffeewebSetLocal(coffeewebStorageKeys.freeNewsLanguageId, languageId)
  },
  setLocalWithCurrentLocation(location) {
    coffeewebSetLocal(coffeewebStorageKeys.currentLocation, location)
  },
  setLocalWithAppLanguage(languageCode) {
    coffeewebSetLocal(coffeewebStorageKeys.appLanguage, languageCode)
  },
  setLocalWithFreeMenus(freeMenus) {
    coffeewebSetLocal(coffeewebStorageKeys.freeMenusToDisplay, freeMenus)
  },
  setLocalWithUserDetails(detail) {
    coffeewebSetLocal(coffeewebStorageKeys.userDetails, detail)
  },
  setLocalWithAuthToken(token) {
    coffeewebSetLocal(coffeewebStorageKeys.authToken, token)
  },
  setLocalWithLanguageIdForNews(languageId) {
    coffeewebSetLocal(coffeewebStorageKeys.languageIdForNews, languageId)
  },
  setLocalWithLoggedInStatus(loggedInStatus) {
    coffeewebSetLocal(coffeewebStorageKeys.isLoggedIn, loggedInStatus)
  },
  setLocalWithSubscriptionNewsId(newsId) {
    coffeewebSetLocal(coffeewebStorageKeys.subscriptionNewsId, newsId)
  },
  setLocalWithLatestNewsReadNotification(newsId) {
    coffeewebSetLocal(coffeewebStorageKeys.latestNewsReadNotification, newsId)
  },
  setLocalWithIsNewsScreen(isNewsScreen) {
    coffeewebSetLocal(coffeewebStorageKeys.coffeeNewsScreen, isNewsScreen)
  },
  setLocalWithSharedNewsId(newsId) {
    coffeewebSetLocal(coffeewebStorageKeys.sharednewsid, newsId)
  },
  setLocalWithNewsForSubscription(subId) {
    coffeewebSetLocal(coffeewebStorageKeys.newsForSubscription, subId)
  },
  setLocalWithPayPalData(paymentDetails) {
    coffeewebSetLocal(coffeewebStorageKeys.paypalDetails, paymentDetails)
  },
  setLocalWithStripeData(paymentDetails) {
    coffeewebSetLocal(coffeewebStorageKeys.payment, paymentDetails)
  },
  setLocalWithFirebaseFCMToken(token) {
    coffeewebSetLocal(coffeewebStorageKeys.firebaseFcmToken, token)
  },
  setLocalWithClockTab(selectedClockTab) {
    coffeewebSetLocal(coffeewebStorageKeys.clockTab, selectedClockTab)
  },
  setLocalWithClockType(selectedClockType) {
    coffeewebSetLocal(coffeewebStorageKeys.selectedClockDisplay, selectedClockType)
  },
  setLocalWithTimeFormat(clockTimeFormat) {
    coffeewebSetLocal(coffeewebStorageKeys.selectedTimeFormat, clockTimeFormat)
  }
}

export const { getLocalAppSettings, getLocalAppLanguage, getLocalSingleDeviceId, getLocalFreeMenus, getLocalFCMToken, getLocalUserDetails, getLocalLoggedInStatus, getLocalFreeNewsLanguageId, getLocalLatestNewsReadNotification, getLocalAuthToken, getLocalShareNewsId, getLocalNewsForSubscription, getLocalLanguageIdForNews, getLocalSubscriptionNewsId, getStripeDataFromLocal, getPayPalDataFromLocal, getLocalCurrentLocation, getLocalClockTab, getLocalClockDisplay, getLocalTimeFormat } = getLocal
export const { setLocalWithSingleDeviceId, setLocalWithAppSettings, setLocalWithFirebaseFCMToken, setLocalWithStripeData, setLocalWithPayPalData, setLocalWithIsNewsScreen, setLocalWithLatestNewsReadNotification, setLocalWithSubscriptionNewsId, setLocalWithLanguageIdForNews, setLocalWithAuthToken, setLocalWithUserDetails, setLocalWithFreeMenus, setLocalWithAppLanguage, setLocalWithCurrentLocation, setLocalWithFreeNewsLanguageId, setLocalWithLoggedInStatus, setLocalWithSharedNewsId, setLocalWithNewsForSubscription, setLocalWithClockTab, setLocalWithClockType, setLocalWithTimeFormat } = setLocal
