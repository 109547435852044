import './styles.scss'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import Validation from 'Utils/Validation'
import Button from 'Components/UIComponent/Button'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

const ForgotPasswordModal = ({ handleCloseModal, generatedToken, showSuccess, forgotPasswordModal }) => {
  const { t } = useTranslation()
  const { setLoading } = useContext(CoffeeWebContext)
  const [emailErrorType, setEmailErrorType] = useState('')
  const [emailValue, setEmailValue] = useState('')

  const handleChangeEmail = ({ value }) => {
    setEmailValue(value)
    if (value === '') {
      setEmailErrorType('EMAIL_IS_REQUIRED')
    } else if (!Validation.emailValidation(value)) {
      setEmailErrorType('INVALID_EMAIL_FORMAT')
    } else {
      setEmailErrorType('')
    }
  }

  const handleClickSendResetLink = () => {
    if (!emailValue) {
      setEmailErrorType('EMAIL_IS_REQUIRED')
    } else if (!emailErrorType) {
      checkForEmail()
    }
  }

  const checkForEmail = async () => {
    setLoading(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.isEmailExist({ email: emailValue, id: 0 })

      if (data) {
        sendResetLink()
      } else {
        setEmailErrorType('EMAIL_NOT_REGISTERED')
        setLoading(false)
      }
    } catch {
      setLoading(false)
    }
  }

  const sendResetLink = async () => {
    try {
      const { data } = await apiAdapterCoffeeWeb.sendForgotPasswordLink({ email: emailValue }, generatedToken)

      if (data) {
        handleCloseModal()
        setEmailValue('')
        setTimeout(() => {
          showSuccess({
            title: t('SUCCESS'),
            message: `${t('RESET_PASSWORD_LINK_HAS_BEEN_SENT_TO')} ${emailValue}`,
            buttonLabel: t('RETURN_TO_LOGIN')
          })
        }, 300)
      }
    } catch (error) {
      console.error(error)
      setEmailErrorType('FAILED_TO_SEND_RESET_PASSWORD_LINK')
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    handleCloseModal()
    setEmailErrorType('')
    setEmailValue('')
  }

  const forgotPasswordModalBody = () => (
    <div className="forgot-password-modal">
      <div className="modal-body-style">
        <div className="before-success-wrapper">
          <div className="desc">{t('ENTER_YOUR_EMAIL_ADDRESS_TO_RECEIVE_A_PASSWORD_RESET_LINK')}</div>
          <div>
            <input type="text" name="Email" value={emailValue} onChange={(e) => handleChangeEmail(e.target)} className="forgot-password-input pass_flow" placeholder={t('ENTER_YOUR_EMAIL_ADDRESS')} />
            <div className="error-message">{t(emailErrorType)}</div>
          </div>
        </div>
      </div>
    </div>
  )

  const forgotPasswordModalFooterContent = (
    <>
      <Button buttonType={'CLOSE'} onButtonClick={handleClose} />
      <Button buttonType={'CUSTOM'} buttonLabel={t('SEND_RESET_LINK')} onButtonClick={() => handleClickSendResetLink()} />
    </>
  )

  return <DialogUIModal title={t('FORGOT_YOUR_PASSWORD')} showModal={forgotPasswordModal} handleClose={handleClose} bodyContent={forgotPasswordModalBody} footerContent={forgotPasswordModalFooterContent} />
}

export default ErrorMessageModal(ForgotPasswordModal)
