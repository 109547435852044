import './styles.scss'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AppleIcon, GooglePlay, CoffeeBeanIcon } from 'Assets/Icons'
import AnalogClock from 'Components/Clock'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

const AboutUs = () => {
  const { t } = useTranslation()
  const { isLoading, setLoading } = useContext(CoffeeWebContext)

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const aboutCoffeeWeb = [
    {
      title: t('COFFEE'),
      subTitle: t('ABOUT_COFFEE'),
      subList: [{ subPoint: t('COUNTRIES_ABOUT_COFFEE') }, { subPoint: t('GLOBAL_MARKET') }]
    },
    {
      title: t('COFFEEWEB'),
      subTitle: t('ABOUT_GLOBAL_COFFEE_INDUSTRY'),
      secondSubTitle: t('ABOUT_COFFEE_SOURCE'),
      subList: [{ subPoint: t('ABOUT_COFFEE_PREMIER_SOURCE') }, { subPoint: t('ABOUT_COFFEE_WEB_SITE_FEATURE') }, { subPoint: t('ABOUT_COFFEE_WEB_SITE_INFORMATION') }]
    },
    {
      title: t('BENEFICIAL_FOR_ALL_COFFEE_INDUSTRY_STAKEHOLDERS'),
      subList: [{ subPoint: t('BENEFICIAL_FOR_ALL_COFFEE') }, { subPoint: t('OUR_COMPREHENSIVE_PLATFORM_PROVIDES') }]
    }
  ]

  return (
    <div className="about-us-container" data-testId="about-us">
      <AnalogClock setLoading={setLoading} isLoading={isLoading} />
      <div className="coffee-web-about-drop-clock"></div>
      <div className="coffee-web-about-container">
        <div className="coffee-web-about-mobile-view">
          <div className="coffee-web-about-map-heading">
            <span>{t('COFFEE_MAP')}</span>
          </div>
          <div className="world-map-image">
            <img src="https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_menu_icons/coffee_map.png" alt="world-map" />
          </div>
        </div>
        <div className="about-coffee-industry" data-testId="about-coffee-industry-summary">
          {aboutCoffeeWeb.map(({ title, subTitle, secondSubTitle, subList }, index) => (
            <div key={index} className="about-us-title">
              <div className="title">{title}</div>
              <div className="sub-title">{subTitle}</div>
              {secondSubTitle && <div className="secondSubTitle">{secondSubTitle}</div>}
              <div className="sub-points">
                {subList.map(({ subPoint }, ind) => (
                  <div className="each-point" key={ind}>
                    <img src={CoffeeBeanIcon} className="coffee-bean-icon" alt="coffee-bean" />
                    <span>{subPoint}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="coffee-web-about-drop"></div>
        <div className="coffee-web-about-map-wrapper">
          <div style={{ position: 'sticky', top: '110px' }}>
            <div className="coffee-web-about-map-heading">
              <div>{t('COFFEE_MAP')}</div>
            </div>
            <div className="world-map-image">
              <img src="https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_menu_icons/coffee_map.png" alt="world-map" />
            </div>
            {window.location.pathname !== '/about-us/mobile' && (
              <div className="coffee-web-app-links">
                <div className="apple-link" data-testId="apple-link">
                  <a href="https://apps.apple.com/in/app/coffeeweb/id1618878403" target="_blank" rel="noreferrer">
                    <AppleIcon fill={'#fff'} title="AppleIcon" />
                    <p>
                      {t('DOWNLOAD_ON_THE')}
                      <br /> <span className="big-txt">{t('APP_STORE')}</span>
                    </p>
                  </a>
                </div>
                <div className="play-store-link" data-testId="playStore-link">
                  <a href="https://play.google.com/store/apps/details?id=com.coffeeweb.app" target="_blank" rel="noreferrer">
                    <img alt="play-store" src={GooglePlay} />
                    <p>
                      {t('GET_IT_ON')} <br /> <span className="big-txt">{t('GOOGLE_PLAY')}</span>
                    </p>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
