import './styles.scss'
import { PageNotFoundSvg } from 'Assets/Icons'

function PageNotFound() {
  return (
    <div className="pageNotFound">
      <div className="mainDiv">
        <div>
          <img alt="page not found" src={PageNotFoundSvg} />
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
