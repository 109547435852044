import './style.scss'
import React, { memo } from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { useTranslation } from 'react-i18next'
import { commonMethods } from 'Utils/commonMethods'
import { DoubleArrowIcon } from 'Assets/Icons'

/* eslint-disable react/display-name */
const AccordianForMobile = memo((props) => {
  const { data, labeling, idMarket } = props
  const { t } = useTranslation()
  const { getOptionExpiryColor, getFirstNoticeDayColor, getFontColorWithBold } = commonMethods

  const handleTotalwithDecimal = (arg1) => Math.abs(parseFloat(arg1 || 0)).toFixed(2)

  const headerTemplate = (item) => (
    <div className="coffeequotes_accordian_header" data-testid="accordion-header">
      <div className="accordian_sub_heading" data-testid="accordion-sub-header">
        <div className="tr_sub_heading">
          <div className="first_row" data-testid="contract-name">
            {item?.contractName}
          </div>
          <div className={`each_row_heading_name ${getFontColorWithBold(item?.chng)}`} data-testid="last-change">
            {item?.idMarket === 1 ? item?.lastChng : parseFloat(item?.lastChng).toFixed(2)}
          </div>
          <div className={`each_row_heading_name ${getFontColorWithBold(item?.chng)}`}>
            <div className={`sign-value-wrapper${item?.idMarket === 1 ? '-robusta' : ''}`} data-testid="sign-value-wrapper">
              <div>{item.chng >= 0 ? '+' : '-'}</div>
              <div>{item.idMarket === 1 ? Math.abs(item?.chng).toString().padStart(2, '0') : Math.abs(parseFloat(item?.chng)).toFixed(2)}</div>
            </div>
          </div>
          <div className="each_row_heading_name" data-testid="row-heading-name">
            <div className="sign-value-wrapper">
              <div>{item.percentageVal >= 0 ? '+' : '-'}</div>
              <div>{Math.abs(parseFloat(item?.percentageVal)).toFixed(2)}</div>
            </div>
          </div>
          <div data-testid="double-arrow-icon">
            <DoubleArrowIcon />
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="coffeequtes-accordian_for_mobile" data-testid="accordion-for-mobile">
      <div className="accordian_main_heading" data-testid="accordion-main-heading">
        <div className="contract-name-heading">
          <img className="country-flag-icon" src={`https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/${idMarket === 1 ? 'GBR' : 'USA'}.svg`} alt="LondonFlag" data-testid="flag-image" />
          {labeling}
        </div>
        <div className="tr_heading">{t('LAST')}</div>
        <div className="tr_heading">{t('CHG')}</div>
        <div className="tr_heading" data-testid="percentage-symbol">
          %
        </div>
      </div>
      <Accordion>
        {data &&
          data?.map((item, index) => {
            const numberOfDecimals = item.idMarket === 1 ? 0 : 2

            return (
              <AccordionTab headerTemplate={headerTemplate(item)} key={index} id={item?.isHighlightRow}>
                <div className="accordian_main_body" data-testid="accordion-main-body">
                  <div className="accodian_sub_div">
                    <div>
                      <div className="each_data">
                        <div>{t('HIGH')}</div>
                        <div>: {item.highRate.toFixed(numberOfDecimals)}</div>
                      </div>

                      <div className="each_data">
                        <div>{t('LOW')}</div>
                        <div>: {item.lowRate.toFixed(numberOfDecimals)}</div>
                      </div>
                      <div className="each_data">
                        <div>{t('VOL')}</div>
                        <div>: {item.volume}</div>
                      </div>
                      <div className="each_data">
                        <div>{t('OPEN')}</div>
                        <div>: {item.openRate.toFixed(numberOfDecimals)}</div>
                      </div>
                      <div className="each_data">
                        <div>{t('BID')}</div>
                        <div>: {item.bid.toFixed(numberOfDecimals)}</div>
                      </div>
                      <div className="each_data">
                        <div>{t('ASK')}</div>
                        <div>: {item.ask.toFixed(numberOfDecimals)}</div>
                      </div>
                    </div>
                    <div>
                      <div className="each_data">
                        <div>{t('NET_HIGH')}</div>
                        <div className="net-move-wrapper">
                          :
                          <div className={`${getFontColorWithBold(item?.netHigh)} sign-value-wrapper`}>
                            <div>{item.netHigh >= 0 ? '+' : '-'}</div>
                            <div>{item?.idMarket === 1 ? Math.abs(item?.netHigh).toString().padStart(2, '0') : handleTotalwithDecimal(item?.netHigh)}</div>
                          </div>
                        </div>
                      </div>
                      <div className="each_data">
                        <div>{t('NET_LOW')}</div>
                        <div className="net-move-wrapper">
                          :
                          <div className={`${getFontColorWithBold(item?.netLow)} sign-value-wrapper`}>
                            <div>{item.netLow >= 0 ? '+' : '-'}</div>
                            <div>{item?.idMarket === 1 ? Math.abs(item?.netLow).toString().padStart(2, '0') : handleTotalwithDecimal(item?.netLow)}</div>
                          </div>
                        </div>
                      </div>
                      <div className="each_data">
                        <div>{t('OPEN_INT')}</div>
                        <div>: {item.openInterest}</div>
                      </div>
                      <div className="each_data">
                        <div>{t('PRE_CLOSE')}</div>
                        <div>: {item.prevRate.toFixed(numberOfDecimals)}</div>
                      </div>
                      <div className="each_data">
                        <div>{t('BSIZE')}</div>
                        <div>: {item.bsize}</div>
                      </div>
                      <div className="each_data">
                        <div>{t('ASIZE')}</div>
                        <div>: {item.asize}</div>
                      </div>
                    </div>
                  </div>
                  <div className="data_for_date">
                    <div>{t('OPTION_EXPIRY')} :</div>
                    <div className={getOptionExpiryColor(item?.optionExpiryStatus)}>{item?.optionExpiryDateFormat}</div>
                  </div>
                  <div className="data_for_date">
                    <div>{t('FIRST_NOTICE_DAY')} :</div>
                    <div className={getFirstNoticeDayColor(item?.firstNoticeDateStatus)}>{item?.firstNoticeDateFormat}</div>
                  </div>
                </div>
              </AccordionTab>
            )
          })}
      </Accordion>
    </div>
  )
})

export default AccordianForMobile
