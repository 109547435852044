import { Route } from 'react-router'
import React from 'react'

const GuestRoute = (props) => {
  const { component: Component, ...rest } = props

  return (
    <div>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </div>
  )
}

export default GuestRoute
