// Stripe.js
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { getLocalUserDetails, setLocalWithStripeData } from 'Utils/LocalStorage_Handler'

function stripe({ setLoading, showError }) {
  const userDetails = getLocalUserDetails()

  const stripeCreateOrder = async (ele) => {
    const postData = {
      order: {
        userId: userDetails.id,
        billingAddress: 'NA',
        shippingAddress: 'NA',
        states: userDetails.states,
        city: userDetails.city,
        totalQuantity: 1,
        totalAmount: ele.basePrice,
        paymentDate: '2022-12-24T04:51:11.228Z',
        orderDate: '2022-12-24T04:51:11.228Z',
        payOrderId: 'NA',
        subscriptionId: ele.name,
        facilitatorAccessToken: 'NA',
        paymentId: ele.paymentId,
        paymentSource: ele.paymentName,
        paymentStatus: 'Pending',
        orderSatus: 1
      },
      orderDetails: [
        {
          idOrderDetail: 0,
          orderId: 0,
          planId: 'NA',
          serviceName: 'NA',
          subscriptionType: ele.paymentName,
          quantity: 0,
          rate: 0
        }
      ]
    }

    await apiAdapterCoffeeWeb
      .createOrder(postData)
      .then((response) => {
        if (response.data.isSuccess === true) {
          InitiateStripePayment(ele, response.data.returnLst)
        }
      })
      .catch(() => {
        showError({
          title: 'Error !',
          message: `<p>${'An unknown error has been occurred while processing your request.'}</p><p>${'Please try again in sometime.'}</p>`,
          buttonLabel: 'OK'
        })
      })
  }

  const InitiateStripePayment = async (ele, oderData) => {
    setLoading(true)
    const postData = {
      amount_total: userDetails.countryId === 102 ? `${parseInt(ele.csgstTaxableAmount, 10)}` : `${parseInt(ele.amountInDollars, 10)}`,
      product_data: ele.name,
      quantity: '1',
      currency: userDetails.countryId === 102 ? 'INR' : 'USD'
    }

    await apiAdapterCoffeeWeb
      .createStripePayment(postData)
      .then((response) => {
        if (response.data.statusCode === 201) {
          window.open(`${response.data?.returnLst?.url}`, '_parent')

          const paymentDetails = {
            orderId: oderData.orderDetails[0].orderId,
            paymentId: response.data?.returnLst.id,
            paymentIntent: response.data?.returnLst.payment_intent,
            amount: ele.basePrice,
            subName: ele.name,
            paymentSource: ele.paymentName
          }

          setLocalWithStripeData(paymentDetails)
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return {
    stripeCreateOrder
  }
}

export default stripe
