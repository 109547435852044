import './styles.scss'
import React, { useState, useEffect } from 'react'
import { Chart } from 'primereact/chart'
import { useTranslation } from 'react-i18next'
import { currencyDummyData } from 'Utils/coffeeQuotesDummyData'
import CreateAccountInfoModal from 'Components/UIComponent/CreateAccountInfoModal'
import { commonMethods } from 'Utils/commonMethods'

function Forex() {
  const { t } = useTranslation()
  const { getButtonAndInfo } = commonMethods
  const { buttonText, infoMessage, className, navigationPath } = getButtonAndInfo()

  const [chartData, setChartData] = useState({})
  const [chartOptions, setChartOptions] = useState({})

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement)
    const textColor = documentStyle.getPropertyValue('--text-color')
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border')
    const data = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: 'Forex Data',
          data: [65, 59, 80, 81, 56, 59, 40, 53, 42, 53, 48, 56, 46],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--pink-500'),
          tension: 0.4
        }
      ]
    }
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        }
      }
    }

    setChartData(data)
    setChartOptions(options)
  }, [])

  return (
    <div>
      <div className="forex-component-wrapper" data-testid="forex-component-wrapper">
        <div className="currency-contaner" data-testid="currency-container">
          <table data-testid="forex-component-table">
            <tbody>
              {currencyDummyData.map(({ countryName, iso3, lastChng, percentageVal, value }, index) => (
                <tr className="each-currency" key={index} data-testid="currency-table-row">
                  <td data-testid="country-name">
                    <div className="country-type" data-testid="country-type">
                      <img src={`https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/${iso3}.svg`} alt="Flag" data-testid="flag-image" />
                      {countryName}
                    </div>
                  </td>
                  <td data-testid="last-change">{lastChng}</td>
                  <td data-testid="percentage-value">{percentageVal}</td>
                  <td data-testid="value">{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="graph-contaner" data-testid="graph-container">
          <Chart type="line" data={chartData} options={chartOptions} />
        </div>
      </div>
    </div>
  )
}

export default Forex
