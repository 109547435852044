export const coffeeQuotesDefaultValue = Array.from({ length: 5 }, () => ({
  isHighlight: 0,
  id: 0,
  createdBy: 0,
  createdOn: '2023-08-07T04:13:55.454Z',
  updatedBy: 0,
  updatedDtms: '2023-08-07T04:13:55.454Z',
  idMarket: 0,
  contractName: '--- --',
  lastChng: 0,
  chng: 0,
  percentageVal: 0,
  volume: 0,
  highRate: 0,
  netHigh: 0,
  highRateCurrency: 0,
  lowRate: 0,
  netLow: 0,
  lowRateCurrency: 0,
  openRate: 0,
  prevRate: 0,
  openInterest: 0,
  bid: 0,
  bsize: 0,
  ask: 0,
  asize: 0,
  optionExpiry: 'DD-MMM-YYYY',
  optionExpiryStatus: null,
  optionExpiryDateFormat: 'DD-MMM-YYYY',
  firstNoticeDate: 'DD-MMM-YYYY',
  firstNoticeDateStatus: null,
  firstNoticeDateFormat: 'DD-MMM-YYYY',
  highCurrency: 0,
  lowCurrency: 0,
  marketName: '0',
  userSymbolId: 0,
  orderBy: 0
}))

export const robustaDummyData = [
  {
    id: 166978926,
    idMarket: 1,
    contractName: 'RCK-24 (MAY 24)',
    lastChng: 3886,
    fxNet: 3886,
    chng: 43,
    contractId: 5,
    percentageVal: 1.12,
    volume: 1123,
    highRate: 3924,
    highRateCurrency: 0,
    lowRate: 3823,
    lowRateCurrency: 0,
    openRate: 3823,
    prevRate: 3843,
    openInterest: 31495,
    bid: 3886,
    bsize: 6,
    ask: 3891,
    asize: 5,
    optionExpiry: '2024-04-17T06:01:53',
    firstNoticeDate: '2024-04-25T06:01:53.857',
    highCurrency: 0,
    lowCurrency: 0,
    marketName: 'RCK-24 (MAY 24)',
    userSymbolId: 0,
    orderBy: 0,
    terminalId: '6acae6b3-a63a-4831-8cbc-298b132d5381',
    firstNoticeDateStatus: 'boldAndBlack',
    optionExpiryStatus: 'boldAndRed',
    netHigh: 81,
    contractHighRate: 0,
    contractLowRate: 0,
    contractHighDate: '2024-04-11T00:00:00',
    contractLowDate: '2023-10-10T00:00:00',
    netLow: -20,
    isHighlight: 0,
    createdBy: 0,
    createdOn: '0001-01-01T00:00:00',
    updatedBy: 0,
    updatedDms: '0001-01-01T00:00:00',
    _52weeksHighDate: '2024-04-11T00:00:00',
    _52weeksHighRate: 3906,
    _52weeksLowRate: 2169,
    _52weeksLowDate: '2023-10-10T00:00:00',
    _52weeksHighDateFormat: '11-APR-2024',
    _52weeksLowDateFormat: '10-OCT-2023',
    optionExpiryDateFormat: '17-APR-2024',
    firstNoticeDateFormat: '25-APR-2024',
    ContractHighDateFormat: '11-APR-2024',
    ContractLowDateFormat: '10-OCT-2023'
  },

  {
    id: 166978927,
    idMarket: 1,
    contractName: 'RCN-24 (JUL 24)',
    lastChng: 3837,
    fxNet: 3837,
    chng: 47,
    contractId: 10,
    percentageVal: 1.24,
    volume: 2588,
    highRate: 3868,
    highRateCurrency: 0,
    lowRate: 3780,
    lowRateCurrency: 0,
    openRate: 3780,
    prevRate: 3790,
    openInterest: 51425,
    bid: 3834,
    bsize: 4,
    ask: 3838,
    asize: 2,
    optionExpiry: '2024-06-19T12:19:33',
    firstNoticeDate: '2024-06-25T12:19:33',
    highCurrency: 0,
    lowCurrency: 0,
    marketName: 'RCN-24 (JUL 24)',
    userSymbolId: 0,
    orderBy: 0,
    terminalId: '0ba25c32-415c-427b-8070-fa463adb4453',
    firstNoticeDateStatus: '',
    optionExpiryStatus: '',
    netHigh: 78,
    contractHighRate: 0,
    contractLowRate: 0,
    contractHighDate: '2024-04-11T00:00:00',
    contractLowDate: '2023-10-11T00:00:00',
    netLow: -10,
    isHighlight: 0,
    createdBy: 0,
    createdOn: '0001-01-01T00:00:00',
    updatedBy: 0,
    updatedDms: '0001-01-01T00:00:00',
    _52weeksHighDate: '2024-04-11T00:00:00',
    _52weeksHighRate: 3851,
    _52weeksLowRate: 2163,
    _52weeksLowDate: '2023-10-11T00:00:00',
    _52weeksHighDateFormat: '11-APR-2024',
    _52weeksLowDateFormat: '11-OCT-2023',
    optionExpiryDateFormat: '19-JUN-2024',
    firstNoticeDateFormat: '25-JUN-2024',
    ContractHighDateFormat: '11-APR-2024',
    ContractLowDateFormat: '11-OCT-2023'
  },
  {
    id: 166978928,
    idMarket: 1,
    contractName: 'RCU-24 (SEP 24)',
    lastChng: 3747,
    fxNet: 3747,
    chng: 45,
    contractId: 19,
    percentageVal: 1.22,
    volume: 498,
    highRate: 3775,
    highRateCurrency: 0,
    lowRate: 3692,
    lowRateCurrency: 0,
    openRate: 3692,
    prevRate: 3702,
    openInterest: 13881,
    bid: 3744,
    bsize: 1,
    ask: 3748,
    asize: 4,
    optionExpiry: '2024-08-21T00:00:00',
    firstNoticeDate: '2024-08-27T00:00:00',
    highCurrency: 0,
    lowCurrency: 0,
    marketName: 'RCU-24 (SEP 24)',
    userSymbolId: 0,
    orderBy: 0,
    terminalId: '89ee81a5-a680-4207-ad25-6547d2ac9339',
    firstNoticeDateStatus: '',
    optionExpiryStatus: '',
    netHigh: 73,
    contractHighRate: 0,
    contractLowRate: 0,
    contractHighDate: '2024-04-11T00:00:00',
    contractLowDate: '2023-10-11T00:00:00',
    netLow: -10,
    isHighlight: 0,
    createdBy: 0,
    createdOn: '0001-01-01T00:00:00',
    updatedBy: 0,
    updatedDms: '0001-01-01T00:00:00',
    _52weeksHighDate: '2024-04-11T00:00:00',
    _52weeksHighRate: 3756,
    _52weeksLowRate: 2146,
    _52weeksLowDate: '2023-10-11T00:00:00',
    _52weeksHighDateFormat: '11-APR-2024',
    _52weeksLowDateFormat: '11-OCT-2023',
    optionExpiryDateFormat: '21-AUG-2024',
    firstNoticeDateFormat: '27-AUG-2024',
    ContractHighDateFormat: '11-APR-2024',
    ContractLowDateFormat: '11-OCT-2023'
  },
  {
    id: 166978929,
    idMarket: 1,
    contractName: 'RCX-24 (NOV 24)',
    lastChng: 3644,
    fxNet: 3644,
    chng: 39,
    contractId: 10027,
    percentageVal: 1.08,
    volume: 152,
    highRate: 3670,
    highRateCurrency: 0,
    lowRate: 3592,
    lowRateCurrency: 0,
    openRate: 3592,
    prevRate: 3605,
    openInterest: 3388,
    bid: 3641,
    bsize: 1,
    ask: 3647,
    asize: 4,
    optionExpiry: '2024-10-16T00:00:00',
    firstNoticeDate: '2024-10-28T00:00:00',
    highCurrency: 0,
    lowCurrency: 0,
    marketName: 'RCX-24 (NOV 24)',
    userSymbolId: 0,
    orderBy: 0,
    terminalId: 'a6225921-0804-4dc3-a15a-4967659abbda',
    firstNoticeDateStatus: '',
    optionExpiryStatus: '',
    netHigh: 65,
    contractHighRate: 0,
    contractLowRate: 0,
    contractHighDate: '2024-04-11T00:00:00',
    contractLowDate: '2023-10-11T00:00:00',
    netLow: -13,
    isHighlight: 0,
    createdBy: 0,
    createdOn: '0001-01-01T00:00:00',
    updatedBy: 0,
    updatedDms: '0001-01-01T00:00:00',
    _52weeksHighDate: '2024-04-11T00:00:00',
    _52weeksHighRate: 3655,
    _52weeksLowRate: 2137,
    _52weeksLowDate: '2023-10-11T00:00:00',
    _52weeksHighDateFormat: '11-APR-2024',
    _52weeksLowDateFormat: '11-OCT-2023',
    optionExpiryDateFormat: '16-OCT-2024',
    firstNoticeDateFormat: '28-OCT-2024',
    ContractHighDateFormat: '11-APR-2024',
    ContractLowDateFormat: '11-OCT-2023'
  },
  {
    id: 166978930,
    idMarket: 1,
    contractName: 'RCF-25 (JAN 25)',
    lastChng: 3549,
    fxNet: 3549,
    chng: 41,
    contractId: 10028,
    percentageVal: 1.17,
    volume: 29,
    highRate: 3563,
    highRateCurrency: 0,
    lowRate: 3509,
    lowRateCurrency: 0,
    openRate: 3509,
    prevRate: 3508,
    openInterest: 1369,
    bid: 3540,
    bsize: 1,
    ask: 3548,
    asize: 2,
    optionExpiry: '2024-12-18T00:00:00',
    firstNoticeDate: '2024-12-24T00:00:00',
    highCurrency: 0,
    lowCurrency: 0,
    marketName: 'RCF-25 (JAN 25)',
    userSymbolId: 0,
    orderBy: 0,
    terminalId: 'f0538d6e-a937-4820-a8a6-934e4dde5724',
    firstNoticeDateStatus: '',
    optionExpiryStatus: '',
    netHigh: 55,
    contractHighRate: 0,
    contractLowRate: 0,
    contractHighDate: '2024-04-11T00:00:00',
    contractLowDate: '2023-10-11T00:00:00',
    netLow: 1,
    isHighlight: 0,
    createdBy: 0,
    createdOn: '0001-01-01T00:00:00',
    updatedBy: 0,
    updatedDms: '0001-01-01T00:00:00',
    _52weeksHighDate: '2024-04-11T00:00:00',
    _52weeksHighRate: 3520,
    _52weeksLowRate: 2122,
    _52weeksLowDate: '2023-10-11T00:00:00',
    _52weeksHighDateFormat: '11-APR-2024',
    _52weeksLowDateFormat: '11-OCT-2023',
    optionExpiryDateFormat: '18-DEC-2024',
    firstNoticeDateFormat: '24-DEC-2024',
    ContractHighDateFormat: '11-APR-2024',
    ContractLowDateFormat: '11-OCT-2023'
  }
]
export const arabicaDummyData = [
  {
    id: 166978931,
    idMarket: 2,
    contractName: 'KCK-24 (MAY 24)',
    lastChng: 229.55,
    fxNet: 229.55,
    chng: 9.2,
    contractId: 9,
    percentageVal: 4.18,
    volume: 6157,
    highRate: 232.5,
    highRateCurrency: 0,
    lowRate: 220.95,
    lowRateCurrency: 0,
    openRate: 220.95,
    prevRate: 220.35,
    openInterest: 41718,
    bid: 229.45,
    bsize: 1,
    ask: 229.65,
    asize: 2,
    optionExpiry: '2024-04-12T06:01:53',
    firstNoticeDate: '2024-04-22T06:01:53',
    highCurrency: 0,
    lowCurrency: 0,
    marketName: 'KCK-24 (MAY 24)',
    userSymbolId: 0,
    orderBy: 0,
    terminalId: 'c06c6ac6-70e7-4aa5-80b9-622ae8c6f8ae',
    firstNoticeDateStatus: 'boldAndRed',
    optionExpiryStatus: 'boldAndRed',
    netHigh: 12.15,
    contractHighRate: 0,
    contractLowRate: 0,
    contractHighDate: '2024-04-11T00:00:00',
    contractLowDate: '2023-10-10T00:00:00',
    netLow: 0.6,
    isHighlight: 0,
    createdBy: 0,
    createdOn: '0001-01-01T00:00:00',
    updatedBy: 0,
    updatedDms: '0001-01-01T00:00:00',
    _52weeksHighDate: '2024-04-11T00:00:00',
    _52weeksHighRate: 221.8,
    _52weeksLowRate: 145.9,
    _52weeksLowDate: '2023-10-10T00:00:00',
    _52weeksHighDateFormat: '11-APR-2024',
    _52weeksLowDateFormat: '10-OCT-2023',
    optionExpiryDateFormat: '12-APR-2024',
    firstNoticeDateFormat: '22-APR-2024',
    ContractHighDateFormat: '11-APR-2024',
    ContractLowDateFormat: '10-OCT-2023'
  },
  {
    id: 166978932,
    idMarket: 2,
    contractName: 'KCN-24 (JUL 24)',
    lastChng: 225.3,
    fxNet: 225.3,
    chng: 7.95,
    contractId: 12,
    percentageVal: 3.66,
    volume: 9604,
    highRate: 227.25,
    highRateCurrency: 0,
    lowRate: 218.35,
    lowRateCurrency: 0,
    openRate: 218.35,
    prevRate: 217.35,
    openInterest: 112113,
    bid: 225.2,
    bsize: 1,
    ask: 225.35,
    asize: 9,
    optionExpiry: '2024-06-12T12:31:47',
    firstNoticeDate: '2024-06-20T12:31:47',
    highCurrency: 0,
    lowCurrency: 0,
    marketName: 'KCN-24 (JUL 24)',
    userSymbolId: 0,
    orderBy: 0,
    terminalId: '7ad5bad2-da36-4842-b706-287c3020642e',
    firstNoticeDateStatus: '',
    optionExpiryStatus: '',
    netHigh: 9.9,
    contractHighRate: 0,
    contractLowRate: 0,
    contractHighDate: '2024-04-11T00:00:00',
    contractLowDate: '2023-10-10T00:00:00',
    netLow: 1,
    isHighlight: 0,
    createdBy: 0,
    createdOn: '0001-01-01T00:00:00',
    updatedBy: 0,
    updatedDms: '0001-01-01T00:00:00',
    _52weeksHighDate: '2024-04-11T00:00:00',
    _52weeksHighRate: 218.9,
    _52weeksLowRate: 146.65,
    _52weeksLowDate: '2023-10-10T00:00:00',
    _52weeksHighDateFormat: '11-APR-2024',
    _52weeksLowDateFormat: '10-OCT-2023',
    optionExpiryDateFormat: '12-JUN-2024',
    firstNoticeDateFormat: '20-JUN-2024',
    ContractHighDateFormat: '11-APR-2024',
    ContractLowDateFormat: '10-OCT-2023'
  },
  {
    id: 166978933,
    idMarket: 2,
    contractName: 'KCU-24 (SEP 24)',
    lastChng: 223.6,
    fxNet: 223.6,
    chng: 7.5,
    contractId: 15,
    percentageVal: 3.47,
    volume: 1850,
    highRate: 225.25,
    highRateCurrency: 0,
    lowRate: 216.6,
    lowRateCurrency: 0,
    openRate: 216.6,
    prevRate: 216.1,
    openInterest: 44488,
    bid: 223.35,
    bsize: 1,
    ask: 223.55,
    asize: 12,
    optionExpiry: '2024-08-09T11:28:45',
    firstNoticeDate: '2024-08-22T11:28:45',
    highCurrency: 0,
    lowCurrency: 0,
    marketName: 'KCU-24 (SEP 24)',
    userSymbolId: 0,
    orderBy: 0,
    terminalId: '2bf4396a-c63b-425d-b772-27c9691ada1f',
    firstNoticeDateStatus: '',
    optionExpiryStatus: '',
    netHigh: 9.15,
    contractHighRate: 0,
    contractLowRate: 0,
    contractHighDate: '2024-04-11T00:00:00',
    contractLowDate: '2023-10-10T00:00:00',
    netLow: 0.5,
    isHighlight: 0,
    createdBy: 0,
    createdOn: '0001-01-01T00:00:00',
    updatedBy: 0,
    updatedDms: '0001-01-01T00:00:00',
    _52weeksHighDate: '2024-04-11T00:00:00',
    _52weeksHighRate: 217.45,
    _52weeksLowRate: 147.5,
    _52weeksLowDate: '2023-10-10T00:00:00',
    _52weeksHighDateFormat: '11-APR-2024',
    _52weeksLowDateFormat: '10-OCT-2023',
    optionExpiryDateFormat: '09-AUG-2024',
    firstNoticeDateFormat: '22-AUG-2024',
    ContractHighDateFormat: '11-APR-2024',
    ContractLowDateFormat: '10-OCT-2023'
  },
  {
    id: 166978934,
    idMarket: 2,
    contractName: 'KCZ-24 (DEC 24)',
    lastChng: 221.85,
    fxNet: 221.85,
    chng: 7,
    contractId: 10026,
    percentageVal: 3.26,
    volume: 1237,
    highRate: 223.25,
    highRateCurrency: 0,
    lowRate: 215,
    lowRateCurrency: 0,
    openRate: 215,
    prevRate: 214.85,
    openInterest: 29480,
    bid: 221.6,
    bsize: 1,
    ask: 221.8,
    asize: 10,
    optionExpiry: '2024-11-08T00:00:00',
    firstNoticeDate: '2024-11-20T00:00:00',
    highCurrency: 0,
    lowCurrency: 0,
    marketName: 'KCZ-24 (DEC 24)',
    userSymbolId: 0,
    orderBy: 0,
    terminalId: 'e83f088d-1d96-4ff8-b4d0-0eb35dcac720',
    firstNoticeDateStatus: '',
    optionExpiryStatus: '',
    netHigh: 8.4,
    contractHighRate: 0,
    contractLowRate: 0,
    contractHighDate: '2024-04-11T00:00:00',
    contractLowDate: '2023-10-10T00:00:00',
    netLow: 0.15,
    isHighlight: 0,
    createdBy: 0,
    createdOn: '0001-01-01T00:00:00',
    updatedBy: 0,
    updatedDms: '0001-01-01T00:00:00',
    _52weeksHighDate: '2024-04-11T00:00:00',
    _52weeksHighRate: 216.05,
    _52weeksLowRate: 148.7,
    _52weeksLowDate: '2023-10-10T00:00:00',
    _52weeksHighDateFormat: '11-APR-2024',
    _52weeksLowDateFormat: '10-OCT-2023',
    optionExpiryDateFormat: '08-NOV-2024',
    firstNoticeDateFormat: '20-NOV-2024',
    ContractHighDateFormat: '11-APR-2024',
    ContractLowDateFormat: '10-OCT-2023'
  },
  {
    id: 166978935,
    idMarket: 2,
    contractName: 'KCH-25 (MAR 25)',
    lastChng: 222.1,
    fxNet: 222.1,
    chng: 7.6,
    contractId: 10029,
    percentageVal: 3.54,
    volume: 394,
    highRate: 222.1,
    highRateCurrency: 0,
    lowRate: 213.95,
    lowRateCurrency: 0,
    openRate: 213.95,
    prevRate: 214.5,
    openInterest: 9813,
    bid: 220.9,
    bsize: 1,
    ask: 221.15,
    asize: 10,
    optionExpiry: '2025-02-12T00:00:00',
    firstNoticeDate: '2025-02-20T00:00:00',
    highCurrency: 0,
    lowCurrency: 0,
    marketName: 'KCH-25 (MAR 25)',
    userSymbolId: 0,
    orderBy: 0,
    terminalId: 'ad6789b9-b75c-43ac-8df7-14047fd376a2',
    firstNoticeDateStatus: '',
    optionExpiryStatus: '',
    netHigh: 7.6,
    contractHighRate: 0,
    contractLowRate: 0,
    contractHighDate: '2024-04-11T00:00:00',
    contractLowDate: '2023-10-10T00:00:00',
    netLow: -0.55,
    isHighlight: 0,
    createdBy: 0,
    createdOn: '0001-01-01T00:00:00',
    updatedBy: 0,
    updatedDms: '0001-01-01T00:00:00',
    _52weeksHighDate: '2024-04-11T00:00:00',
    _52weeksHighRate: 215.5,
    _52weeksLowRate: 150.25,
    _52weeksLowDate: '2023-10-10T00:00:00',
    _52weeksHighDateFormat: '11-APR-2024',
    _52weeksLowDateFormat: '10-OCT-2023',
    optionExpiryDateFormat: '12-FEB-2025',
    firstNoticeDateFormat: '20-FEB-2025',
    ContractHighDateFormat: '11-APR-2024',
    ContractLowDateFormat: '10-OCT-2023'
  }
]

export const arbitrageDummyData = Array.from({ length: 5 }, () => ({
  robustaTerminal: '--',
  arabicaTerminal: '--',
  robustaValue: '--',
  robustaChng: '--',
  arabicaValue: '--',
  arabicaChng: '--',
  differenceValue: '--'
}))

export const dummyTerminalDifferenceHistoryData = Array.from({ length: 15 }, () => ({
  terminal1: '--',
  date: '--',
  dateFormat: '--',
  terminal2: '--',
  terminal1Value: '--',
  terminal2Value: '--',
  differenceValue: '--'
}))

export const dummyArbitrageHistoryData = Array.from({ length: 15 }, () => ({
  robustaTerminal: '--',
  robustaValue: '--',
  arabicaTerminal: '--',
  arabicaValue: '--',
  convertedValue: '--',
  differenceValue: '--',
  terminalDate: '--',
  terminalDateFormat: '--',
  arabicaContractId: '--',
  robustaContractId: '--'
}))

export const currencyDummyData = [
  {
    countryName: 'India',
    iso3: 'IND',
    lastChng: '12.30',
    percentageVal: '0.5%',
    value: '203'
  },
  {
    iso3: 'AFG',
    countryName: 'Afghanistan',
    lastChng: '12.30',
    percentageVal: '0.5%',
    value: '105'
  },
  {
    countryName: 'India',
    iso3: 'IND',
    lastChng: '12.30',
    percentageVal: '0.5%',
    value: '203'
  },
  {
    iso3: 'ASM',
    countryName: 'American Samoa',
    lastChng: '10.45',
    percentageVal: '0.12%',
    value: '203'
  },
  {
    iso3: 'ALB',
    countryName: 'Albania',
    lastChng: '12.30',
    percentageVal: '0.5%',
    value: '13'
  },
  {
    iso3: 'DZA',
    countryName: 'Algeria',
    lastChng: '12.30',
    percentageVal: '1.23%',
    value: '233'
  },
  {
    iso3: 'ASM',
    countryName: 'American Samoa',
    lastChng: '10.45',
    percentageVal: '0.12%',
    value: '203'
  },
  {
    iso3: 'ALA',
    countryName: 'Åland Islands',
    lastChng: '12.30',
    percentageVal: '0.5%',
    value: '26'
  },
  {
    iso3: 'ASM',
    countryName: 'American Samoa',
    lastChng: '10.45',
    percentageVal: '0.12%',
    value: '203'
  },
  {
    iso3: 'ALA',
    countryName: 'Åland Islands',
    lastChng: '12.30',
    percentageVal: '0.5%',
    value: '26'
  },
  {
    iso3: 'ASM',
    countryName: 'American Samoa',
    lastChng: '10.45',
    percentageVal: '0.12%',
    value: '203'
  },
  {
    iso3: 'ALB',
    countryName: 'Albania',
    lastChng: '12.30',
    percentageVal: '0.5%',
    value: '13'
  },
  {
    iso3: 'ALA',
    countryName: 'Åland Islands',
    lastChng: '12.30',
    percentageVal: '0.5%',
    value: '26'
  }
]
export const robustaWorkbookDummyData = [
  {
    idWorkbook: 42155,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.777',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.777',
    idUser: 4380,
    idMarket: 1,
    contractName: 'RCN-24 (JUL 24)',
    workbookType: 1,
    outRate: 0,
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChg: 3499,
    workbookDifferential: 0,
    workbookTotal: '3499',
    grandTotal: 0,
    convertTo: 0,
    expenseIn: 0,
    fxcurrencyType: 0,
    fxcurrency: 83.32,
    fxpredis: 0,
    fxnet: '83.32',
    grossPrice: '291.54',
    netPrice: '291.54',
    profit: 0,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    isOutRateValueCustom: false,
    marketName: 'Robusta',
    marketworkbookOrderby: 0,
    usersymbolsOrderby: 0,
    chngStr: '0.00',
    lastChng: '3499',
    customLastChg: '3499',
    customNetPrice: '292',
    customWorkbookDifferential: '0',
    customOutRate: '3499',
    chngColorCondition: 0
  },
  {
    idWorkbook: 42156,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.777',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.777',
    idUser: 4380,
    idMarket: 1,
    contractName: 'RCN-24 (JUL 24)',
    workbookType: 1,
    outRate: 0,
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChg: 3499,
    workbookDifferential: 0,
    workbookTotal: '3499',
    grandTotal: 0,
    convertTo: 0,
    expenseIn: 0,
    fxcurrencyType: 0,
    fxcurrency: 83.32,
    fxpredis: 0,
    fxnet: '83.32',
    grossPrice: '291.54',
    netPrice: '291.54',
    profit: 0,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    isOutRateValueCustom: false,
    marketName: 'Robusta',
    marketworkbookOrderby: 1,
    usersymbolsOrderby: 0,
    chngStr: '0.00',
    lastChng: '3499',
    customLastChg: '3499',
    customNetPrice: '292',
    customWorkbookDifferential: '0',
    customOutRate: '3499',
    chngColorCondition: 0
  },
  {
    idWorkbook: 42159,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.777',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.777',
    idUser: 4380,
    idMarket: 1,
    contractName: 'RCU-24 (SEP 24)',
    workbookType: 1,
    outRate: 0,
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChg: 3436,
    workbookDifferential: 0,
    workbookTotal: '3436',
    grandTotal: 0,
    convertTo: 0,
    expenseIn: 0,
    fxcurrencyType: 0,
    fxcurrency: 83.32,
    fxpredis: 0,
    fxnet: '83.32',
    grossPrice: '286.29',
    netPrice: '286.29',
    profit: 0,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    isOutRateValueCustom: false,
    marketName: 'Robusta',
    marketworkbookOrderby: 2,
    usersymbolsOrderby: 0,
    chngStr: '0.00',
    lastChng: '3436',
    customLastChg: '3436',
    customNetPrice: '286',
    customWorkbookDifferential: '0',
    customOutRate: '3436',
    chngColorCondition: 0
  },
  {
    idWorkbook: 42160,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.777',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.777',
    idUser: 4380,
    idMarket: 1,
    contractName: 'RCX-24 (NOV 24)',
    workbookType: 1,
    outRate: 0,
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChg: 3361,
    workbookDifferential: 0,
    workbookTotal: '3361',
    grandTotal: 0,
    convertTo: 0,
    expenseIn: 0,
    fxcurrencyType: 0,
    fxcurrency: 83.32,
    fxpredis: 0,
    fxnet: '83.32',
    grossPrice: '280.04',
    netPrice: '280.04',
    profit: 0,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    isOutRateValueCustom: false,
    marketName: 'Robusta',
    marketworkbookOrderby: 3,
    usersymbolsOrderby: 0,
    chngStr: '0.00',
    lastChng: '3361',
    customLastChg: '3361',
    customNetPrice: '280',
    customWorkbookDifferential: '0',
    customOutRate: '3361',
    chngColorCondition: 0
  },
  {
    idWorkbook: 42160,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.777',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.777',
    idUser: 4380,
    idMarket: 1,
    contractName: 'RCX-24 (NOV 24)',
    workbookType: 1,
    outRate: 0,
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChg: 3361,
    workbookDifferential: 0,
    workbookTotal: '3361',
    grandTotal: 0,
    convertTo: 0,
    expenseIn: 0,
    fxcurrencyType: 0,
    fxcurrency: 83.32,
    fxpredis: 0,
    fxnet: '83.32',
    grossPrice: '280.04',
    netPrice: '280.04',
    profit: 0,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    isOutRateValueCustom: false,
    marketName: 'Robusta',
    marketworkbookOrderby: 3,
    usersymbolsOrderby: 0,
    chngStr: '0.00',
    lastChng: '3361',
    customLastChg: '3361',
    customNetPrice: '280',
    customWorkbookDifferential: '0',
    customOutRate: '3361',
    chngColorCondition: 0
  },
  {
    idWorkbook: 42160,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.777',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.777',
    idUser: 4380,
    idMarket: 1,
    contractName: 'RCX-24 (NOV 24)',
    workbookType: 1,
    outRate: 0,
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChg: 3361,
    workbookDifferential: 0,
    workbookTotal: '3361',
    grandTotal: 0,
    convertTo: 0,
    expenseIn: 0,
    fxcurrencyType: 0,
    fxcurrency: 83.32,
    fxpredis: 0,
    fxnet: '83.32',
    grossPrice: '280.04',
    netPrice: '280.04',
    profit: 0,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    isOutRateValueCustom: false,
    marketName: 'Robusta',
    marketworkbookOrderby: 3,
    usersymbolsOrderby: 0,
    chngStr: '0.00',
    lastChng: '3361',
    customLastChg: '3361',
    customNetPrice: '280',
    customWorkbookDifferential: '0',
    customOutRate: '3361',
    chngColorCondition: 0
  },
  {
    idWorkbook: 42160,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.777',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.777',
    idUser: 4380,
    idMarket: 1,
    contractName: 'RCX-24 (NOV 24)',
    workbookType: 1,
    outRate: 0,
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChg: 3361,
    workbookDifferential: 0,
    workbookTotal: '3361',
    grandTotal: 0,
    convertTo: 0,
    expenseIn: 0,
    fxcurrencyType: 0,
    fxcurrency: 83.32,
    fxpredis: 0,
    fxnet: '83.32',
    grossPrice: '280.04',
    netPrice: '280.04',
    profit: 0,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    isOutRateValueCustom: false,
    marketName: 'Robusta',
    marketworkbookOrderby: 3,
    usersymbolsOrderby: 0,
    chngStr: '0.00',
    lastChng: '3361',
    customLastChg: '3361',
    customNetPrice: '280',
    customWorkbookDifferential: '0',
    customOutRate: '3361',
    chngColorCondition: 0
  }
]

export const arabicaWorkbookDummyData = [
  {
    idWorkbook: 159,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.778Z',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.778Z',
    idUser: 4380,
    idMarket: 2,
    contractName: 'KCU-23 (SEP 23)',
    workbookType: 2,
    outRate: '4199',
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChng: '200.00',
    lastChg: '200.00',
    workbookDifferential: 0,
    workbookTotal: '190.46',
    convertTo: '4199',
    expenseIn: 0,
    grandTotal: 349.98,
    fxcurrencyType: 0,
    fxcurrency: 83.34,
    fxpredis: 0,
    fxnet: 83.34,
    grossPrice: 349.98,
    netPrice: 349.98,
    profit: 0,
    orderBy: 0,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    customWorkbookDifferential: '-9.54',
    isOutRateValueCustom: false
  },
  {
    idWorkbook: 10790,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.778Z',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.778Z',
    idUser: 4380,
    idMarket: 2,
    contractName: 'KCH-24 (MAR 24)',
    workbookType: 2,
    outRate: '4313',
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChng: '195.65',
    lastChg: '195.65',
    workbookDifferential: 0,
    workbookTotal: '195.65',
    convertTo: '4313',
    expenseIn: 0,
    grandTotal: 0,
    fxcurrencyType: 0,
    fxcurrency: 83.34,
    fxpredis: 0,
    fxnet: 83.34,
    grossPrice: 359.45,
    netPrice: 359.45,
    profit: 0,
    orderBy: 1,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    customWorkbookDifferential: '0',
    isOutRateValueCustom: false
  },
  {
    idWorkbook: 10790,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.778Z',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.778Z',
    idUser: 4380,
    idMarket: 2,
    contractName: 'KCH-24 (MAR 24)',
    workbookType: 2,
    outRate: '4313',
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChng: '195.65',
    lastChg: '195.65',
    workbookDifferential: 0,
    workbookTotal: '195.65',
    convertTo: '4313',
    expenseIn: 0,
    grandTotal: 0,
    fxcurrencyType: 0,
    fxcurrency: 83.34,
    fxpredis: 0,
    fxnet: 83.34,
    grossPrice: 359.45,
    netPrice: 359.45,
    profit: 0,
    orderBy: 1,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    customWorkbookDifferential: '0',
    isOutRateValueCustom: false
  },
  {
    idWorkbook: 10790,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.778Z',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.778Z',
    idUser: 4380,
    idMarket: 2,
    contractName: 'KCH-24 (MAR 24)',
    workbookType: 2,
    outRate: '4313',
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChng: '195.65',
    lastChg: '195.65',
    workbookDifferential: 0,
    workbookTotal: '195.65',
    convertTo: '4313',
    expenseIn: 0,
    grandTotal: 0,
    fxcurrencyType: 0,
    fxcurrency: 83.34,
    fxpredis: 0,
    fxnet: 83.34,
    grossPrice: 359.45,
    netPrice: 359.45,
    profit: 0,
    orderBy: 1,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    customWorkbookDifferential: '0',
    isOutRateValueCustom: false
  },
  {
    idWorkbook: 10792,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.778Z',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.778Z',
    idUser: 4380,
    idMarket: 2,
    contractName: 'KCH-24 (MAR 24)',
    workbookType: 2,
    outRate: '4000',
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChng: 195.65,
    lastChg: 195.65,
    workbookDifferential: 0,
    workbookTotal: '181.44',
    convertTo: 4000,
    expenseIn: 0,
    grandTotal: 0,
    fxcurrencyType: 0,
    fxcurrency: 83.34,
    fxpredis: 0,
    fxnet: 83.12,
    grossPrice: 332.48,
    netPrice: 332.48,
    profit: 0,
    orderBy: 2,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    customWorkbookDifferential: '-14.21',
    isOutRateValueCustom: false
  },
  {
    idWorkbook: 10610,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.778Z',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.778Z',
    idUser: 4380,
    idMarket: 2,
    contractName: 'KCZ-23 (DEC 23)',
    workbookType: 2,
    outRate: '4197',
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChng: '210.00',
    lastChg: '210.00',
    workbookDifferential: 0,
    workbookTotal: '190.37',
    convertTo: '4197',
    expenseIn: 0,
    grandTotal: 350.95,
    fxcurrencyType: 0,
    fxcurrency: 83.34,
    fxpredis: 0,
    fxnet: 83.34,
    grossPrice: 349.75,
    netPrice: 350.95,
    profit: 1.2,
    orderBy: 3,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    customWorkbookDifferential: '-19.63',
    isOutRateValueCustom: false
  },
  {
    idWorkbook: 10610,
    createdBy: 0,
    createdOn: '2023-08-23T06:58:59.778Z',
    updatedBy: 0,
    updatedDtms: '2023-08-23T06:58:59.778Z',
    idUser: 4380,
    idMarket: 2,
    contractName: 'KCZ-23 (DEC 23)',
    workbookType: 2,
    outRate: '4197',
    marketNetPrice: 0,
    grade: '',
    chng: 0,
    lastChng: '210.00',
    lastChg: '210.00',
    workbookDifferential: 0,
    workbookTotal: '190.37',
    convertTo: '4197',
    expenseIn: 0,
    grandTotal: 350.95,
    fxcurrencyType: 0,
    fxcurrency: 83.34,
    fxpredis: 0,
    fxnet: 83.34,
    grossPrice: 349.75,
    netPrice: 350.95,
    profit: 1.2,
    orderBy: 3,
    isLastValueCustom: false,
    isNetPriceValueCustom: false,
    customWorkbookDifferential: '-19.63',
    isOutRateValueCustom: false
  }
]
