import './styles.scss'
import React from 'react'
import { AppLoader } from 'Assets/Icons'
import { ROUTE_STRINGS } from 'Utils/Constants'

const LayoutContainer = ({ children, isLoading }) => {
  const pageUrl = window.location.pathname

  const extractURL = (url) => {
    const splitPageUrl = url.split('/')
    const currentUrl = splitPageUrl.pop()

    return currentUrl
  }

  const isMenuAbsent = extractURL(pageUrl) === extractURL(ROUTE_STRINGS.login) || extractURL(pageUrl) === extractURL(ROUTE_STRINGS.createAccount)

  return (
    <div className={`layout-body-content ${isMenuAbsent ? 'menu-absent' : ''}`} id="layout-container-div">
      {isLoading && (
        <div className="layout-loader-wrapper">
          <AppLoader className="layout-loader" />
        </div>
      )}
      {children}
    </div>
  )
}

export default LayoutContainer
