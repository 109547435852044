import './styles.scss'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'primereact/checkbox'
import { getLocalUserDetails, setLocalWithLatestNewsReadNotification } from 'Utils/LocalStorage_Handler'
import { NoData } from 'Assets/Images'
import { ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { db } from 'firebase.js'
import { doc, onSnapshot } from 'firebase/firestore'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

const Notification = (props) => {
  const { t } = useTranslation()
  const params = new URLSearchParams()
  const history = useHistory()
  const { setLoading, isLoading } = useContext(CoffeeWebContext)
  const userDetails = getLocalUserDetails()
  const { mockTesting, mockSelectedNotificationsForRead, mockUnreadNotificationCount, mockShowCheckBox, mockNoNotification, mockShowAllLoadedMessage, mockNotificationLoader } = props
  const [userNotificationForUser, setUserNotificationForUser] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(20) // eslint-disable-line
  const [hasMore, setHasMore] = useState(true)
  const [notificationLoader, setNotificationLoader] = useState(false)
  const [noNotification, setNoNotification] = useState(false)
  const [showAllLoadedMessage, setShowAllLoadedMessage] = useState(false)
  const [showCheckBox, setShowCheckBox] = useState(false)
  const [selectedNotificationsForRead, setSelectedNotificationsForRead] = useState([])
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)
  const [styleForNotificationHeader, setStyleForNotificationHeader] = useState(null)
  const [styleForNotificationContainer, setStyleForNotificationContainer] = useState(null)

  useEffect(() => {
    getNotificationForUser()

    const cleanUp = init()

    if (typeof cleanUp === 'function') {
      return () => {
        cleanUp()
      }
    }

    // Below props are used for testing purposes
    if (mockTesting) {
      setSelectedNotificationsForRead(mockSelectedNotificationsForRead)
      setUserNotificationForUser(mockSelectedNotificationsForRead)
      setUnreadNotificationCount(mockUnreadNotificationCount)
      setShowCheckBox(mockShowCheckBox)
      setNoNotification(mockNoNotification)
      setShowAllLoadedMessage(mockShowAllLoadedMessage)
      setNotificationLoader(mockNotificationLoader)
    }
  }, [])

  useEffect(() => {
    if (showAllLoadedMessage) {
      const body = document.querySelector('body')

      body.style.overflow = 'auto'

      const timeoutId = setTimeout(() => {
        setShowAllLoadedMessage(false)
      }, 5000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [showAllLoadedMessage])

  useEffect(() => {
    const handleResize = () => {
      setStyleForNotificationHeader(getDynamicHeight(90, 150, 'top'))
      setStyleForNotificationContainer(getDynamicHeight(90, 140, 'marginTop'))
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [selectedNotificationsForRead])

  useEffect(() => {
    if (userNotificationForUser?.length > 0 && unreadNotificationCount === 0) {
      setUserNotificationForUser(userNotificationForUser?.map((ele) => ({ ...ele, isRead: true })))
    }
  }, [unreadNotificationCount])

  const init = () => {
    let mounted = false

    if (userDetails?.phone || userDetails?.email) {
      const firebaseRequest = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb_Devp' : 'CoffeeWeb', userDetails?.googlesignid ? userDetails?.email : userDetails?.phone), (doc) => {
        const firebaseResponse = doc.data()

        if (mounted) {
          if (firebaseResponse?.UserNotifications?.UnreadCount >= 0) {
            setUnreadNotificationCount(firebaseResponse?.UserNotifications?.UnreadCount)
          }
        } else {
          mounted = true
        }
      })

      return () => {
        firebaseRequest()
      }
    }
  }

  const getNotificationForUser = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getUsersNotificationForUser({ userId: userDetails.id, pageNumber, pageSize })
      const { appNotificationDTO, notificationCount } = response.data.returnLst

      setUnreadNotificationCount(notificationCount.unreadNotification)
      if (appNotificationDTO.length > 0) {
        setUserNotificationForUser(appNotificationDTO)
        setPageNumber(pageNumber + 1)
      } else {
        setHasMore(false)
        setNoNotification(true)
      }
    } catch {
      setNoNotification(true)
    } finally {
      setLoading(false)
    }
  }

  const getNotificationForUserScroll = async () => {
    setNotificationLoader(true)

    try {
      const response = await apiAdapterCoffeeWeb.getUsersNotificationForUser({ userId: userDetails.id, pageNumber, pageSize })

      const { appNotificationDTO } = response.data.returnLst

      setUserNotificationForUser((userNotificationForUser) => [...userNotificationForUser, ...appNotificationDTO])
      setPageNumber(pageNumber + 1)
    } catch (error) {
      setShowAllLoadedMessage(true)
      setHasMore(false)
    } finally {
      setLoading(false)
      setNotificationLoader(false)
    }
  }

  const markNewsAsRead = async () => {
    setLoading(true)

    const updateData = {
      userId: userDetails?.id,
      newsId: checkAllAreChecked ? [] : selectedNotificationsForRead
    }
    const { newsId } = updateData

    try {
      const response = await apiAdapterCoffeeWeb.updateNotificationReadAll(updateData)

      setLocalWithLatestNewsReadNotification(selectedNotificationsForRead)
      if (response?.data) {
        const updateNewsRead = userNotificationForUser.map((ele) => {
          const isSelected = selectedNotificationsForRead.includes(ele.sourceId)

          return {
            ...ele,
            isChecked: false,
            isRead: isSelected ? true : ele.isRead
          }
        })

        setUserNotificationForUser(updateNewsRead)
        setUnreadNotificationCount(newsId.length)
        setSelectedNotificationsForRead([])
        setShowCheckBox(false)
      }
    } catch (error) {
      console.log(error.response)
    } finally {
      setLoading(false)
    }
  }

  const handleOpenNotification = (notification) => {
    const { sourceId } = notification

    params.set('newsId', sourceId)

    history.push({
      search: params.toString(),
      pathname: ROUTE_STRINGS.opennewsnotification,
      state: { pageRef: 'yes' }
    })
    setLocalWithLatestNewsReadNotification(sourceId)
  }

  const showCheckBoxToSelect = () => {
    setShowCheckBox(true)
  }

  const selectedNotifications = ({ value, checked }) => {
    const selectedNotificationsByUser = userNotificationForUser.map((ele) => (ele.sourceId === value.sourceId ? { ...ele, isChecked: checked } : ele))
    const sleetedNotificationIds = selectedNotificationsByUser.filter((ele) => ele.isChecked).map((ele) => ele.sourceId)

    setUserNotificationForUser(selectedNotificationsByUser)
    setSelectedNotificationsForRead(sleetedNotificationIds)
  }

  const checkAllAreChecked = userNotificationForUser?.filter((ele) => !ele.isRead).every((ele) => ele.isChecked)

  const selectAllNotification = () => {
    const selectedNotificationsByUser = userNotificationForUser.map((ele) => ({ ...ele, isChecked: !checkAllAreChecked }))
    const sleetedNotificationIds = selectedNotificationsByUser.filter((ele) => ele.isChecked).map((ele) => ele.sourceId)

    setUserNotificationForUser(selectedNotificationsByUser.map((ele) => ({ ...ele, isChecked: !checkAllAreChecked })))
    setSelectedNotificationsForRead(sleetedNotificationIds)
  }

  const getDynamicHeight = (initialHeight, height, property) => {
    const isMobile = window.innerWidth < 767

    if (selectedNotificationsForRead?.length > 0) {
      return { [property]: isMobile ? height - 40 : height }
    }

    return { [property]: isMobile ? initialHeight - 40 : initialHeight }
  }

  return (
    <>
      <div className="notification_screen_container" style={styleForNotificationContainer}>
        {selectedNotificationsForRead?.length > 0 && (
          <div className="mark-as-read-wrapper">
            <div className="mark-as-read-button" onClick={markNewsAsRead} data-testId="mark-news-read-button">
              {t('MARK_ALL_AS_READ')}
            </div>
          </div>
        )}

        <div className="notification_screen">
          <div className="screen_heading" style={styleForNotificationHeader}>
            <div>{t('NOTIFICATIONS')}</div>
            <div className="notification-mark-as-read">
              {unreadNotificationCount > 0 && <div onClick={showCheckBoxToSelect}>{!showCheckBox && t('MARK_AS_READ')}</div>}
              {showCheckBox && <div onClick={selectAllNotification}> {checkAllAreChecked ? t('UNSELECT_ALL') : t('SELECT_ALL')}</div>}
            </div>
          </div>
          <InfiniteScroll dataLength={userNotificationForUser?.length} next={userNotificationForUser?.length > 15 && getNotificationForUserScroll} hasMore={hasMore} scrollThreshold="80px">
            <div className="notification_message_store">
              {userNotificationForUser?.length ? (
                userNotificationForUser?.map((ele, index) => (
                  <div key={index}>
                    <div className="each_message_highlight" id={!ele.isRead && 'unread'}>
                      <div className="heading-checkbox-wrapper">
                        <div className="message_title" onClick={() => handleOpenNotification(ele)}>
                          {ReactHtmlParser(ele.notificationMessage)}
                        </div>
                        <div>{showCheckBox && !ele?.isRead && <Checkbox value={ele} onChange={(ele) => selectedNotifications(ele)} checked={ele?.isChecked} />}</div>
                      </div>
                      <div className="message_arrived_time" onClick={() => handleOpenNotification(ele)}>
                        {ele.timeOnly}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="notification_not_found">{!isLoading && <img src={NoData} alt="NoDataFound" className="no_image_found" data-testid="no-data-image" />}</div>
              )}
            </div>
          </InfiniteScroll>
          <div>
            {notificationLoader && (
              <div className="custom-spinner-loader" data-testId="loader-container">
                <div className="loader-spinner"></div>
              </div>
            )}
          </div>
          {noNotification && !userNotificationForUser?.length && <div className="no-notification">{t('NO_NOTIFICATIONS')}</div>}
        </div>
      </div>
      {showAllLoadedMessage && <div className="no-more-notifications-message">{t('NO_MORE_NOTIFICATIONS')}</div>}
    </>
  )
}

export default Notification
