import React, { useState, useEffect, useRef } from 'react'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import { popupIcon } from 'Assets/Icons'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { coffeeReportJsonLinks } from 'Utils/Constants'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'

export default function Columbia({ swapReport, setLoading }) {
  const { t } = useTranslation()
  const toast = useRef(null)
  const { columbiaCountryWiseExportsReport } = coffeeReportJsonLinks

  const [fetchedData, setFetchedData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [currentData, setCurrentData] = useState({})
  const [previousData, setPreviousData] = useState({})
  const [currentYear, setCurrentYear] = useState('')
  const [previousYear, setPreviousYear] = useState('')
  const [modalType, setModalType] = useState(null)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)

    const reportName = columbiaCountryWiseExportsReport

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setFetchedData(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const calculateTotals = (countryCertifiedData) => {
    const totals = {
      EXPORTS: 0
    }

    countryCertifiedData.forEach(({ EXPORTS }) => {
      totals.EXPORTS += Number(EXPORTS) || 0
    })

    return totals
  }

  const convertMTToBags = (valueInMT) => {
    if (valueInMT === '' || Number.isNaN(Number(valueInMT))) {
      return ''
    }
    const result = (valueInMT * 1000) / 60

    return Math.round(result)
  }

  const valueForBags = (valueInMT) => {
    if (valueInMT === '' || Number.isNaN(Number(valueInMT))) {
      return ''
    }

    return valueInMT || ''
  }

  const displayValue = (value) => (swapReport === 'MT' ? valueForBags(Math.round(value)).toLocaleString() : convertMTToBags(value).toLocaleString())

  const calculateTotalsWithMonth = (data) => {
    const totals = {
      EXPORTS: 0,
      MONTH: ''
    }

    data.forEach(({ EXPORTS, MONTH }) => {
      totals.EXPORTS += Number(EXPORTS) || 0

      if (MONTH) {
        totals.MONTH = MONTH
      }
    })

    return totals
  }

  const getTextColor = (value) => {
    if (value === 0) {
      return 'neutral'
    }
    if (value > 0) {
      return 'positive'
    }

    return 'negative'
  }

  const showMonthValueModal = (currentIndex, selectedYear) => {
    const filteredData = fetchedData.find(({ year }) => year === selectedYear)

    const selectedIndex = fetchedData.findIndex(({ year }) => year === selectedYear)

    const nextData = selectedIndex !== -1 && selectedIndex + 1 < fetchedData.length ? fetchedData[selectedIndex + 1] : null

    if (!filteredData || !nextData) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })

      return
    }

    const currentYearData = filteredData?.report[currentIndex]
    const previousYearData = nextData?.report[currentIndex]

    const currentYearValue = filteredData?.year.split(' : ')[1]
    const previousYearValue = nextData?.year.split(' : ')[1]

    setCurrentData(currentYearData)
    setPreviousData(previousYearData)
    setCurrentYear(currentYearValue)
    setPreviousYear(previousYearValue)
    setModalType('month')
    setShowModal(true)

    if (currentYearData?.EXPORTS !== '' && previousYearData?.EXPORTS !== '') {
      setShowModal(true)
    } else {
      setShowModal(false)
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_SELECTED_YEAR'), life: 3000 })
    }

    if (currentYearData.EXPORTS && previousYearData.EXPORTS) {
      setShowModal(true)
    }
  }

  const showTotalValueModal = (selectedYear) => {
    const filteredData = fetchedData.find(({ year }) => year === selectedYear)

    const selectedIndex = fetchedData.findIndex(({ year }) => year === selectedYear)

    const nextData = selectedIndex !== -1 && selectedIndex + 1 < fetchedData.length ? fetchedData[selectedIndex + 1] : null

    if (!filteredData || !nextData) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })

      return
    }

    const totalValue = []

    const updateData = filteredData?.report.filter((ele) => ele.EXPORTS)

    for (let i = 0; i < updateData.length; i++) {
      const elementCopy = { ...nextData?.report[i] }

      totalValue.push(elementCopy)
    }

    if (filteredData && nextData) {
      const currentYearTotal = calculateTotalsWithMonth(updateData)
      const previousYearTotal = calculateTotalsWithMonth(totalValue)

      const currentYearValue = filteredData?.year.split(' : ')[1]
      const previousYearValue = nextData?.year.split(' : ')[1]

      setCurrentData(currentYearTotal)
      setPreviousData(previousYearTotal)

      setCurrentYear(currentYearValue)
      setPreviousYear(previousYearValue)
      setModalType('total')

      if (currentYearTotal && previousYearTotal) {
        setShowModal(true)
      }
    }
  }

  const comparisonTableData = () => {
    if (!currentData || !previousData) {
      return null
    }

    const displayValue = (value) => (swapReport === 'MT' ? valueForBags(value).toLocaleString() : convertMTToBags(value).toLocaleString())

    const difference = {
      export: Number(currentData.EXPORTS) - Number(previousData.EXPORTS) || 0
    }

    const percentageDifference = {
      export: previousData.EXPORTS ? ((difference.export / Number(previousData.EXPORTS)) * 100).toFixed(2) : 'N/A'
    }

    const currentMonth = currentData.MONTH?.split('-')[0]
    const previousMonth = previousData.MONTH?.split('-')[0]

    const currentMonthYear = `${currentMonth} - ${currentYear}`
    const previousMonthYear = previousData ? `${previousMonth} - ${previousYear}` : ''

    const dynamicTitle = modalType === 'total' ? `UPTO ${currentMonthYear} TO ${previousMonthYear} COMPARISON` : `${currentMonthYear} TO ${previousMonthYear} COMPARISON`

    return (
      <div className="comparison-table">
        <div className="content-title">
          <h5>{dynamicTitle}</h5>
        </div>
        <table>
          <thead>
            <tr>
              <th>{t('MONTH')}</th>
              <th>{t('EXPORTS')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{currentData.MONTH}</td>
              <td>{displayValue(Math.round(currentData.EXPORTS))}</td>
            </tr>
            <tr>
              <td>{previousData ? previousData.MONTH : ''}</td>
              <td>{displayValue(Math.round(previousData.EXPORTS))}</td>
            </tr>
            <tr>
              <td>{t('DIFFERENCE')}</td>
              <td className={getTextColor(difference.export)}>{displayValue(Math.round(difference.export))}</td>
            </tr>
            <tr>
              <td>{t('% WISE')}</td>
              <td className={getTextColor(percentageDifference.export)}>{percentageDifference.export !== 'N/A' ? `${percentageDifference.export}%` : 'N/A'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="horizontal-scroll-container" id="port-wise-stock-report">
      {fetchedData?.map(({ report, year }, index) => {
        const { EXPORTS } = calculateTotals(report)

        return (
          <div key={index} className="table-wrapper">
            <table>
              <thead>
                <tr className="year-row">
                  <td colSpan={6}>
                    <div>{year}</div>
                  </td>
                </tr>
                <tr>
                  <th className="month">{t('MONTH')}</th>
                  <th>EXPORTS</th>
                </tr>
              </thead>
              <tbody>
                {report.map(({ MONTH, EXPORTS }, index) => (
                  <tr key={index}>
                    <td>
                      <div className="popup-wrapper" onClick={() => showMonthValueModal(index, year)}>
                        <div>{MONTH}</div>
                        <div className="icon-wrapper">
                          <img src={popupIcon} alt={MONTH} className="popup-icon" />
                        </div>
                      </div>
                    </td>
                    <td>{displayValue(EXPORTS)}</td>
                  </tr>
                ))}
                <tr className="tr-color">
                  <td>
                    <div className="popup-wrapper" onClick={() => showTotalValueModal(year)}>
                      <div>{t('TOTAL')}</div>
                      <div className="icon-wrapper">
                        <img src={popupIcon} alt={'icon'} className="popup-icon" />
                      </div>
                    </div>
                  </td>
                  <td> {swapReport === 'MT' ? Math.round(EXPORTS).toLocaleString() || '' : convertMTToBags(EXPORTS).toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      })}

      <Toast ref={toast} position="top-right" />

      <DialogUIModal title={modalType === 'total' ? t('TOTAL_COMPARISON_DATA') : t('MONTH_COMPARISON_DATA')} showModal={showModal} handleClose={() => setShowModal(false)} bodyContent={comparisonTableData} titlePosition="center" size="lg" />
    </div>
  )
}
