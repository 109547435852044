import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Toast } from 'primereact/toast'
import { popupIcon } from 'Assets/Icons'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { coffeeReportJsonLinks } from 'Utils/Constants'

export default function Uganda({ swapReport, setLoading }) {
  const { t } = useTranslation()
  const toast = useRef(null)
  const { ugandaCountryWiseExportsReport } = coffeeReportJsonLinks

  const [fetchedData, setFetchedData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [currentData, setCurrentData] = useState({})
  const [previousData, setPreviousData] = useState({})
  const [currentYear, setCurrentYear] = useState('')
  const [previousYear, setPreviousYear] = useState('')
  const [modalType, setModalType] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      const reportName = ugandaCountryWiseExportsReport

      try {
        const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

        setFetchedData(response.data)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const calculateTotals = (countryCertifiedData) => {
    const totals = {
      ARABICA: 0,
      ROBUSTA: 0
    }

    countryCertifiedData.forEach(({ ARABICA, ROBUSTA }) => {
      totals.ARABICA += Number(ARABICA) || 0
      totals.ROBUSTA += Number(ROBUSTA) || 0
    })

    return totals
  }

  const convertMTToBags = (valueInMT) => {
    if (valueInMT === '' || Number.isNaN(Number(valueInMT))) {
      return ''
    }
    const result = (valueInMT * 1000) / 60

    return Math.round(result) || ''
  }

  const valueForBags = (valueInMT) => {
    if (valueInMT === '' || Number.isNaN(Number(valueInMT))) {
      return ''
    }

    return valueInMT || ''
  }

  const calculateTotalsWithMonth = (data) => {
    const totals = {
      ARABICA: 0,
      ROBUSTA: 0,
      MONTH: ''
    }

    data.forEach(({ ARABICA, ROBUSTA, MONTH }) => {
      totals.ARABICA += Number(ARABICA) || 0
      totals.ROBUSTA += Number(ROBUSTA) || 0

      if (MONTH) {
        totals.MONTH = MONTH
      }
    })

    return totals
  }

  const getTextColor = (value) => {
    if (value === 0) {
      return 'neutral'
    }
    if (value > 0) {
      return 'positive'
    }

    return 'negative'
  }

  const showMonthValueModal = (currentIndex, selectedYear) => {
    const filteredData = fetchedData.find(({ year }) => year === selectedYear)

    const selectedIndex = fetchedData.findIndex(({ year }) => year === selectedYear)

    const nextData = selectedIndex !== -1 && selectedIndex + 1 < fetchedData.length ? fetchedData[selectedIndex + 1] : null

    if (!filteredData || !nextData) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })

      return
    }

    const currentYearData = filteredData?.report[currentIndex]
    const previousYearData = nextData?.report[currentIndex]

    const currentYearValue = filteredData?.year.split(' : ')[1]
    const previousYearValue = nextData?.year.split(' : ')[1]

    setCurrentData(currentYearData)
    setPreviousData(previousYearData)
    setCurrentYear(currentYearValue)
    setPreviousYear(previousYearValue)
    setModalType('month')

    if (currentYearData?.ARABICA !== '' && currentYearData?.ROBUSTA !== '' && previousYearData?.ARABICA !== '' && previousYearData?.ROBUSTA !== '') {
      setShowModal(true)
    } else {
      setShowModal(false)
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_SELECTED_YEAR'), life: 3000 })
    }
  }

  const showTotalValueModal = (selectedYear) => {
    const filteredData = fetchedData.find(({ year }) => year === selectedYear)

    const selectedIndex = fetchedData.findIndex(({ year }) => year === selectedYear)

    const nextData = selectedIndex !== -1 && selectedIndex + 1 < fetchedData.length ? fetchedData[selectedIndex + 1] : null

    if (!filteredData || !nextData) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })

      return
    }

    const totalValue = []

    const updateData = filteredData?.report.filter((ele) => ele.ARABICA && ele.ROBUSTA)

    for (let i = 0; i < updateData.length; i++) {
      const elementCopy = { ...nextData?.report[i] }

      totalValue.push(elementCopy)
    }

    if (filteredData && nextData) {
      const currentYearTotal = calculateTotalsWithMonth(updateData)
      const previousYearTotal = calculateTotalsWithMonth(totalValue)

      const currentYearValue = filteredData?.year.split(' : ')[1]
      const previousYearValue = nextData?.year.split(' : ')[1]

      setCurrentData(currentYearTotal)
      setPreviousData(previousYearTotal)

      setCurrentYear(currentYearValue)
      setPreviousYear(previousYearValue)
      setModalType('total')

      if (currentYearTotal && previousYearTotal) {
        setShowModal(true)
      }
    }
  }

  const comparisonTableData = () => {
    if (!currentData || !previousData) {
      return null
    }

    const displayValue = (value) => (swapReport === 'MT' ? valueForBags(value).toLocaleString() : convertMTToBags(value).toLocaleString())

    const sumOfCurrentReports = currentData ? Number(currentData.ARABICA) + Number(currentData.ROBUSTA) : 0
    const sumOfPreviousReports = previousData ? Number(previousData.ARABICA) + Number(previousData.ROBUSTA) : 0

    const difference = {
      arabica: Number(currentData.ARABICA) - Number(previousData.ARABICA) || 0,
      robusta: Number(currentData.ROBUSTA) - Number(previousData.ROBUSTA) || 0,
      total: sumOfCurrentReports - sumOfPreviousReports
    }

    const percentageDifference = {
      arabica: previousData.ARABICA ? ((difference.arabica / Number(previousData.ARABICA)) * 100).toFixed(2) : 'N/A',
      robusta: previousData.ROBUSTA ? ((difference.robusta / Number(previousData.ROBUSTA)) * 100).toFixed(2) : 'N/A',
      total: sumOfPreviousReports ? ((difference.total / sumOfPreviousReports) * 100).toFixed(2) : 'N/A'
    }

    const currentMonth = currentData.MONTH?.split('-')[0]
    const previousMonth = previousData.MONTH?.split('-')[0]

    const currentMonthYear = `${currentMonth} - ${currentYear}`
    const previousMonthYear = previousData ? `${previousMonth} - ${previousYear}` : ''

    const dynamicTitle = modalType === 'total' ? `UPTO ${currentMonthYear} TO ${previousMonthYear} COMPARISON` : `${currentMonthYear} TO ${previousMonthYear} COMPARISON`

    return (
      <div className="comparison-table">
        <div className="content-title">
          <h5>{dynamicTitle}</h5>
        </div>
        <table>
          <thead>
            <tr>
              <th>{t('MONTH')}</th>
              <th>{t('ROBUSTA_COFFEE_REPORTS')}</th>
              <th>{t('ARABICA_COFFEE_REPORTS')}</th>
              <th>{t('TOTAL')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{currentData.MONTH}</td>
              <td>{displayValue(Math.round(currentData.ROBUSTA))}</td>
              <td>{displayValue(Math.round(currentData.ARABICA))}</td>
              <td>{displayValue(Math.round(sumOfCurrentReports))}</td>
            </tr>
            <tr>
              <td>{previousData ? previousData.MONTH : ''}</td>
              <td>{displayValue(Math.round(previousData.ROBUSTA))}</td>
              <td>{displayValue(Math.round(previousData.ARABICA))}</td>
              <td>{displayValue(Math.round(sumOfPreviousReports))}</td>
            </tr>
            <tr>
              <td>{t('DIFFERENCE')}</td>
              <td className={getTextColor(difference.robusta)}>{displayValue(Math.round(difference.robusta))}</td>
              <td className={getTextColor(difference.arabica)}>{displayValue(Math.round(difference.arabica))}</td>
              <td className={getTextColor(difference.total)}>{displayValue(Math.round(difference.total))}</td>
            </tr>
            <tr>
              <td>{t('% WISE')}</td>
              <td className={getTextColor(percentageDifference.robusta)}>{percentageDifference.robusta !== 'N/A' ? `${percentageDifference.robusta}%` : 'N/A'}</td>
              <td className={getTextColor(percentageDifference.arabica)}>{percentageDifference.arabica !== 'N/A' ? `${percentageDifference.arabica}%` : 'N/A'}</td>
              <td className={getTextColor(percentageDifference.total)}>{percentageDifference.total !== 'N/A' ? `${percentageDifference.total}%` : 'N/A'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="horizontal-scroll-container" id="port-wise-stock-report">
      {fetchedData?.map(({ report, year }, index) => {
        const { ROBUSTA, ARABICA } = calculateTotals(report)
        const sumOfTotals = Math.round(ROBUSTA + ARABICA)

        return (
          <div key={index} className="table-wrapper">
            <table>
              <thead>
                <tr className="year-row">
                  <td colSpan={6}>
                    <div>{year}</div>
                  </td>
                </tr>
                <tr>
                  <th className="month">{t('MONTH')}</th>
                  <th className="roubsta">{t('ROBUSTA_COFFEE_REPORTS')}</th>
                  <th className="arabica">{t('ARABICA_COFFEE_REPORTS')}</th>
                  <th className="total">{t('TOTAL')}</th>
                </tr>
              </thead>
              <tbody>
                {report.map(({ MONTH, ROBUSTA, ARABICA }, index) => {
                  const sumOfReports = Math.round(ROBUSTA + ARABICA)

                  const displayValue = (value) => (swapReport === 'MT' ? valueForBags(Math.round(value)).toLocaleString() : convertMTToBags(value).toLocaleString())

                  return (
                    <tr key={index}>
                      <td>
                        <div className="popup-wrapper" onClick={() => showMonthValueModal(index, year)}>
                          <div>{MONTH}</div>
                          <div className="icon-wrapper">
                            <img src={popupIcon} alt={MONTH} className="popup-icon" />
                          </div>
                        </div>
                      </td>

                      <td>{displayValue(ROBUSTA)} </td>
                      <td> {displayValue(ARABICA)}</td>
                      <td> {displayValue(sumOfReports)}</td>
                    </tr>
                  )
                })}
                <tr className="tr-color">
                  <td>
                    <div className="popup-wrapper" onClick={() => showTotalValueModal(year)}>
                      <div>{t('TOTAL')}</div>
                      <div className="icon-wrapper">
                        <img src={popupIcon} alt={'icon'} className="popup-icon" />
                      </div>
                    </div>
                  </td>
                  <td> {swapReport === 'MT' ? Math.round(ROBUSTA).toLocaleString() || '' : convertMTToBags(ROBUSTA).toLocaleString()}</td>
                  <td> {swapReport === 'MT' ? Math.round(ARABICA).toLocaleString() || '' : convertMTToBags(ARABICA).toLocaleString()}</td>
                  <td> {swapReport === 'MT' ? Math.round(sumOfTotals).toLocaleString() || '' : convertMTToBags(sumOfTotals).toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      })}

      <Toast ref={toast} position="top-right" />

      <DialogUIModal title={modalType === 'total' ? t('TOTAL_COMPARISON_DATA') : t('MONTH_COMPARISON_DATA')} showModal={showModal} handleClose={() => setShowModal(false)} bodyContent={comparisonTableData} titlePosition="center" size="lg" />
    </div>
  )
}
