import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { popupIcon } from 'Assets/Icons'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { coffeeReportJsonLinks } from 'Utils/Constants'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import { Toast } from 'primereact/toast'

export default function Vietnam({ swapReport, setLoading }) {
  const { t } = useTranslation()
  const toast = useRef(null)
  const { vietnamCountryWiseExportsReport } = coffeeReportJsonLinks

  const [fetchedData, setFetchedData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [currentData, setCurrentData] = useState({})
  const [previousData, setPreviousData] = useState({})
  const [currentYear, setCurrentYear] = useState('')
  const [previousYear, setPreviousYear] = useState('')
  const [modalType, setModalType] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      const reportName = vietnamCountryWiseExportsReport

      try {
        const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

        setFetchedData(response.data)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const calculateTotals = (countryCertifiedData) => {
    const totals = {
      MT: 0
    }

    countryCertifiedData.forEach(({ MT }) => {
      totals.MT += Number(MT) || 0
    })

    return totals
  }

  const convertMTToBags = (valueInMT) => {
    if (valueInMT === '' || Number.isNaN(Number(valueInMT))) {
      return ''
    }
    const result = (valueInMT * 1000) / 60

    return Math.round(result)
  }

  const calculateTotalsWithMonth = (data) => {
    const totals = {
      MT: 0,
      MONTH: ''
    }

    data.forEach(({ MT, MONTH }) => {
      totals.MT += Number(MT) || 0

      if (MONTH) {
        totals.MONTH = MONTH
      }
    })

    return totals
  }

  const getTextColor = (value) => {
    if (value === 0) {
      return 'neutral'
    }
    if (value > 0) {
      return 'positive'
    }

    return 'negative'
  }

  const showMonthValueModal = (currentIndex, selectedYear) => {
    const filteredData = fetchedData.find(({ year }) => year === selectedYear)

    const selectedIndex = fetchedData.findIndex(({ year }) => year === selectedYear)

    const nextData = selectedIndex !== -1 && selectedIndex + 1 < fetchedData.length ? fetchedData[selectedIndex + 1] : null

    if (!filteredData || !nextData) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })

      return
    }

    const currentYearData = filteredData?.report[currentIndex]
    const previousYearData = nextData?.report[currentIndex]

    const currentYearValue = filteredData?.year.split(' : ')[1]
    const previousYearValue = nextData?.year.split(' : ')[1]

    setCurrentData(currentYearData)
    setPreviousData(previousYearData)
    setCurrentYear(currentYearValue)
    setPreviousYear(previousYearValue)
    setModalType('month')
    setShowModal(true)

    if (currentYearData?.MT !== '' && previousYearData?.MT !== '') {
      setShowModal(true)
    } else {
      setShowModal(false)
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_SELECTED_YEAR'), life: 3000 })
    }
  }

  const showTotalValueModal = (selectedYear) => {
    const filteredData = fetchedData.find(({ year }) => year === selectedYear)

    const selectedIndex = fetchedData.findIndex(({ year }) => year === selectedYear)

    const nextData = selectedIndex !== -1 && selectedIndex + 1 < fetchedData.length ? fetchedData[selectedIndex + 1] : null

    if (!filteredData || !nextData) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })

      return
    }

    const totalValue = []

    const updateData = filteredData?.report.filter((ele) => ele.MT)

    for (let i = 0; i < updateData.length; i++) {
      const elementCopy = { ...nextData?.report[i] }

      totalValue.push(elementCopy)
    }

    if (filteredData && nextData) {
      const currentYearTotal = calculateTotalsWithMonth(updateData)
      const previousYearTotal = calculateTotalsWithMonth(totalValue)

      const currentYearValue = filteredData?.year.split(' : ')[1]
      const previousYearValue = nextData?.year.split(' : ')[1]

      setCurrentData(currentYearTotal)
      setPreviousData(previousYearTotal)

      setCurrentYear(currentYearValue)
      setPreviousYear(previousYearValue)
      setModalType('total')

      if (currentYearTotal && previousYearTotal) {
        setShowModal(true)
      }
    }
  }

  const comparisonTableData = () => {
    if (!currentData || !previousData) {
      return null
    }

    const displayValue = (value) => (swapReport === 'MT' ? value.toLocaleString() : convertMTToBags(value).toLocaleString())

    const difference = {
      mt: Number(currentData.MT) - Number(previousData.MT) || 0
    }

    const percentageDifference = {
      mt: previousData.MT ? ((difference.mt / Number(previousData.MT)) * 100).toFixed(2) : 'N/A'
    }

    const currentMonth = currentData.MONTH?.split('-')[0]
    const previousMonth = previousData.MONTH?.split('-')[0]

    const currentMonthYear = `${currentMonth} - ${currentYear}`
    const previousMonthYear = previousData ? `${previousMonth} - ${previousYear}` : ''

    const dynamicTitle = modalType === 'total' ? `UPTO ${currentMonthYear} TO ${previousMonthYear} COMPARISON` : `${currentMonthYear} TO ${previousMonthYear} COMPARISON`

    return (
      <div className="comparison-table">
        <div className="content-title">
          <h5>{dynamicTitle}</h5>
        </div>
        <table>
          <thead>
            <tr>
              <th>{t('MONTH')}</th>
              <th>{t('EXPORTS')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{currentData.MONTH}</td>
              <td>{displayValue(Math.round(currentData.MT))}</td>
            </tr>

            <tr>
              <td>{previousData ? previousData.MONTH : ''}</td>
              <td>{displayValue(Math.round(previousData.MT))}</td>
            </tr>

            <tr>
              <td>{t('DIFFERENCE')}</td>
              <td className={getTextColor(difference.mt)}>{displayValue(Math.round(difference.mt))}</td>
            </tr>

            <tr>
              <td>{t('% WISE')}</td>
              <td className={getTextColor(percentageDifference.mt)}>{percentageDifference.mt !== 'N/A' ? `${percentageDifference.mt}%` : 'N/A'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="horizontal-scroll-container" id="port-wise-stock-report">
      {fetchedData?.map(({ report, year }, index) => {
        const { MT } = calculateTotals(report)

        return (
          <div key={index} className="table-wrapper">
            <table>
              <thead>
                <tr className="year-row">
                  <td colSpan={6}>
                    <div>{year}</div>
                  </td>
                </tr>
                <tr>
                  <th className="month">{t('MONTH')}</th>
                  <th>EXPORTS</th>
                </tr>
              </thead>
              <tbody>
                {report.map(({ MONTH, MT }, index) => (
                  <tr key={index}>
                    <td>
                      <div className="popup-wrapper" onClick={() => showMonthValueModal(index, year)}>
                        <div>{MONTH}</div>
                        <div className="icon-wrapper">
                          <img src={popupIcon} alt={MONTH} className="popup-icon" />
                        </div>
                      </div>
                    </td>
                    <td> {swapReport === 'MT' ? MT.toLocaleString() : convertMTToBags(MT).toLocaleString()}</td>
                  </tr>
                ))}
                <tr className="tr-color">
                  <td>
                    <div className="popup-wrapper" onClick={() => showTotalValueModal(year)}>
                      <div>{t('TOTAL')}</div>
                      <div className="icon-wrapper">
                        <img src={popupIcon} alt={'icon'} className="popup-icon" />
                      </div>
                    </div>
                  </td>
                  <td> {swapReport === 'MT' ? MT.toLocaleString() : convertMTToBags(MT).toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      })}

      <Toast ref={toast} position="top-right" />

      <DialogUIModal title={modalType === 'total' ? t('TOTAL_COMPARISON_DATA') : t('MONTH_COMPARISON_DATA')} showModal={showModal} handleClose={() => setShowModal(false)} bodyContent={comparisonTableData} titlePosition="center" size="lg" />
    </div>
  )
}
