import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { getLocalUserDetails, setLocalWithPayPalData } from 'Utils/LocalStorage_Handler'

function paypal({ setLoading, showError }) {
  const userDetails = getLocalUserDetails()

  const paypalCreateOrder = async (ele) => {
    const postData = {
      order: {
        userId: userDetails.id,
        billingAddress: 'NA',
        shippingAddress: 'NA',
        states: userDetails.states,
        city: userDetails.city,
        totalQuantity: 1,
        totalAmount: ele.basePrice,
        paymentDate: '2022-12-24T04:51:11.228Z',
        orderDate: '2022-12-24T04:51:11.228Z',
        payOrderId: 'NA',
        subscriptionId: ele.name,
        facilitatorAccessToken: 'NA',
        paymentId: ele.paymentId,
        paymentSource: ele.paymentName,
        paymentStatus: 'Pending',
        orderSatus: 1
      },
      orderDetails: [
        {
          idOrderDetail: 0,
          orderId: 0,
          planId: 'NA',
          serviceName: 'NA',
          subscriptionType: ele.paymentName,
          quantity: 0,
          rate: 0
        }
      ]
    }

    await apiAdapterCoffeeWeb
      .createOrder(postData)
      .then((response) => {
        if (response.data.isSuccess === true) {
          InitiatePaypalPayment(ele, response.data.returnLst)
        }
      })
      .catch(() => {
        showError({
          title: 'Error !',
          message: `<p>${'An unknown error has been occurred while processing your request.'}</p><p>${'Please try again in sometime.'}</p>`,
          buttonLabel: 'OK'
        })
      })
  }

  const InitiatePaypalPayment = async (ele, oderData) => {
    setLoading(true)

    const postData = {
      currency_code: 'USD',
      userPhoneNumber: userDetails.phone,
      payPalItems: [
        {
          name: ele.name,
          description: ele.subscriptionSecondName,
          unit_amount: ele.amountInDollars,
          quantity: '1'
        }
      ],
      payer: {
        name: {
          given_name: userDetails.firstName,
          surname: userDetails.lastName
        }
      }
    }

    try {
      const response = await apiAdapterCoffeeWeb.makePayPalPayment(postData)

      if (response.data.statusCode === 201) {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
        const paypalLink = response.data.returnLst?.links.find((ele) => ele.rel === 'approve')

        window.open(`${paypalLink.href}`, '_parent')

        const paymentDetails = {
          orderId: oderData.orderDetails[0].orderId,
          paymentId: response.data?.returnLst?.id,
          uuid: response.data?.returnLst?.uuid,
          amount: ele.basePrice,
          subName: ele.name,
          paymentSource: ele.paymentName,
          facilitatorAccessToken: response.data?.returnLst?.uuid
        }

        setLocalWithPayPalData(paymentDetails)
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
  }

  return {
    paypalCreateOrder
  }
}

export default paypal
