import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppleIcon, GooglePlay } from 'Assets/Icons'

function AppLinks() {
  const { t } = useTranslation()

  return (
    <div className="coffee-web-app-links">
      <a href="https://apps.apple.com/in/app/coffeeweb/id1618878403" target="_blank" rel="noreferrer">
        <AppleIcon className="icon" fill={'#fff'} />
        <div>
          <div className="small-text"> {t('DOWNLOAD_ON_THE')}</div>
          <div className="big-text">{t('APP_STORE')}</div>
        </div>
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.coffeeweb.app" target="_blank" rel="noreferrer">
        <img className="icon" alt="play-store" src={GooglePlay} />
        <div>
          <div className="small-text">{t('GET_IT_ON')}</div>
          <div className="big-text">{t('GOOGLE_PLAY')}</div>
        </div>
      </a>
    </div>
  )
}

export default AppLinks
