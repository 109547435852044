import './styles.scss'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import CarouselMedia from 'Components/UIComponent/CarouselMedia'
import { getLocalUserDetails, setLocalWithSubscriptionNewsId } from 'Utils/LocalStorage_Handler'
import { FilledHeartIcon, UnFilledHeartIcon } from 'Assets/Icons'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ShareNewsfeed from 'Screens/CoffeeNewsFeeds/Components/ShareNewsfeed'
import Button from 'Components/UIComponent/Button'
import { addBlankTargetToAnchors } from 'Utils/HtmlUtils'
import { ROUTE_STRINGS } from 'Utils/Constants'
import ImagePreview from 'Components/UIComponent/ImagePreview'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

const OpenNewsNotification = ({ ...props }) => {
  // props for testing
  const { mockData, mockLiked, OpenNewsNotificationMockData } = props
  const location = useLocation()
  const params = new URLSearchParams(location?.search)
  const notificationNewsId = params.get('newsId')
  const history = useHistory()
  const userDetails = getLocalUserDetails()
  const { t } = useTranslation()
  const { setLoading } = useContext(CoffeeWebContext)
  const [coffeeNewsData, setCoffeeNewsData] = useState({})
  const [showShareNewsModal, setShowShareNewsModal] = useState(false)
  const [isLiked, setIsLiked] = useState(false)
  const [showImagePreview, setShowImagePreview] = useState(false)
  const [imagePreviewActiveIndex, setImagePreviewActiveIndex] = useState(0)

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (mockData && mockData.length > 0) {
      setCoffeeNewsData(mockData[0])
    }
  }, [mockData])
  // useEffect to initialize state for testing purposes
  useEffect(() => {
    setIsLiked(mockLiked)
  }, [])

  useEffect(() => {
    getCoffeeNewsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationNewsId, OpenNewsNotificationMockData])

  const getCoffeeNewsData = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getNewsById({ userId: userDetails.id, newsId: notificationNewsId, isRead: true })
      const getNotificationNewsData = response.data.returnLst
      const { newsFavourite, nwsFeedMedia, newsHtml, newsLock, newsForLock, newsForSubscription, id: newsId } = getNotificationNewsData

      const subscribedNews = newsForSubscription.split(',')
      const lockedNews = newsForLock.split(',')

      const isSubscriptionValid = subscribedNews.includes(`${userDetails?.sub?.subType}`) && !lockedNews.includes(`${userDetails?.sub?.subType}`)

      if (isSubscriptionValid) {
        const modifiedNewsHtml = addBlankTargetToAnchors(newsHtml)
        const modifiedNotificationData = { ...getNotificationNewsData, newsHtml: modifiedNewsHtml }

        if (newsFavourite) {
          setIsLiked((previousState) => !previousState)
        }
        setCoffeeNewsData(modifiedNotificationData)
      } else {
        history.replace(ROUTE_STRINGS.subscription)
        setLocalWithSubscriptionNewsId(newsId)
      }
    } catch (error) {
      console.log('Error', error.response)
    } finally {
      setLoading(false)
    }
  }

  const addToFavorites = async (newsId) => {
    const postData = {
      id: 0,
      idUser: userDetails.id,
      idNews: newsId.id,
      isFavourite: true
    }

    try {
      const {
        data: { returnLst, statusCode }
      } = await apiAdapterCoffeeWeb.postNewsUsersFavorite(postData)

      if (statusCode === 201) {
        const NewsData = { ...coffeeNewsData, newsFavourite: returnLst }

        setCoffeeNewsData(NewsData)
      }
      setIsLiked(true)
      setLoading(false)
    } catch (error) {
      console.log('Error', error)
    }
  }

  const removeFromFavorites = async ({ newsFavourite }) => {
    try {
      const {
        data: { returnLst, statusCode }
      } = await apiAdapterCoffeeWeb.makeNewsUnfavorite(newsFavourite.id)

      if (statusCode === 201) {
        const NewsData = { ...coffeeNewsData, newsFavourite: returnLst }

        setCoffeeNewsData(NewsData)
      }
      setIsLiked(false)
    } catch (error) {
      console.log('Error', error)
    }
  }

  const handleBackButton = () => {
    const { state } = history.location

    if (state?.pageRef === 'yes') {
      history.goBack()
    } else {
      history.replace(ROUTE_STRINGS.coffeeNewsFeeds)
    }
  }

  const handleShowShareButton = () => {
    setShowShareNewsModal(true)
  }

  const handleViewDocument = (item) => {
    window.open(item.filePath, '_blank')
  }

  const handleImagePreview = (index) => {
    setShowImagePreview(true)
    setImagePreviewActiveIndex(index)
  }

  return (
    <>
      {Object.keys(coffeeNewsData).length > 0 && (
        <div className="notification_news_container" data-testid="news-container">
          <div className="notification_news">
            <div className="notification_news_image_container" data-testid="notification-news-image-container">
              <div className="sticky_buttons" data-testid="button-container">
                <div className="back_button">
                  <Button data-testid="go-back-button" buttonType={'CUSTOM'} buttonLabel={t('GO_BACK')} buttonIcon={<i className="pi pi-arrow-left go-back-arrow-icon" />} variant={'soft-action'} buttonWidth={'full'} onButtonClick={handleBackButton} />
                </div>
                <div className="share_button">
                  <Button buttonType={'CUSTOM'} buttonLabel={t('SHARE')} buttonIcon={<i className="pi pi-share-alt share-icon" />} buttonWidth={'full'} onButtonClick={() => handleShowShareButton(coffeeNewsData)} />
                </div>
              </div>
              {coffeeNewsData.nwsFeedMedia?.length > 0 && (
                <div className="images_div" data-testid="newsFeed-Media">
                  {coffeeNewsData.nwsFeedMedia?.map((ele, index) => (
                    <div key={index}>{ele.typeMedia === 'image' ? <img alt="notification_images" src={ele.pathOfMedia} className="notification_media_assets" onClick={() => handleImagePreview(index)} /> : <video src={ele.pathOfMedia} className="notification_media_assets" controls />}</div>
                  ))}
                </div>
              )}
            </div>
            <div className="notification_news_content_container" data-testid="mobile-size-news-content-container">
              <div className="sticky_buttons_mobile_size" data-testid="sticky-buttons-mobile-container">
                <div className="back_button">
                  <Button buttonType={'CUSTOM'} buttonLabel={t('GO_BACK')} buttonIcon={<i className="pi pi-arrow-left go-back-arrow-icon" />} variant={'soft-action'} buttonWidth={'full'} onButtonClick={handleBackButton} />
                </div>
                <div className="share_button">
                  <Button buttonType={'CUSTOM'} buttonLabel={t('SHARE')} buttonIcon={<i className="pi pi-share-alt share-icon" />} buttonWidth={'full'} onButtonClick={() => handleShowShareButton(coffeeNewsData)} />
                </div>
              </div>
              <div className="sticky_banner" data-testid="sticky-banner-container">
                <div className="banner_icons" data-testid="banner-icons-container">
                  <div className="icon-container" data-testid="icon-container">
                    <i className="pi pi-calendar social-feed-icons calendar-clock-icons" data-testid="custom-date" />
                    {coffeeNewsData.customdate}
                  </div>
                  <div className="icon-container">
                    <i className="pi pi-clock social-feed-icons calendar-clock-icons" data-testid="custom-time" />
                    {coffeeNewsData.customtime}
                  </div>
                </div>
                <div className="banner_image" data-testid="news-subject">
                  {ReactHtmlParser(coffeeNewsData.subject)}
                </div>
                <div className="banner_icons">
                  <div className="icon-container account-icon-container">
                    <i className="pi pi-user social-feed-icons account-icon user-account-icon" />
                    <span className="author-text" data-testid="author-name">
                      {coffeeNewsData.author}
                    </span>
                  </div>
                  <div className="visibility_icon_and_like_button">
                    <div className="visibility_icon">
                      <i className="pi pi-eye social-feed-icons view-icon" data-testid="views-icon" />
                      {coffeeNewsData.views}
                    </div>
                    {isLiked ? (
                      <div className="button_pointer" onClick={() => removeFromFavorites(coffeeNewsData)} data-testid="filled-heart-icon">
                        <FilledHeartIcon />
                      </div>
                    ) : (
                      <div className="button_pointer" onClick={() => addToFavorites(coffeeNewsData)} data-testid="red-heart-icon">
                        <UnFilledHeartIcon className="heart_logo_blob" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="image_carousel" data-testid="image-carousel">
                <CarouselMedia mediaList={coffeeNewsData?.nwsFeedMedia} handleImagePreview={handleImagePreview} />
              </div>
              {coffeeNewsData.fileName ? (
                <div className="pdf_view_document_button">
                  <Button buttonType={'CUSTOM'} buttonLabel={t('VIEW_DOCUMENT')} onButtonClick={() => handleViewDocument(coffeeNewsData)} />
                </div>
              ) : (
                <div className="pdf_view_document_button" data-testid="view-document"></div>
              )}
              <div>
                <div className="content_view" data-testid="news-html-container">
                  {ReactHtmlParser(coffeeNewsData.newsHtml)}
                </div>
                <div className="news_html_for_height"></div>
              </div>
            </div>

            {showImagePreview && <ImagePreview mediaList={coffeeNewsData?.nwsFeedMedia} setShowImagePreview={setShowImagePreview} imagePreviewActiveIndex={imagePreviewActiveIndex} setImagePreviewActiveIndex={setImagePreviewActiveIndex} />}
            <div data-testid="share-news-modal">{showShareNewsModal && <ShareNewsfeed newsData={coffeeNewsData} closeModal={() => setShowShareNewsModal(false)} />}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default OpenNewsNotification
