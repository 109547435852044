import './styles.scss'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Sidebar } from 'primereact/sidebar'
import { doc, onSnapshot } from 'firebase/firestore'
import { getLocalAppSettings, getLocalUserDetails, getLocalFreeNewsLanguageId, getLocalFreeMenus, getLocalLoggedInStatus, getLocalAppLanguage, getLocalSingleDeviceId, setLocalWithFreeNewsLanguageId, setLocalWithCurrentLocation, setLocalWithAppSettings, setLocalWithSingleDeviceId, getLocalCurrentLocation } from 'Utils/LocalStorage_Handler'
import { coffeewebLogo, ROUTE_STRINGS, STATIC_ROUTE_STRINGS } from 'Utils/Constants'
import { devEnvLogo } from 'Assets/Icons'
import { commonMethods, useResizeHandler } from 'Utils/commonMethods'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import NotificationListenService from './NotificationDropdown'
import { db } from '../../../firebase'
import ProfileModal from './Components/ProfileModal'
import LogoutModal from './Components/LogoutModal'

const Header = () => {
  const history = useHistory()
  const { i18n, t } = useTranslation()
  const { isWebView, isMobileView } = useResizeHandler()
  const { generateUniqueId, refreshLocalStorage } = commonMethods
  const { isLoading } = useContext(CoffeeWebContext)
  const overlayPanelRef = useRef(null)

  const appSettings = getLocalAppSettings()
  const userDetails = getLocalUserDetails()
  const freeNewsLanguageId = getLocalFreeNewsLanguageId()
  const freeMenusToDisplay = getLocalFreeMenus()
  const userLoginStatus = getLocalLoggedInStatus()

  const isLoggedInStatus = userDetails?.isLoggedIn

  let displayUrl

  if (!freeNewsLanguageId) {
    setLocalWithFreeNewsLanguageId(1)
  }

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false)
  const [showLogoutPopup, setShowLogoutPopup] = useState(false)
  const [modalShow, setModalShow] = React.useState(false)
  const [drawerState, setDrawerState] = React.useState(false)
  const [currentCity, setCurrentCity] = useState(null)

  const [menuDetails, setMenuDetails] = useState([])
  const [currentDateTime, setCurrentDateTime] = useState({ currentDate: '', currentTime: '' })

  const { currentDate, currentTime } = currentDateTime
  const pageUrl = window.location.pathname
  const splitPageUrl = pageUrl.split('/')
  const currentUrl = splitPageUrl.pop()

  useEffect(() => {
    getApiSettings()
    if (isLoggedInStatus) {
      const unsubscribe = init()

      return () => {
        unsubscribe()
      }
    }
  }, [isLoggedInStatus])

  useEffect(() => {
    const storedLocation = getLocalCurrentLocation()

    const fetchLocation = async () => {
      console.log('first')

      try {
        if (storedLocation) {
          console.log('second')
          setCurrentCity(storedLocation.name)
        } else {
          const locationResponse = await commonMethods.searchLocation()

          console.log('third')

          setLocalWithCurrentLocation(locationResponse)
          setCurrentCity(locationResponse?.name)
        }
      } catch (error) {
        console.error('Error getting geolocation:', error)
      }
    }

    if (isMobileView && !isLoggedInStatus) {
      fetchLocation()
    }
  }, [isMobileView, isLoggedInStatus])

  useEffect(() => {
    let intervalId

    if (drawerState && isMobileView && !isLoggedInStatus) {
      updateDateTime()
      intervalId = setInterval(updateDateTime, 1000)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [drawerState, isMobileView, isLoggedInStatus])

  useEffect(() => {
    if (isMobileView) {
      document.querySelector('body').style.overflow = drawerState ? 'hidden' : 'auto'
    }
  }, [drawerState])

  const updateDateTime = () => {
    const updatedDateTime = commonMethods.updateDateTime()

    setCurrentDateTime(updatedDateTime)
  }

  const init = () => {
    const unSub = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb_Devp' : 'CoffeeWeb', userDetails?.googlesignid ? userDetails?.email : userDetails?.phone), (doc) => {
      const response = doc.data()
      const singleDeviceId = getLocalSingleDeviceId()
      const userDetails = getLocalUserDetails()
      const isLoggedInStatus = userDetails?.isLoggedIn

      if (response) {
        if (response?.DeviceId !== singleDeviceId && isLoggedInStatus) {
          clearStorage('setNewDeviceId')
        } else if (response.LoginStatus === 'Log-Off' && !sessionStorage.getItem('logoutFlag')) {
          clearStorage()
        }
      }
    })

    if (userDetails?.menus !== null) {
      setMenuDetails(userDetails?.menus)
    }

    return () => {
      unSub()
    }
  }

  const getApiSettings = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.appSetting()

      if (response?.data?.id) {
        setLocalWithAppSettings(response?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const clearStorage = (setNewDeviceId) => {
    setShowLogoutPopup(true)
    setTimeout(() => {
      const { singleDeviceId } = refreshLocalStorage()

      if (setNewDeviceId) {
        setLocalWithSingleDeviceId(generateUniqueId())
      } else {
        setLocalWithSingleDeviceId(singleDeviceId)
      }

      sessionStorage.clear()
      setShowLogoutPopup(false)
      history.push(ROUTE_STRINGS.guestCoffeeQuotes)
    }, 5100)
  }

  const handleOpenForProfileSetting = () => {
    setDrawerState(false)
    setModalShow(true)
  }

  const openProfileSetting = () => {
    setDrawerState(false)
    setModalShow(true)
  }

  const navigateToSubscription = (event) => {
    setDrawerState(false)
    event.stopPropagation()
    history.push(ROUTE_STRINGS.subscription)
  }

  const navigateToCreateAccount = (event) => {
    setDrawerState(false)
    event.stopPropagation()
    history.push({ pathname: ROUTE_STRINGS.createAccount })
  }

  const navigateToLoginAccount = (event) => {
    setDrawerState(false)
    history.push({ pathname: ROUTE_STRINGS.login })
  }

  const checkForMenu = userDetails?.menus?.findIndex((ele) => ele.menuUrl === currentUrl)

  if (userDetails?.menus !== null) {
    displayUrl = userDetails?.menus[checkForMenu]?.menuName
  }

  function generateLinearGradientColor() {
    const defaultColor = '#50b3f6'
    const color = userDetails?.sub?.subscriptionColor || defaultColor

    const colorPart1 = color.slice(0, 7)
    const colorPart2 = color.slice(9, 16)
    const colorPart3 = color.slice(18, 27)

    return `linear-gradient(100deg, ${colorPart1}, ${colorPart2}, ${colorPart3})`
  }

  const handleClose = () => {
    const userLanguage = getLocalAppLanguage()

    i18n.changeLanguage(userLanguage)
    setModalShow(false)
  }

  const handleLogin = () => {
    if (userLoginStatus === false) {
      history.push(ROUTE_STRINGS.login)
    } else {
      history.push(ROUTE_STRINGS.createAccount)
    }
  }

  const redirectToHome = () => {
    if (userLoginStatus) {
      history.push(ROUTE_STRINGS.coffeequotes)
    } else {
      history.push(ROUTE_STRINGS.guestCoffeeQuotes)
    }
  }

  const showLogoutConfirmationModal = () => {
    setIsLogoutModalVisible(true)
    setDrawerState(false)
  }

  const handleProfileMouseEnter = () => {
    if (overlayPanelRef.current) {
      overlayPanelRef.current.hide()
    }
  }

  return (
    <>
      <div className={`pre-header ${isLoading ? 'is-loading' : ''}`} id="header">
        <div className="newNavBar">
          {/* Large Screen */}
          <div className="forLargeScreen" onClick={redirectToHome}>
            <img className="coffeeWebLogo" alt="coffee-web-logo" src={process.env.REACT_APP_NODE_ENV === 'production' ? coffeewebLogo.transparentLogo : devEnvLogo} />
          </div>

          {/* Mobile Screen */}
          {!isWebView && (
            <div className="forMobileScreen">
              {userLoginStatus ? <i className="pi pi-bars mobileScreenMenuIcon" onClick={() => setDrawerState(true)} /> : <img alt="coffee-web-logo" onClick={redirectToHome} src={process.env.REACT_APP_NODE_ENV === 'production' ? coffeewebLogo.transparentLogo : devEnvLogo} className="coffeeWebLogo" />}

              <Sidebar visible={drawerState} position={isLoggedInStatus ? 'left' : 'right'} onHide={() => setDrawerState(false)} className="mobileScreenDrawer">
                <div className="mobileScreenLogoWrapper">
                  <img alt="" src={process.env.REACT_APP_NODE_ENV === 'production' ? coffeewebLogo.transparentLogo : devEnvLogo} />
                  <div className="close-icon">
                    <i className="pi pi-times" onClick={() => setDrawerState(false)} />
                  </div>
                </div>

                <div className="sideBar">
                  <div className="sideBarCardWrapper">
                    <div className="sideBarCard" style={{ backgroundImage: `linear-gradient(100deg, ${userDetails?.sub?.subscriptionColor ? userDetails?.sub?.subscriptionColor.slice(0, 7) : '#ae5e49'}, ${userDetails?.sub?.subscriptionColor ? userDetails?.sub?.subscriptionColor.slice(9, 16) : '#ae5e49'}, ${userDetails?.sub?.subscriptionColor ? userDetails?.sub?.subscriptionColor.slice(18, 27) : '#ae5e49'})`, animation: 'gradient 7s ease infinite' }}>
                      <div>
                        {/* User Country Image Div */}
                        <div>{userDetails?.countryFlag ? <img alt="country-flag" src={userDetails?.countryFlag} /> : <img alt="flag-india" src="https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/IND.svg"></img>}</div>

                        {/* User Details */}
                        <div>
                          {/* Name and Edit Button */}
                          <div>
                            <div className="userFirstLastName" onClick={() => openProfileSetting()}>
                              {userDetails?.firstName ? `${userDetails.firstName} ${userDetails.lastName}` : 'Guest User'}
                            </div>
                            <div className="EditButtonDiv">
                              <i className="pi pi-pencil" onClick={userDetails ? () => openProfileSetting() : () => handleLogin()} />
                            </div>
                          </div>
                          {/* Users Occupation,Phone Number, Email */}
                          <div>
                            {userDetails?.occupationName !== 'NA' && <div onClick={() => openProfileSetting()}>{userDetails?.occupationName}</div>}
                            {userLoginStatus ? (
                              userDetails?.phone && (
                                <div onClick={() => openProfileSetting()}>
                                  +{userDetails?.countryPhoneCode} {userDetails?.phone}
                                </div>
                              )
                            ) : (
                              <div>{currentCity}</div>
                            )}
                            {userDetails?.email && <div onClick={() => openProfileSetting()}>{userDetails?.email}</div>}
                          </div>
                        </div>
                      </div>

                      {userLoginStatus ? (
                        <div>
                          <div>
                            {userDetails?.sub?.endDate && userDetails?.sub?.subType !== 1 && <div> {userDetails?.sub?.subscriptionValidTill}</div>}
                            {userDetails?.sub?.endDate && userDetails?.sub?.subType !== 1 && <div> {userDetails?.sub?.subscriptionValidTillDate}</div>}
                          </div>
                          <div>{userDetails?.sub?.subTypeName}</div>
                        </div>
                      ) : (
                        <div className="date-time-wrapper">
                          <div className="current-date">{currentDate}</div>
                          <div className="current-time">{currentTime}</div>
                        </div>
                      )}

                      {appSettings?.showrenewal && userDetails?.sub?.renewProcess ? (
                        <div onClick={() => setDrawerState(false)}>
                          {appSettings?.showrenewal && (
                            <button className="upgradeButton renew-button" onClick={navigateToSubscription} style={{ border: `2px solid ${userDetails?.sub?.subscriptionColor && userDetails?.sub?.subscriptionColor.slice(0, 7)}`, color: `${userDetails?.sub?.subscriptionColor && userDetails?.sub?.subscriptionColor.slice(0, 7)}`, padding: '1px 15px' }}>
                              {userDetails?.sub?.renewProcess}
                            </button>
                          )}
                        </div>
                      ) : (
                        <div>
                          <button className="upgradeButton guest-user-text-border" onClick={navigateToCreateAccount}>
                            <div className="renew-text">{t('CREATE_ACCOUNT_BUTTON_LABEL')}</div>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="sidebar-menus-wrapper">
                    <div className="sideMenu-container">
                      {menuDetails &&
                        menuDetails.map((ele, index) => (
                          <React.Fragment key={index}>
                            {ele?.isWebDisplayEnable === true && ele?.isLaunch === true && ele?.menuUrl !== null && ele?.isPwadisplayEnable && (
                              <div key={index} className="menu-icons-container" onClick={() => setDrawerState(false)}>
                                <NavLink exact to={ele?.menuUrl} className="nav-link-container">
                                  <div className="menu-icons">{ele?.menuIcon !== 'NA' && <img alt="" src={ele?.menuIcon} className="menu-image" />}</div>
                                  <div className="menu-name">{ele?.menuName}</div>
                                </NavLink>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      {!userLoginStatus &&
                        freeMenusToDisplay &&
                        freeMenusToDisplay.map((ele, index) => (
                          <React.Fragment key={index}>
                            {ele?.isWebDisplayEnable === true && ele?.isLaunch === true && ele?.menuUrl !== null && ele?.isPwadisplayEnable && (
                              <div key={index} className="menu-icons-container" onClick={() => setDrawerState(false)}>
                                <NavLink exact to={ele.menuUrl === 'CoffeeQuotes' ? ROUTE_STRINGS.guestCoffeeQuotes : ele.menuUrl} className="nav-link-container">
                                  <div className="menu-icons">{ele?.menuIcon !== 'NA' && <img alt="" src={ele?.menuIcon} className="menu-image" />}</div>
                                  <div className="menu-name">{ele?.menuName}</div>
                                </NavLink>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                  {!userLoginStatus && (
                    <div className="create-account-text">
                      To enjoy more features{' '}
                      <span className="create-account-label" onClick={navigateToCreateAccount}>
                        {' '}
                        {t('CREATE_ACCOUNT_BUTTON_LABEL')}
                      </span>
                    </div>
                  )}

                  {
                    <div className="sidebar-logout-setting-wrapper">
                      <div className="sidebar-action-logout">
                        {userLoginStatus ? (
                          <div className="logout-button" onClick={() => showLogoutConfirmationModal()}>
                            <i className="pi pi-power-off"></i>
                            <div className="logout">{t('LOGOUT')}</div>
                          </div>
                        ) : (
                          <div className="logout-button guest-user-text-border" onClick={() => navigateToLoginAccount()}>
                            <i className="pi pi-sign-in"></i>
                            <div className="logout">{t('LOGIN_BUTTON')}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  }

                  <div className="buy-subscription-container"></div>
                </div>

                {/* TODO : FOR THE FUTURE RELEASE */}
                {/* <div className="sidebar-version-number">{Package.version}</div> */}
                {/* --------Footer In Mobile Screen-------- */}
                <div className="sidebar-footer">
                  <div className="sidebar-footer-copyright" style={{ textAlign: 'center' }}>
                    {t('COPYRIGHT')} <b>{t('COFFEE_WEB')}</b>
                  </div>
                  <div className="sidebar-footer-terms" style={{ display: 'flex', justifyContent: 'center' }}>
                    {t('ALL_RIGHTS_RESERVED')}
                    <a href={STATIC_ROUTE_STRINGS.termsAndCondition} target="_blank" rel="noreferrer">
                      {t('TERMS_CONDITIONS')}
                    </a>
                    <span className="">{t('AND')}</span>
                    <a href={STATIC_ROUTE_STRINGS.privacyPolicy} target="_blank" rel="noreferrer">
                      {t('PRIVACY_POLICY')}
                    </a>
                  </div>
                </div>
              </Sidebar>
            </div>
          )}

          <div className="forMobileScreen mobile-screen-display-page">{displayUrl}</div>

          <div className="subActionsContainer">
            {userLoginStatus ? (
              <div className="subActionsDiv">
                <div className="forLargeScreen">
                  {userDetails?.userType !== 0 && (
                    <div className="avatarContainer" onMouseEnter={handleProfileMouseEnter}>
                      <div onClick={() => setModalShow(true)} className="avatar-logo">
                        {userDetails?.firstName?.substr(0, 1).toUpperCase()}
                      </div>
                      <div
                        className="avatarCard"
                        onClick={() => setModalShow(true)}
                        style={{
                          '--custom-background': generateLinearGradientColor()
                        }}
                      >
                        <div className="avatarDiv">
                          <div>
                            <img alt="" className="avatarUserCountryImg" src={userDetails?.countryFlag} />
                          </div>
                          <div className="avatar-user-personal-details">
                            <div className="username-edit-button">
                              <div>
                                {userDetails?.firstName} {userDetails?.lastName}
                              </div>
                              <div>
                                <i className="pi pi-pencil" />
                              </div>
                            </div>
                            {userDetails?.occupationName !== 'NA' && <div className="user-occupation">{userDetails?.occupationName}</div>}

                            {userDetails?.phone && (
                              <div>
                                {t('PHONE')} : + {userDetails?.countryPhoneCode} {userDetails?.phone}
                              </div>
                            )}
                            {userDetails?.email && (
                              <div>
                                {t('EMAIL')} : {userDetails?.email}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="userSubscriptionDetails">
                          <div className="subscriptionDate">
                            {userDetails?.sub?.endDate && userDetails?.sub?.subType !== 1 && <div> {userDetails?.sub?.subscriptionValidTill}</div>}
                            {userDetails?.sub?.endDate && userDetails?.sub?.subType !== 1 && <div> {userDetails?.sub?.subscriptionValidTillDate}</div>}
                          </div>
                          <div className="subscriptionType">{userDetails?.sub?.subTypeName}</div>
                        </div>
                        {appSettings?.showrenewal && userDetails?.sub?.renewProcess && (
                          <div className="upgrade-button-large-screen-container">
                            {appSettings?.showrenewal && (
                              <button className="upgradeButtonLargeScreen" onClick={navigateToSubscription}>
                                {userDetails?.sub?.renewProcess}
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {userDetails?.userType !== 0 && (
                  <div className="">
                    <NotificationListenService overlayPanelRef={overlayPanelRef} />
                  </div>
                )}

                <div className="forLargeScreen hoverOnLogout">
                  <i className="pi pi-power-off logout-icon" onClick={showLogoutConfirmationModal}></i>
                  <div className="tooltipLogout" onClick={showLogoutConfirmationModal}>
                    <div>{t('LOGOUT')}</div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {currentUrl !== 'login' && currentUrl !== 'create_account' && (
                  <div>
                    <div className="auth-screen-button forLargeScreen" onClick={handleLogin}>
                      {t('LOGIN_AND_CREATE_ACCOUNT')}
                    </div>
                    <div className="forMobileScreen">
                      <i className="pi pi-bars mobileScreenMenuIcon" onClick={() => setDrawerState(true)}></i>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {userLoginStatus && (
        <div>
          <LogoutModal showLogoutPopup={showLogoutPopup} isLogoutModalVisible={isLogoutModalVisible} setIsLogoutModalVisible={setIsLogoutModalVisible} />
          {modalShow && <ProfileModal show={modalShow} setModalShow={setModalShow} onHide={handleClose} />}
        </div>
      )}
    </>
  )
}

export default Header
