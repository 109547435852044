const REG_EX = {
  ONLY_NUMBER_REG_EX: /^[0-9]/,
  ALPHANUMERIC_WITHOUT_SPACES: /^[a-zA-Z0-9\-_]{0,40}$/,
  NAME_REG_EX: /^[\p{L}\p{M} ]+$/u,
  PASSWORD_REG_EX: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
  PHONE_REG_EX: /^[1-9][0-9]*$/,
  EMAIL_REG_EX: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  LEADING_SPACES: /^\s*/,
  VALID_EMAIL_REGEX: /^\s*[^@\s]+@[^@\s]+\.[^@\s]{2,}\s*$/,
  REGULAR_REGEX: /^\d+$/,
  PHONE_NUMBER_REGEX: /^[\d]{7,15}$/,
  NEGATIVE_SIGN_SPACE_REGEX: /(-?)([^0-9]*)([0-9]+)/
}

export default REG_EX
