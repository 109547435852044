export function addBlankTargetToAnchors(htmlString) {
  const parser = new DOMParser()
  const parsedHtml = parser.parseFromString(htmlString, 'text/html')

  const anchorTags = parsedHtml.getElementsByTagName('a')

  Array.from(anchorTags).forEach((anchorTag) => {
    anchorTag.setAttribute('target', '_blank')
  })

  return new XMLSerializer().serializeToString(parsedHtml)
}
