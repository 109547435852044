export const RouteStrings = {
  login: '/',
  signup: '/signup',
  newsdummy: '/coffeenewsfeeds',
  support: '/dashboard/support',
  pnf: '/dashboard/pnf',
  // otpScreen: '/coffeeweb/otpscreen',
  dashboard: '/dashboard',
  admindashboard: '/dashboard/admindashboard',
  coffeeentries: '/dashboard/coffeeentries',
  differentialsentry: '/dashboard/differentialsentry',
  freightrateentry: '/dashboard/freightrateentry',
  addtradebox: '/dashboard/addtradebox',
  listtraders: '/dashboard/listtraders',
  edittraders: '/dashboard/edittraders',
  listnewsfeed: '/dashboard/listnewsfeed',
  addnewsfeed: '/dashboard/addnewsfeed',
  editnewsfeed: '/dashboard/editnewsfeed',
  newsfeedapprovalscreen: '/dashboard/newsfeedapprovalscreen',
  uploadvideo: '/dashboard/uploadvideo',
  listusers: '/dashboard/listusers',
  adduser: '/dashboard/adduser',
  accountsettings: '/dashboard/adduser/accountsettings',
  approvalsettings: '/dashboard/adduser/approvalsettings',
  edituser: '/dashboard/edituser/',
  edituserProfile: '/dashboard/edituser/',
  approvallist: '/dashboard/approvallist',
  listmenugroup: '/dashboard/listmenugroup',
  addmenugroup: '/dashboard/addmenugroup',
  editmenugroup: '/dashboard/editmenugroup',
  coffeeweb: '/dashboard/coffeeweb',
  coffeeprices: '/dashboard/coffeeprices',
  coffeeQuint: '/dashboard/coffeeQuint',
  weather: '/dashboard/weather',
  reports: '/dashboard/reports',
  coffeequotes: '/dashboard/CoffeeQuotes',
  coffeenewsfeeds: '/dashboard/coffeenewsfeeds',
  coffeenewsfeedsFvt: '/dashboard/coffeenewsfeedsFvt',
  newsreadmore: '/dashboard/newsreadmore',
  newsreadmorefvt: '/dashboard/newsreadmorefvt',
  notification: '/dashboard/notification',
  newsreadmorefornotification: '/dashboard/newsreadmorefornotification',
  newsreadmorefornotificationdummy: '/dashboard/newsreadmorefornotificationdummy',
  opennewsnotification: '/dashboard/opennewsnotification',
  opensearchednews: '/dashboard/opensearchednews',
  opensharednews: '/dashboard/opensharednews',
  previewpdf: '/dashboard/previewpdf',
  searchfornews: '/dashboard/searchfornews',
  globalrawcoffeeprices: '/dashboard/globalrawcoffeeprices',
  globaldifferentials: '/dashboard/globaldifferentials',
  globalfreightrates: '/dashboard/globalfreightrates',
  traderequest: '/dashboard/traderequest',
  mynotification: '/dashboard/mynotification',
  adminreports: '/dashboard/adminreports',
  socialMediaProfilePage: '/dashboard/smProfilePage',
  approvalscreens: '/dashboard/approvalscreens',
  countrydropdownselector: '/dashboard/countrydropdownselector',

  unlockuser: '/dashboard/unlockuser',
  payment: '/dashboard/payment',
  paymentHistory: '/dashboard/paymentHistory',
  paymentgateway: '/dashboard/paymentgateway',
  subscription: '/dashboard/subscription',
  success: '/dashboard/success',
  cancel: '/dashboard/cancel',
  PaymentStatus: '/dashboard/success',
  approveTrader: '/dashboard/approvetrader',
  globalprice: '/dashboard/globalprice',
  globalrawcoffeeprice: '/dashboard/globalrawcoffeeprice',
  getsubscriptation: '/dashboard/getsubscriptation',
  addsubscription: '/dashboard/addsubscription',
  subscriptionfeatures: '/dashboard/subscriptionfeatures',
  marketdata: '/dashboard/marketdata',
  xedata: '/dashboard/xedata',

  home: '/dashboard/coffeeweb',
  discover: '/dashboard/discover',
  connections: '/dashboard/connections',
  followers: '/dashboard/followers',
  following: '/dashboard/following',
  connectionRequests: '/dashboard/connectionrequests',
  notifications: '/dashboard/notifications',
  messages: '/dashboard/messages',
  myprofile: '/dashboard/myprofile',
  blockedlist: '/dashboard/blockedlist'
}

// export const RouteStrings = {
//   login: '/',
//   signup: '/signup',
//   newsdummy: '/coffeeNewsFeeds',
//   supportRequest: '/supportRequest',
//   dashboard: '/dashboard',
//   coffeeweb: '/dashboard/coffeeweb',
//   coffeeprices: '/dashboard/coffeeprices',
//   coffeequotes: '/dashboard/CoffeeQuotes',
//   coffeeNewsFeeds: '/dashboard/coffeeNewsFeeds',
//   newsreadmorefvt: '/dashboard/newsreadmorefvt',
//   notification: '/dashboard/notification',
//   newsreadmorefornotificationdummy: '/dashboard/newsreadmorefornotificationdummy',
//   opensearchednews: '/dashboard/opensearchednews',
//   opensharednews: '/dashboard/opensharednews',
//   previewPdf: '/dashboard/previewPdf',
//   globalfreightrates: '/dashboard/globalfreightrates',
//   traderequest: '/dashboard/traderequest',
//   support: '/dashboard/support',
//   countrydropdownselector: '/dashboard/countrydropdownselector',
//   payment: '/dashboard/payment',
//   paymentgateway: '/dashboard/paymentgateway',
//   subscription: '/dashboard/subscription',
//   success: '/dashboard/success',
//   cancel: '/dashboard/cancel',

//   home: '/dashboard/coffeeweb'
// }
