import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { coffeeReportJsonLinks } from 'Utils/Constants'

const TraderWiseComparison = ({ selectedMarket, leftDivRef, rightDivRef, scrollFunction, setLoading }) => {
  const { t } = useTranslation()
  const { robustaTraderWiseComparison, arabicaTraderWiseComparison } = coffeeReportJsonLinks

  const [fetchedData, setFetchedData] = useState([])
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null)

  useEffect(() => {
    fetchData()
  }, [selectedMarket])

  useEffect(() => {
    const cleanUp = scrollFunction()

    return () => {
      cleanUp()
    }
  }, [])

  const fetchData = async () => {
    setLoading(true)

    const reportName = selectedMarket === 'Robusta' ? robustaTraderWiseComparison : arabicaTraderWiseComparison

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setFetchedData(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index)
  }

  const handleMouseLeave = () => {
    setHoveredRowIndex(null)
  }

  return (
    <div className="table-content" id="trader-wise-content">
      <div className="table-content-left" ref={leftDivRef}>
        <table>
          <thead>
            <tr className="empty-header-row">
              <th colSpan={4} style={{ background: 'white' }}></th>
            </tr>
            <tr className="table-content-left-header">
              <th>{t('DATES_UPPERCASE')}</th>
              <th>{t('TERMINAL_LEVEL_UPPERCASE')}</th>
            </tr>

            <tr className="blank-row"></tr>

            <tr className="empty-row">
              <th colSpan={4}></th>
            </tr>

            <tr className="blank-row"></tr>
          </thead>

          <tbody>
            {fetchedData?.terminalDetails?.map((item, index) => (
              <tr key={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === index ? 'highlighted' : ''}>
                <td>{item?.date}</td>
                <td>{item?.terminalLevel}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="table-content-right" ref={rightDivRef}>
        <table>
          <thead>
            <tr className="header-row-top">
              <th colSpan={4} style={{ background: 'white' }}></th>
            </tr>

            <tr className="table-header-main">
              <th>{t('TOTAL_TRADERS').toUpperCase()}</th>
              <th>{t('NET_CHANGE').toUpperCase()}</th>
            </tr>
            <tr className="blank-row"></tr>

            <tr className="header-row-bottom">
              <th colSpan={4}></th>
            </tr>
            <tr className="blank-row"></tr>
          </thead>

          <tbody>
            {fetchedData?.terminalDetails?.map((item, index) => (
              <tr key={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === index ? 'highlighted' : ''}>
                <td className="light-purple">{item?.totalTraders.toLocaleString()}</td>
                <td className={`${item?.netChange >= 0 ? 'positive-color' : 'negative-color'}`}>{item?.netChange?.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {fetchedData?.details?.map((data, index) => (
          <table key={index}>
            <thead>
              <tr className="table-title-row">
                <th style={{ background: 'white' }} colSpan={data?.label === 'FUND BUYERS' || data?.label === 'SWAP DEALERS' || data?.label === 'OTHER REPORTABLE' || data?.label === 'FUND BUYERS' ? 7 : 6}>
                  {data?.label}
                </th>
              </tr>
              <tr className="table-content-title-row">
                <th className="positive-label">{t('LONG')}</th>
                <th className="negative-label">{t('SHORT')}</th>
                <th>{t('TOTAL').toUpperCase()}</th>
                <th className="positive-label">{t('PERCENTAGE_LONG')}</th>
                <th className="negative-label">{t('PERCENTAGE_SHORT')}</th>
                <th>{t('NET')}</th>
                {(data?.label === 'FUND BUYERS' || data?.label === 'SWAP DEALERS' || data?.label === 'OTHER REPORTABLE' || data?.label === 'FUND BUYERS') && <th className="gold-label">{t('SPREADING')}</th>}
              </tr>
              <tr className="blank-row"></tr>

              <tr>
                <th className="positive-label">{data?.long?.toLocaleString()}</th>
                <th className="negative-label">{data?.short?.toLocaleString()}</th>
                <th>{data?.total?.toLocaleString()}</th>
                <th className="positive-label">{data?.percentLong?.toFixed(2)}</th>
                <th className="negative-label">{data?.percentShort?.toFixed(2)}</th>
                <th>{data?.net}</th>
                {(data?.label === 'FUND BUYERS' || data?.label === 'SWAP DEALERS' || data?.label === 'OTHER REPORTABLE' || data?.label === 'FUND BUYERS') && <th className="gold-label">{data?.spreading?.toLocaleString()}</th>}
              </tr>

              <tr className="blank-row"></tr>
            </thead>
            <tbody>
              {data?.data?.map((item, index) => (
                <tr key={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === index ? 'highlighted' : ''}>
                  <td className="positive-label">{item?.long.toLocaleString()}</td>
                  <td className="negative-label">{item?.short.toLocaleString()}</td>
                  <td>{item?.total.toLocaleString()}</td>
                  <td className="positive-label">{item?.percentLong?.toFixed(2)}</td>
                  <td className="negative-label">{item?.percentShort?.toFixed(2)}</td>
                  <td>{item?.net.toLocaleString()}</td>
                  {(data?.label === 'FUND BUYERS' || data?.label === 'SWAP DEALERS' || data?.label === 'OTHER REPORTABLE' || data?.label === 'FUND BUYERS') && <td className="gold-label">{item?.spreading.toLocaleString()}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
    </div>
  )
}

export default TraderWiseComparison
