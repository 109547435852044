import './styles.scss'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { setDoc, doc, getDoc } from 'firebase/firestore'
import { useTranslation } from 'react-i18next'
import { useGoogleLogin } from '@react-oauth/google'
import { Dialog } from 'primereact/dialog'
import { EyeFilled, EyeFilledOff, KeyIcon, GoogleIcon, Closebtn, EmailIcon } from 'Assets/Icons'
import { warningIcon } from 'Assets/Images'
import { ROUTE_STRINGS, STATIC_ROUTE_STRINGS } from 'Utils/Constants'
import { db } from 'firebase.js'
import { getLocalUserDetails, getLocalAuthToken, getLocalFCMToken, getLocalShareNewsId, getLocalNewsForSubscription, setLocalWithLoggedInStatus, setLocalWithUserDetails, setLocalWithAuthToken, setLocalWithLanguageIdForNews, setLocalWithSubscriptionNewsId } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import Validation from 'Utils/Validation'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import { commonMethods } from 'Utils/commonMethods'
import DropdownComponent from 'Components/DropdownComponent'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import Button from 'Components/UIComponent/Button'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import ResetPasswordModal from '../ResetPasswordModal'
import ForgotPasswordModal from '../ForgotPasswordModal'

const LoginComponent = ({ handleTabSelection, appSettingsState, singleDeviceId, setNewsDataOnClick, handleApiResponse, addUserInFirebase, showError }) => {
  const history = useHistory()
  const params = new URLSearchParams()
  const { t } = useTranslation()
  const { setLoading } = useContext(CoffeeWebContext)
  const { sentryLog, fetchIPDetails } = commonMethods
  const userDetails = getLocalUserDetails()
  const accessToken = getLocalAuthToken()
  const firebaseFcmToken = getLocalFCMToken()
  const sharedNewsId = history.location.state?.encryptedId || getLocalShareNewsId()
  const newsForSubscription = history.location.state?.newsForSubscription || getLocalNewsForSubscription()
  const newsId = history.location.state?.newsId
  const forGuestUser = history.location.state?.forGuestUser

  const [forgotPasswordModal, setForgotPasswordModal] = useState(false)
  const [resetPasswordModal, setResetPasswordModal] = useState(false)
  const [deviceChangeModal, setDeviceChangeModal] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [invalidCredentials, setInvalidCredentials] = useState(false)
  const [dropdownError, setDropdownError] = useState(false)
  const [visible, setVisible] = useState(false)
  const mobileNumberInputRef = useRef(null)
  const passwordInputRef = useRef(null)
  const [googleAuthToken, setGoogleAuthToken] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [countries, setCountries] = useState([])
  const [isError, setIsError] = useState(false)
  const [errorState, setErrorState] = useState({
    email: false,
    invalidEmailFormat: false,
    password: false,
    phoneNo: false,
    emailFormat: false
  })

  const [userInputValues, setUserInputValue] = useState({
    email: '',
    password: '',
    phoneNo: ''
  })

  let displayNameForChat = ''
  let emailForChat = ''
  let userChatId = ''

  if (userDetails && accessToken) {
    setLocalWithLoggedInStatus(true)
    if (userDetails?.userLandingPageUrl) {
      history.push(`${ROUTE_STRINGS.dashboard}/${userDetails?.userLandingPageUrl}`)
    } else {
      history.push(ROUTE_STRINGS.coffeequotes)
    }
  }

  useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.code === 'Enter' && !Validation.isInputFieldEmpty(mobileNumberInputRef.current.value)) {
        passwordInputRef.current.focus()
      }
    }

    const mobileNumberInput = mobileNumberInputRef.current

    mobileNumberInput.addEventListener('keyup', handleKeyUp)
    setNewsDataOnClick((previousState) => ({ ...previousState, encryptedId: sharedNewsId, newsForSubscription, newsId, forGuestUser }))

    return () => {
      mobileNumberInput.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const authMode = urlParams.get('authMod')

    if (authMode?.includes('reset_password_email')) {
      setResetPasswordModal(true)
    } else {
      setResetPasswordModal(false)
    }
  }, [history.location.search])

  const getAllCountriesDetails = async (googleAuthToken) => {
    setLoading(true)
    setIsError(false)
    let countryData

    try {
      const response = await apiAdapterCoffeeWeb.getAllCountries()

      const countryData = response.data

      if (response.data.length) {
        setCountries(response.data)
        getLocation(googleAuthToken, countryData)
      }
    } catch {
      getLocation(googleAuthToken, countryData)
      setIsError(true)
    } finally {
      setLoading(false)
    }
  }

  const getLocation = async (googleAuthToken, countryData) => {
    try {
      const ipLocationResponse = await fetchIPDetails()

      const response = countryData.find((item) => item?.nicename === ipLocationResponse?.countryName)

      setSelectedCountry(response)
      setVisible(true)
      setGoogleAuthToken(googleAuthToken)
    } catch {
      setGoogleAuthToken(googleAuthToken)
      setVisible(true)
      setIsError(true)
    }
  }

  const addUserInDatabase = async (googleAuthToken, countryCode, phonecode) => {
    setLoading(true)
    setVisible(false)
    const data = {
      userType: 1,
      firstName: 'NA',
      lastName: '',
      email: 'NA',
      phone: 'NA',
      occupationId: 0,
      occupationName: 'NA',
      countryId: countryCode,
      password: 'NA',
      createdBy: 0,
      includeMenu: true,
      countryPhoneCode: phonecode,
      idtimezone: 0,
      idState: 0,
      showMobileNo: true,
      showEmailId: true,
      isAllowedToConnect: true,
      userRole: 0,
      registerSource: 2,
      deviceId: singleDeviceId,
      fbToken: firebaseFcmToken,
      registerDevice: 'Browser',
      googleTocken: googleAuthToken
    }

    try {
      const response = await apiAdapterCoffeeWeb.createUser(data)
      const {
        data: { firstName, email, userFbregistrationId, isError, errorMessage }
      } = response

      if (!isError) {
        displayNameForChat = firstName
        emailForChat = email
        userChatId = userFbregistrationId
        addUserInFirebase(googleAuthToken, email, userChatId, displayNameForChat, emailForChat)
      }
    } catch (error) {
      sentryLog({ userId: 'NA', screenName: 'login', description: error?.response?.data?.title || error?.response?.status, errorIn: 'createUser API' })
      showError({
        title: t('ERROR_TITLE'),
        message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p>`,
        buttonLabel: t('OK')
      })
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  /* eslint-disable max-params */
  const getPostData = (username, password, singleDeviceId, firebaseFcmToken, byPassOldDevice, token) => {
    const postData = {
      username: token ? 'NA' : username,
      password: token ? 'NA' : password,
      includeMenu: true,
      isMobile: false,
      deviceId: singleDeviceId,
      fbToken: firebaseFcmToken || 'NA',
      idAndroidDevice: false,
      byPassOldDevice,
      googleToken: token || 'NA'
    }

    return postData
  }

  const postLoginData = async (token) => {
    setLoading(true)
    const { email: username, password } = userInputValues
    const byPassOldDevice = false
    const userDataPost = getPostData(username, password, singleDeviceId, firebaseFcmToken, byPassOldDevice, token)

    try {
      const response = await apiAdapterCoffeeWeb.authenticateWithFB(userDataPost)

      if (response?.data) {
        if (!response?.data?.isError) {
          if (response?.data?.userType !== 0) {
            firebaseValidationForUser(response?.data)
          } else {
            setInvalidCredentials(true)
          }
        } else if (response.data.error === 'User Does Not Exist') {
          getAllCountriesDetails(token)
        } else {
          setInvalidCredentials(true)
        }
      }
    } catch (error) {
      const statusCode = error?.response?.status

      sentryLog({ userId: username, screenName: 'login', description: error?.response?.data?.title || statusCode, errorIn: 'AuthenticatewithFB API' })
      handleApiResponse({ status: statusCode, message: t('SORRY_UNABLE_TO_LOGIN'), onRetry: () => postLoginData() })
    } finally {
      setLoading(false)
    }
  }

  const firebaseValidationForUser = async (res) => {
    setLoading(true)
    const { userFbregistrationId: userChatId, firstName: displayNameForChat, email: emailForChat, isAlreadyLoggedIn } = res

    try {
      const data = await getDoc(doc(db, 'users', userChatId))

      if (!data.exists()) {
        await setDoc(doc(db, 'users', userChatId), {
          uid: userChatId,
          displayNameForChat,
          emailForChat
        })
        await setDoc(doc(db, 'userChats', userChatId), {})
        if (res?.token) {
          proceedToDashboard(res)
        } else {
          handleApiResponse({ status: null, message: t('SORRY_UNABLE_TO_LOGIN'), onRetry: () => postLoginData() })
        }
      } else if (isAlreadyLoggedIn) {
        setDeviceChangeModal(true)
      } else if (res?.token) {
        proceedToDashboard(res)
      } else {
        handleApiResponse({ status: null, message: t('SORRY_UNABLE_TO_LOGIN'), onRetry: () => postLoginData() })
      }
    } catch (err) {
      console.log('firebaseValidationForUser', err)
    } finally {
      setLoading(false)
    }
  }

  const proceedToDashboard = (res) => {
    setLoading(true)
    const {
      token,
      userLandingPageUrl,
      sub: { subType }
    } = res

    const newsSubscriptionType = newsForSubscription?.split(',')?.map((item) => item?.trim())
    const isUserSubscribed = newsSubscriptionType?.includes(subType.toString())

    setLocalWithUserDetails(res)
    setLocalWithAuthToken(token)
    setLocalWithLoggedInStatus(true)
    setLocalWithLanguageIdForNews(res?.languageId)

    if (newsId && forGuestUser) {
      if (isUserSubscribed) {
        params.set('newsId', newsId)
        history.push({ pathname: ROUTE_STRINGS.coffeeNewsFeeds, search: params.toString() })
      } else {
        history.push({ pathname: ROUTE_STRINGS.subscription, state: { newsId } })
        setLocalWithSubscriptionNewsId(newsId)
      }
    } else if (forGuestUser) {
      history.push(ROUTE_STRINGS.coffeeNewsFeeds)
    } else if (sharedNewsId) {
      if (isUserSubscribed) {
        history.push({ pathname: ROUTE_STRINGS.opensharednews, state: { encryptedId: sharedNewsId } })
      } else {
        history.push(ROUTE_STRINGS.subscription)
      }
    } else if (!userLandingPageUrl) {
      history.push(ROUTE_STRINGS.coffeequotes)
    } else {
      history.push(`${ROUTE_STRINGS.dashboard}/${userLandingPageUrl}`)
    }
    setLoading(false)
  }

  const handleSubmit = () => {
    const { email, password } = userInputValues

    if (Validation.checkAllInputFieldsNotEmpty(email, password) && !errorState.invalidEmailFormat && !errorState.emailFormat) {
      postLoginData()
    } else {
      if (Validation.isInputFieldEmpty(email)) {
        setErrorState((previousState) => ({ ...previousState, email: true }))
      }
      if (Validation.isInputFieldEmpty(password)) {
        setErrorState((previousState) => ({ ...previousState, password: true }))
      }
      if (Validation.checkEmptyField(password, email)) {
        setErrorState((previousState) => ({ ...previousState, email: true, password: true }))
      }
      if (!Validation.checkEmailFormat(email)) {
        setErrorState((previousState) => ({ ...previousState, emailFormat: true }))
      }
    }
  }

  const handleChange = ({ target: { name, value } }) => {
    setInvalidCredentials(false)

    if (name === 'email') {
      setUserInputValue((previousValue) => ({ ...previousValue, email: value }))
      if (Validation.isInputFieldEmpty(value)) {
        setErrorState((previousState) => ({ ...previousState, email: true, invalidEmailFormat: false }))
      } else if (!Validation.regularRegex(value)) {
        if (!Validation.checkEmailFormat(value)) {
          setErrorState((previousState) => ({ ...previousState, emailFormat: true, email: false }))
        } else {
          setErrorState((previousState) => ({ ...previousState, email: false, emailFormat: false }))
        }
      } else {
        setErrorState((previousState) => ({ ...previousState, email: false, emailFormat: false }))
      }
    } else if (name === 'password') {
      setUserInputValue((previousValue) => ({ ...previousValue, password: value }))
      if (Validation.isInputFieldEmpty(value)) {
        setErrorState((previousState) => ({ ...previousState, password: true }))
      } else {
        setErrorState((previousState) => ({ ...previousState, password: false }))
      }
    }
  }

  const handleBlur = ({ target: { name, value } }) => {
    if (name === 'password' || name === 'phoneNo') {
      const val = value.toString().toLowerCase()

      if (Validation.isInputFieldEmpty(val)) {
        setErrorState((previousState) => ({ ...previousState, [name]: true }))
      } else {
        setErrorState((previousState) => ({ ...previousState, [name]: false }))
      }
    }
  }

  const handleDeviceChangeConfirmation = async () => {
    setLoading(true)
    const { email: username, password } = userInputValues
    const byPassOldDevice = true
    const userDataPost = getPostData(username, password, singleDeviceId, firebaseFcmToken, byPassOldDevice, googleAuthToken)

    try {
      const response = await apiAdapterCoffeeWeb.authenticateWithFB(userDataPost)

      if (response?.status === 200 && response?.data && response?.data?.token) {
        proceedToDashboard(response?.data)
      } else {
        sentryLog({ userId: response?.data?.email || response?.data?.phone, screenName: 'login', description: 'Null Token received', errorIn: 'AuthenticatewithFB API' })
        handleApiResponse({ status: null, message: t('SORRY_UNABLE_TO_LOGIN'), onRetry: () => handleDeviceChangeConfirmation() })
      }
    } catch (error) {
      const statusCode = error?.response?.status

      sentryLog({ userId: username, screenName: 'login', description: error?.response?.data?.title || statusCode, errorIn: 'AuthenticatewithFB API' })
      handleApiResponse({ status: statusCode, onRetry: () => handleDeviceChangeConfirmation(), message: t('SORRY_UNABLE_TO_LOGIN') })
    } finally {
      setLoading(false)
    }
  }

  const togglePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleForgotPasswordClick = () => {
    setErrorState((previousState) => ({ ...previousState, password: false, phoneNo: false }))
    setForgotPasswordModal(true)
  }

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (response) => {
      const token = response.access_token

      setGoogleAuthToken(token)
      postLoginData(token)
    }
  })

  const closeSingleDeviceDialogModal = () => {
    setGoogleAuthToken(null)
    setDeviceChangeModal(false)
  }

  const handleCloseCountryDialogModal = () => {
    setSelectedCountry(null)

    setDropdownError((prevState) => ({
      ...prevState,
      noDataFound: false,
      countryIsRequired: false
    }))
    setVisible(false)
  }

  const submitCountrySelection = () => {
    setDropdownError((prevState) => ({
      ...prevState,
      noDataFound: false
    }))

    if (!selectedCountry) {
      setDropdownError((prevState) => ({
        ...prevState,
        countryIsRequired: true
      }))
    } else {
      addUserInDatabase(googleAuthToken, selectedCountry.id, selectedCountry.phonecode)
    }
  }

  const handleDropDownChange = (event) => {
    if (event && event.id && event.phonecode) {
      setDropdownError((prevState) => ({
        ...prevState,
        countryIsRequired: false,
        noDataFound: false
      }))
      setSelectedCountry(event)
    }
  }
  const dialogBodyContent = () => (
    <div className="login-country-picker-dropdown">
      <DropdownComponent isAuthScreen={true} currentItem={selectedCountry} setCurrentItem={setSelectedCountry} countryList={countries} handleSelectCountry={handleDropDownChange} errorState={isError} width="100%" height="40px" />
      {dropdownError.noDataFound && <div className="dropdown-error-message">{t('NO_DATA_FOUND')}</div>}
      {dropdownError.countryIsRequired && <div className="dropdown-error-message">{t('COUNTRY_IS_REQUIRED')}</div>}
    </div>
  )

  const dialogFooterContent = () => (
    <div className="login-dialog-footer">
      <div className="login-terms-and-condition">
        {t('BY_SIGNING_UP_YOU_AGREE_TO_OUR')}
        <a href={STATIC_ROUTE_STRINGS.termsAndCondition} target="_blank" rel="noreferrer">
          {t('TERMS_CONDITIONS')}
        </a>
        {t('AND')}
        <a href={STATIC_ROUTE_STRINGS.privacyPolicy} target="_blank" rel="noreferrer">
          {t('PRIVACY_POLICY')}
        </a>
      </div>
      <div className="dialog-button-container">
        <Button buttonType={'CLOSE'} onButtonClick={() => handleCloseCountryDialogModal()} />
        <Button buttonType={'CONTINUE'} onButtonClick={submitCountrySelection} />
      </div>
    </div>
  )

  const singleDeviceModalContent = () => (
    <div className="single-device-container">
      <div>
        <img alt="warning icon" src={warningIcon} className="warning-icon" />
      </div>
      <h6 className="title">{t('SINGLE_DEVICE_CONTINUE_LOGIN')}</h6>
      <h6 className="description">{t('SINGLE_DEVICE_LOGOUT_NOTE')}</h6>
    </div>
  )

  const singleDeviceModalFooter = (
    <div className="single-device-footer-button-container">
      <Button buttonType={t('CANCEL')} onButtonClick={() => closeSingleDeviceDialogModal(false)} variant="danger-action" />
      <Button buttonType={t('CONTINUE')} onButtonClick={() => handleDeviceChangeConfirmation()} />
    </div>
  )

  return (
    <div className="login-tab-component">
      <div className="tab-content login-card-content">
        <div className="tab-pane active" role="tabpanel">
          {invalidCredentials && <p className="invalid-credentials">{t('INVALID_CREDENTIALS')}</p>}
          <div className="login-form">
            <div className="email-input-field">
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text login-form-groups">
                    <EmailIcon fill={'#fff'} />
                  </span>
                </div>
                <input id="mobileNumber" autoComplete="email" type="text" name="email" value={userInputValues.email} onChange={handleChange} onBlur={handleBlur} className="form-control login-form-inputs" placeholder={t('EMAIL')} ref={mobileNumberInputRef} disabled={deviceChangeModal} />
              </div>
              {errorState.email && !errorState.phoneInvalidError && <div className="errorMessage">{t('EMAIL_IS_REQUIRED')}</div>}
              {errorState.emailFormat && !errorState.phoneInvalidError && !errorState.email && <div className="errorMessage">{t('INVALID_EMAIL_FORMAT')}</div>}
              {errorState.phoneInvalidError && <div className="errorMessage">{t('PLEASE_ENTER_ONLY_NUMBERS')}</div>}
            </div>
            <div className="input-group form-group mobile-input-block">
              <div className="flex password-input-block">
                <div className="input-group-prepend">
                  <span className="input-group-text login-form-groups ">
                    <KeyIcon stroke={'#fff'} fill={'#fff'} />
                  </span>
                </div>
                <div className="pass-wrapper">
                  <input id="passwordInput" autoComplete="current-password" type={showPassword ? 'text' : 'password'} name="password" value={userInputValues.password} onBlur={handleBlur} onChange={handleChange} className="form-control login-form-inputs password-flow" placeholder={t('PASSWORD')} ref={passwordInputRef} onKeyDown={(e) => e.code === 'Enter' && handleSubmit()} disabled={deviceChangeModal} />
                  <span onClick={togglePassword} className="eye-icon">
                    {showPassword ? <EyeFilled /> : <EyeFilledOff className="eye-filled-off-icon" />}
                  </span>
                </div>
              </div>
              {errorState.password && <div className="errorMessage">{t('PASSWORD_IS_REQUIRED')}</div>}
            </div>
            <div className="login-from-login-button">
              <Button buttonType={'CUSTOM'} buttonLabel={t('LOGIN_BUTTON')} buttonSize={'small'} buttonWidth={'full'} onButtonClick={handleSubmit} />
            </div>
            <div className="or-divider">
              <div className="hr-line" />
              <div>OR</div>
              <div className="hr-line" />
            </div>
            <Button buttonType={'CUSTOM'} buttonLabel={t('CONTINUE_WITH_GOOGLE')} variant={'grey-soft-action'} buttonSize={'small'} buttonWidth={'full'} buttonIcon={<GoogleIcon />} onButtonClick={() => loginWithGoogle()} />
          </div>
        </div>
        <div>
          <div className="forgot-password-container">
            <div className="forgot-password-text" onClick={handleForgotPasswordClick}>
              {t('FORGET_PASSWORD_LABEL')}
            </div>
            <ForgotPasswordModal handleCloseModal={() => setForgotPasswordModal(false)} forgotPasswordModal={forgotPasswordModal} />
            <ResetPasswordModal handleCloseModal={() => setResetPasswordModal(false)} resetPasswordModal={resetPasswordModal} />
          </div>
          {appSettingsState.showSignup === true && (
            <div className="login-links">
              <span lang="en-us">{t('DONT_HAVE_AN_ACCOUNT')}</span>
              <Button buttonType={'CUSTOM'} buttonLabel={t('CREATE_ACCOUNT_BUTTON_LABEL')} buttonSize={'x-small'} onButtonClick={() => handleTabSelection('create_account')} className="button-wrapper" />
            </div>
          )}
        </div>
        <DialogUIModal showLogo={true} hideCloseIcon={true} showModal={deviceChangeModal} handleClose={() => closeSingleDeviceDialogModal(false)} bodyContent={singleDeviceModalContent} footerContent={singleDeviceModalFooter} />
      </div>
      <div className="login-support-button">
        <NavLink exact to={ROUTE_STRINGS.supportRequest}>
          {t('SUPPORT_LABEL')}
        </NavLink>
      </div>

      <DialogUIModal showModal={visible} hideCloseIcon={true} handleClose={handleCloseCountryDialogModal} title={t('SELECT_COUNTRY')} bodyContent={dialogBodyContent} className="login-country-choose-modal" onHide={handleCloseCountryDialogModal} footerContent={dialogFooterContent} />
    </div>
  )
}

export default ApiResponseHandler(ErrorMessageModal(LoginComponent))
